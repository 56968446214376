import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { colors } from '../assets/utils/constants';
import CustomCard2 from '../components/cards/CustomCard2';
import OccupationCard from '../components/dash/OccupationCard';
import FieldWidget from '../components/widgets/FieldWidget';
import PageLayout from './layouts/PageLayout';
import { profileSelector } from '../states/features/selectors';
import { useSelector } from 'react-redux';
import Institution from './profile/components/Institution';
import { isEmpty } from 'lodash';

const AcademicDetails = () => {
    // ############ Redux State ###############
    const { profile: student } = useSelector(profileSelector);

    return (
        <>
            <PageLayout
                head={'Academic details'}
                sidebar={
                    <Box>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12}>
                                <Box
                                    sx={{
                                        display: { xs: 'block', sm: 'none' },
                                    }}
                                >
                                    <OccupationCard />
                                </Box>
                                <Box>
                                    {!isEmpty(student) && (
                                        <Institution student={student} />
                                    )}
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <OccupationCard />
                            </Grid>
                        </Grid>
                    </Box>
                }
                maxNoSidebar
            >
                <Grid container rowSpacing={3}>
                    <Grid item xs={12} sm={12} sx={{
                                mt: 1,
                                display: { xs: 'block', sm: 'none' },
                            }}>
                        <Box
                           
                        >
                            {!isEmpty(student) && (
                                <Institution student={student} />
                            )}
                            <Box sx={{ mt: 3 }}>
                                <OccupationCard />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Box
                            sx={{
                                display: { xs: 'block', sm: 'flex' },
                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                            }}
                        >
                            <Box
                                sx={{
                                    width: '100%',
                                    border: `1px solid ${colors.bgColor5}`,
                                    p: 2,
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: 15,
                                        color: colors.info,
                                    }}
                                >
                                    My Sector
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 15,
                                        color: colors.primary,
                                        opacity: 0.8,
                                    }}
                                >
                                    {student?.section?.sector?.name}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    width: '100%',
                                    border: `1px solid ${colors.bgColor5}`,
                                    p: 2,
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: 15,
                                        color: colors.info,
                                    }}
                                >
                                    My Department
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 15,
                                        color: colors.primary,
                                        opacity: 0.8,
                                    }}
                                >
                                    {student?.section?.department?.name}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    width: '100%',
                                    border: `1px solid ${colors.bgColor5}`,
                                    p: 2,
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: 15,
                                        color: colors.info,
                                    }}
                                >
                                    My Program | Occupation | Section
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 15,
                                        color: colors.primary,
                                        opacity: 0.8,
                                    }}
                                >
                                    {student?.section?.name}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item sm={12}>
                        <CustomCard2
                            head={'Attended practical training'}
                            height={{ md: '54vh', xs: '100%' }}
                            borderBottom
                        >
                            <Grid container spacing={3}>
                                {[1, 2].map((index) => (
                                    <Grid item xs={12} sm={6} key={index}>
                                        <FieldWidget />
                                    </Grid>
                                ))}
                            </Grid>
                        </CustomCard2>
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default AcademicDetails;
