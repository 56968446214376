// ############ ENV #############
const env = "pro";

// => Local base url
const adminLocalBaseUrl = "http://127.0.0.1:8000/api/v1";
const orgLocalBaseUrl = "http://127.0.0.1:8080/api/v1";
const studentLocalBaseUrl = "http://127.0.0.1:6060/api/v1";

// => Dev base url
const adminDevBaseUrl = "http://127.0.0.1:8000/api/v1";
const orgDevBaseUrl = "http://127.0.0.1:8080/api/v1";
const studentDevBaseUrl = "http://127.0.0.1:6060/api/v1";

// => Pro base url
const adminProBaseUrl = "https://secure.admin.wazawamax.co.tz/api/v1";
const orgProBaseUrl = "https://secure.org.wazawamax.co.tz/api/v1";
const studentProBaseUrl = "https://secure.student.wazawamax.co.tz/api/v1";

// => Get Admin baseURL
const getAdminBaseURL = () => {
    if (env === "dev") {
        return adminDevBaseUrl;
    }
    if (env === "pro") {
        return adminProBaseUrl;
    }
    return adminLocalBaseUrl;
};
// => Get Org baseURL
const getOrgBaseURL = () => {
    if (env === "dev") {
        return orgDevBaseUrl;
    }
    if (env === "pro") {
        return orgProBaseUrl;
    }
    return orgLocalBaseUrl;
};
// => Get student baseURL
const getStudentBaseURL = () => {
    if (env === "dev") {
        return studentDevBaseUrl;
    }
    if (env === "pro") {
        return studentProBaseUrl;
    }
    return studentLocalBaseUrl;
};

export const adminBaseURL = getAdminBaseURL();
export const orgBaseURL = getOrgBaseURL();
export const studentBaseURL = getStudentBaseURL();
