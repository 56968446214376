import { Box, Grid } from '@mui/material';
import React from 'react';
import { colors } from '../../assets/utils/constants';
import PageHeader from '../../components/PageHeader';

const PageLayout = ({ sidebar, children, head, noSidebar, maxNoSidebar }) => {
    return (
        <>
            <Grid
                container
                spacing={2}
                sx={{
                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor3}) !important`,
                }}
            >
                {head && (
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        sx={{
                            position: 'sticky',
                            top: { md: 49, xs: 40 },
                            zIndex: 999,
                        }}
                    >
                        <PageHeader head={head} />
                    </Grid>
                )}
                <Grid item xs={12} sm={12}>
                    <Grid container spacing={2} sx={{ px: 2, pb: .5 }}>
                        <Grid item xs={12} sm={noSidebar ? 12 : 9}>
                            <Box>{children}</Box>
                        </Grid>
                        {noSidebar ? (
                            <></>
                        ) : maxNoSidebar ? (
                            <>
                                <Grid
                                    item
                                    xs={12}
                                    sm={3}
                                    sx={{
                                        display: { xs: 'none', sm: 'block' },
                                    }}
                                >
                                    <Box
                                        sx={{
                                            height: head
                                                ? { xs: '100%', sm: '`79vh`' }
                                                : { xs: '100%', sm: `88vh` },
                                            position: 'sticky',
                                            top: 80,
                                            py: 1.5,
                                            p: 1.5,
                                            overflow: 'auto',
                                            background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor2}) !important`,
                                            borderRadius: 4,
                                        }}
                                    >
                                        {sidebar}
                                    </Box>
                                </Grid>
                            </>
                        ) : (
                            <>
                                <Grid item xs={12} sm={3}>
                                    <Box
                                        sx={{
                                            height: head
                                                ? { xs: '100%', sm: '`79vh`' }
                                                : { xs: '100%', sm: `88vh` },
                                            position: 'sticky',
                                            top: 80,
                                            py: 1.5,
                                            p: 1.5,
                                            overflow: 'auto',
                                            background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor2}) !important`,
                                            borderRadius: 4,
                                        }}
                                    >
                                        {sidebar}
                                    </Box>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default PageLayout;
