import React from 'react';
import { ArrowRightAltOutlined, DoneAllOutlined } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import CustomSubmitButton from '../../../components/Forms/CustomSubmitButton';
import useRTK from '../../../hooks/useRTK';
import { useDispatch } from 'react-redux';
import { setPracticalTrainingDetails, updateTrainingDetails } from '../../../states/features/practicalTrainings/practicalTrainingsSlice';
import { useApplyPTMutation } from '../../../api/practicalTrainings/practicalTrainingsAPI';

const ApplyTraining = ({ handleConfirm, training }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# RTK ##################
    const [
        applyAction,
        {
            isLoading: applyLoading,
            isSuccess: applySuccess,
            isError: applyIsError,
            error: applyError,
            data: applyData,
        },
    ] = useApplyPTMutation();
    // ############# useEffect ##################
    // => apply res
    const rtkActions = (data) => {
        handleConfirm();
        dispatch(setPracticalTrainingDetails(data));
        dispatch(updateTrainingDetails(data));
    };
    RTK.useRTKResponse(
        applySuccess,
        applyIsError,
        applyError,
        applyData,
        rtkActions,
    );

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <Box className="warning-message">
                    <Typography sx={{ fontSize: 18, fontWeight: 'bold' }}>
                        Confirm practical training application
                    </Typography>
                </Box>
                <Box sx={{ py: 5 }}>
                    <Typography sx={{ fontSize: 15, textAlign: 'center' }}>
                        Lorem ipsum dolor, sit amet consectetur adipisicing
                        elit. Voluptate laboriosam, maxime aliquid laborum
                        necessitatibus accusantium facilis harum praesentium
                        voluptatem.
                    </Typography>
                </Box>
                <Box
                    sx={{
                        py: 0.5,
                        width: '100%',
                    }}
                >
                    <CustomSubmitButton
                        startIcon={<DoneAllOutlined color="success" />}
                        endIcon={<ArrowRightAltOutlined />}
                        btnColor={`secondary`}
                        variant={'contained'}
                        onClick={() => {
                            applyAction(training.id);
                        }}
                        loading={applyLoading}
                        sx={{ py: 1.5, fontSize: 16 }}
                    >
                        Submit application
                    </CustomSubmitButton>
                </Box>
            </Box>
        </>
    );
};

export default ApplyTraining;
