import * as React from "react";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import {
    Avatar,
    Box,
    Stack,
    Badge,
    Typography,
    Grid,
    styled,
} from "@mui/material";
import { colors } from "../assets/utils/constants";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { filter, isEmpty, size } from "lodash";
import logo from "../assets/media/images/logo.png";
import NotificationsBar from "./NotificationsBar";
import CustomSearchTextfield from "./Forms/CustomSearchTextfield";
import CustomSearchCard from "./cards/CustomSearchCard";
import { Search, Sort } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { practicalTrainingsSelector } from "../states/features/selectors";
import FieldWidgetOne from "./widgets/FieldWidgetOne";
import NoContent from "./NoContent";

const drawerWidth = 250;
// ->
const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const TopbarRight = styled(Stack)({
    "& .icon": {
        // color: colors.primary,
        opacity: 0.8,
    },
});

// !###################### MAIN FUNC ############################
const Topbar = ({ mobileOpen, handleDrawerToggle }) => {
    // ################ Comp State ####################
    const [notMenuToggle] = useState("none");
    const [openNotBar, setOpenNotBar] = React.useState(false);

    // ############### Redux State ##################
    const { practicalTrainings } = useSelector(practicalTrainingsSelector);

    // ################ FUNC ####################
    const toggleNotDrawer = () => {
        setOpenNotBar(true);
    };
    const closeNotDrawer = () => {
        setOpenNotBar(false);
    };

    // -> store
    const notifications = [
        {
            id: 1,
            notification_type: "Verification approve",
            title: "Verification approved",
            body: "Lorem ipsum dolor sit amet consectetur adipisicing elit amet consectetur adipisicing elit.",
            from: "Golden mapungo",
            date: "5 days ago",
        },
        {
            id: 1,
            notification_type: "Verification denied",
            title: "Verification denied",
            body: "Lorem ipsum dolor sit amet consectetur adipisicing elit amet consectetur adipisicing elit.",
            from: "Golden mapungo",
            date: "5 days ago",
        },
    ];

    // -> Search modal
    const [searchModal, setSearchModal] = useState("0vh");
    const handleSeachModalToggle = () => {
        setSearchModal(searchModal === "0vh" ? "90vh" : "0vh");
        setMobileSearchInput(mobileSearchInput === "block" ? "none" : "block");
    };

    // -> Mobil search input
    const [mobileSearchInput, setMobileSearchInput] = useState("none");
    const handleMobileSearchInput = () => {
        setSearchModal(searchModal === "0vh" ? "90vh" : "0vh");
        setMobileSearchInput(mobileSearchInput === "block" ? "none" : "block");
    };

    return (
        <>
            <AppBar
                position="fixed"
                sx={{
                    boxShadow: "none",
                    width: {
                        xs: "100%",
                        sm: true ? `calc(100% - ${drawerWidth}px)` : `95.8%`,
                    },
                    background: {
                        xs: colors.primary,
                        sm: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor5})`,
                    },
                }}
            >
                {/* ############### Notificationbar ###############*/}
                <NotificationsBar
                    openNotBar={openNotBar}
                    closeNotDrawer={closeNotDrawer}
                />

                <Toolbar
                    sx={{
                        justifyContent: "space-between",
                    }}
                >
                    {/* ############### Left ################## */}
                    <Box
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        {mobileOpen ? (
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerToggle}
                                edge="start"
                                size="small"
                                sx={{
                                    display: { xs: "block", sm: "none" },
                                    marginTop: 1.3,
                                    color: colors.secondary,
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        ) : (
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerToggle}
                                edge="start"
                                size="small"
                                sx={{
                                    display: { xs: "block", sm: "none" },
                                    marginTop: 1.3,
                                    color: colors.secondary,
                                }}
                            >
                                <Sort />
                            </IconButton>
                        )}
                        <Typography
                            sx={{
                                fontSize: 14,
                                fontWeight: "bold",
                                textTransform: "uppercase",
                                color: colors.primary,
                                opacity: 0.8,
                                display: { xs: "none", sm: "block" },
                            }}
                        >
                            Practical training portal
                        </Typography>
                        <Box sx={{ display: { xs: "block", sm: "none" } }}>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    color: colors.primary,
                                    ml: 2,
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: "bold",
                                        fontSize: 18,
                                        color: colors.secondary,
                                    }}
                                >
                                    Wazawa Max
                                </Typography>
                                <Box
                                    sx={{
                                        bgcolor: colors.primary,
                                        borderRadius: 2,
                                    }}
                                >
                                    <Avatar
                                        src={logo}
                                        sx={{
                                            p: 1,
                                            img: {
                                                objectFit: "contain",
                                            },
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                    {/* ############### Center ################## */}
                    <Stack variant="div" style={{ display: "flex" }}>
                        <Box sx={{ display: { xs: "none", sm: "block" } }}>
                            <CustomSearchTextfield
                                searchModal={searchModal}
                                toggleSearchModal={handleSeachModalToggle}
                            />
                        </Box>
                    </Stack>

                    {/* ############### Right ################## */}
                    <TopbarRight
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={3}
                        sx={{ zIndex: 5 }}
                    >
                        <Stack sx={{ display: { xs: "none", sm: "none" } }}>
                            <IconButton
                                size="small"
                                onClick={handleMobileSearchInput}
                            >
                                <Search
                                    sx={{
                                        color: {
                                            xs: colors.secondary,
                                            sm: colors.primary,
                                        },
                                    }}
                                    className="icon"
                                />
                            </IconButton>
                        </Stack>
                        <Stack
                            spacing={4}
                            direction="row"
                            sx={{
                                position: "relative",
                                p: { md: 1, sm: 1, xs: 0.5 },
                                borderRadius: 2,
                                background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.secondary})`,
                            }}
                        >
                            <Badge
                                color="error"
                                badgeContent={size(
                                    filter(
                                        notifications,
                                        ({ isSeen }) => isSeen === 0
                                    )
                                )}
                                showZero
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    cursor: "pointer",
                                    "& .MuiBadge-badge": {
                                        bgcolor: "red",
                                        fontWeight: "bold",
                                        height: 15,
                                    },
                                }}
                                onClick={() => toggleNotDrawer()}
                            >
                                <NotificationsActiveIcon
                                    className="icon"
                                    sx={{
                                        fontSize: { md: 20, sm: 20, xs: 15 },
                                        color: colors.primary,
                                    }}
                                />
                                <Typography
                                    sx={{
                                        fontWeight: "bold",
                                        ml: 0.5,
                                        color: colors.primary,
                                        fontSize: { md: 10, sm: 10, xs: 9 },
                                    }}
                                >
                                    Notification
                                </Typography>
                            </Badge>
                        </Stack>
                    </TopbarRight>

                    {/* ############### Mobile search input ################## */}
                    <Box
                        sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bgcolor: colors.bgColor5,
                            display: { xs: mobileSearchInput, sm: "none" },
                            zIndex: 10,
                        }}
                    >
                        <CustomSearchTextfield
                            toggleSearchModal={handleSeachModalToggle}
                        />
                    </Box>
                </Toolbar>

                {/* ############### Search card ################## */}
                <Box
                    sx={{
                        position: "relative",
                    }}
                >
                    <Box
                        sx={{
                            height: searchModal,
                            position: "absolute",
                            left: 0,
                            right: 0,
                            transition: `.3s all ease-in-out`,
                            borderBottomRightRadius: 25,
                            borderBottomLeftRadius: 25,
                            color: colors.primary,
                            overflow: "hidden",
                            boxShadow: {
                                xs: "none",
                                sm:
                                    searchModal === "0vh"
                                        ? "none"
                                        : `10px 10px 30px 5px ${colors.bgColor5}`,
                            },
                        }}
                    >
                        <Box sx={{ display: { xs: "block", sm: "none" } }}>
                            <CustomSearchCard
                                head={"Filter result"}
                                height={570}
                                borderBottom
                                toggleSearchModal={handleSeachModalToggle}
                            ></CustomSearchCard>
                        </Box>
                        <Box sx={{ display: { xs: "none", sm: "block" } }}>
                            <CustomSearchCard
                                head={"Search for active training vacancy"}
                                height={"100vh"}
                                borderBottom
                                toggleSearchModal={handleSeachModalToggle}
                                // action={<PracticalTrainingFilter />}
                            >
                                {isEmpty(practicalTrainings) ? (
                                    <>
                                        <NoContent
                                            height={300}
                                            message={
                                                "No active practical trainings"
                                            }
                                        />
                                    </>
                                ) : (
                                    <Grid container spacing={3}>
                                        {practicalTrainings.map(
                                            (training, index) => (
                                                <Grid
                                                    item
                                                    sm={6}
                                                    key={training.id}
                                                >
                                                    <FieldWidgetOne
                                                        training={training}
                                                    />
                                                </Grid>
                                            )
                                        )}
                                    </Grid>
                                )}
                            </CustomSearchCard>
                        </Box>
                    </Box>
                </Box>
            </AppBar>
        </>
    );
};

export default Topbar;
