import React from "react";
import { Select } from "@mui/material";
import { Box, FormControl, InputLabel } from "@mui/material";
import { ErrorMessage, useField } from "formik";

const SmallCustomSelectInput = ({
    formik,
    label,
    color,
    children,
    handler,
    ...props
}) => {
    const [field, meta] = useField(props);
    const [selectValue, setSelectValue] = React.useState(
        field.value ? field.value : ""
    );

    const handleChange = (e) => {
        setSelectValue(e.target.value);
        const fieldName = field.name;
        formik.setFieldValue(`${fieldName}`, e.target.value);
        // -> posting fee
        handler && handler(e.target.value);
    };

    return (
        <>
            <Box
                sx={{
                    borderRadius: 1.5,
                    overflow: "hidden",
                }}
            >
                <FormControl variant="filled" sx={{ minWidth: "100%" }}>
                    <InputLabel
                        id={label}
                        sx={{
                            color: `${color ? color : "none"} !important`,
                        }}
                    >
                        {label}
                    </InputLabel>
                    <Select
                        className={`${meta.touched && meta.error && "error"}`}
                        {...field}
                        {...props}
                        minRows={3}
                        placeholder={label}
                        value={selectValue}
                        onChange={handleChange}
                        sx={{ color: `${color ? color : "#eee"}` }}
                        size={"small"}
                        displayEmpty
                    >
                        {children}
                    </Select>
                </FormControl>
                <ErrorMessage
                    component="span"
                    name={field.name}
                    className="error-text"
                />
            </Box>
        </>
    );
};

export default SmallCustomSelectInput;
