import { Box, Button, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import CustomCard2 from '../../../components/cards/CustomCard2';
import { SaveAlt } from '@mui/icons-material';
import ArraivalLetter from '../../reports/ArraivalLetter';
import ReportDialog from '../../../components/dialogs/ReportDialog';

const TrainingDocs = () => {
    // ############### Comp State #################
    const [openDialog, setOpenDialog] = useState(false);
    const [actionHead, setActionHead] = useState('');
    const [actionType, setActionType] = useState('');

    // ############## FUNC #################
    // => Handle dialogy open
    const handleDialogOpen = (action, head) => {
        setActionType(action);
        setActionHead(head);
        setOpenDialog(true);
    };
    // => Handle dialogy close
    const handleDialogClose = () => {
        setActionHead('');
        setOpenDialog(false);
    };

    return (
        <>
            {/* ############# DIALOG ############### */}
            <ReportDialog
                isConfirm
                noDropClose
                openDialog={openDialog}
                customWidth={'md'}
                handleDialogClose={handleDialogClose}
                head={actionHead}
            >
                {actionType === 'arrival' ? <ArraivalLetter /> : ''}
            </ReportDialog>

            <Grid container justifyContent={'space-around'} padding={{md: 3, xs: 0}} spacing={{md: 0, xs: 3}}>
                <Grid item sm={5} xs={12}>
                    <CustomCard2 head={'Arrival letter'}>
                        <Box>
                            <Typography className="info-message">
                                Lorem ipsum dolor sit amet consectetur
                                adipisicing elit. Molestiae omnis pariatur
                                sapiente iure officia.
                            </Typography>
                            <Button
                                variant="outlined"
                                fullWidth
                                sx={{ mt: 3 }}
                                color="success"
                                startIcon={<SaveAlt />}
                                onClick={() => {
                                    let action = 'arrival';
                                    let head =
                                        'Get practical training arrival letter';
                                    handleDialogOpen(action, head);
                                }}
                            >
                                Get document
                            </Button>
                        </Box>
                    </CustomCard2>
                </Grid>
                <Grid item sm={5} xs={12}>
                    <CustomCard2 head={'Appriciation letter'}>
                        <Box>
                            <Typography className="info-message">
                                Lorem ipsum dolor sit amet consectetur
                                adipisicing elit. Molestiae omnis pariatur
                                sapiente iure officia.
                            </Typography>
                            <Button
                                variant="outlined"
                                fullWidth
                                sx={{ mt: 3 }}
                                color="success"
                                startIcon={<SaveAlt />}
                            >
                                Get document
                            </Button>
                        </Box>
                    </CustomCard2>
                </Grid>
            </Grid>
        </>
    );
};

export default TrainingDocs;
