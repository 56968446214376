import React, { useState } from "react";
import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import { colors } from "../../assets/utils/constants";
import MobApp from "../../components/MobApp";
import PageLayout from "../layouts/PageLayout";
import { ManageAccounts } from "@mui/icons-material";
import CustomDialog from "../../components/dialogs/CustomDialog";
import { useSelector } from "react-redux";
import { profileSelector } from "../../states/features/selectors";
import BecomeBeneficiary from "./CRUD/BecomeBeneficiary";

const WazawaMaxMob = () => {
    // ############### Comp State ################
    const [openDialog, setOpenDialogy] = useState(false);
    const deviceWidth = useMediaQuery("(min-width:768px)");

    // ############### Redux state ################
    const { profile: student } = useSelector(profileSelector);

    // ############### FUNC ################
    // -> Dialogy open
    const handleDialogyOpen = () => {
        setOpenDialogy(true);
    };
    // -> Dialogy closer
    const handleDialogClose = () => {
        setOpenDialogy(false);
    };

    return (
        <>
            {/* ##################### DIALOG ################# */}
            <CustomDialog
                head={"Become beneficiary"}
                openDialog={openDialog}
                handleDialogClose={handleDialogClose}
                fullScreen={deviceWidth ? false : true}
                noDropClose
            >
                <BecomeBeneficiary
                    student={student}
                    handleDialogClose={handleDialogClose}
                />
            </CustomDialog>
            <PageLayout head={"Wazawa Max Mobile application"} noSidebar>
                <Grid container justifyContent={"center"} spacing={5}>
                    <Grid item sm={11}>
                        <Box
                            sx={{
                                background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor4}) !important`,
                                p: 5,
                                borderRadius: 4,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-around",
                                position: "relative",
                            }}
                        >
                            <Box
                                sx={{
                                    width: 15,
                                    background: `linear-gradient(90deg, ${colors.secondary}, ${colors.bgColor1})`,
                                    position: "absolute",
                                    top: 20,
                                    bottom: 20,
                                    left: 0,
                                    borderTopRightRadius: 100,
                                    borderBottomRightRadius: 100,
                                }}
                            ></Box>
                            <Box
                                sx={{
                                    width: 15,
                                    background: `linear-gradient(90deg, ${colors.bgColor1}, ${colors.secondary})`,
                                    position: "absolute",
                                    top: 20,
                                    bottom: 20,
                                    right: 0,
                                    borderTopLeftRadius: 100,
                                    borderBottomLeftRadius: 100,
                                }}
                            ></Box>
                            <Box>
                                <Typography
                                    sx={{
                                        fontSize: 22,
                                        fontWeight: "bold",
                                        opacity: 0.8,
                                    }}
                                >
                                    Wazawa Max App For students
                                </Typography>
                            </Box>
                            <Box>
                                <Typography sx={{ fontSize: 15, py: 2 }}>
                                    Enjoy using the Wazawa Max Blog as your
                                    entry point to the newest trends and success
                                    tales! Our website now has a lively blog
                                    that informs potential employees and
                                    employers about all the latest trends in the
                                    Wazawa Max network.
                                </Typography>
                                <Typography sx={{ fontSize: 15, py: 2 }}>
                                    Discover a wealth of informative
                                    information, such as job seekers'
                                    endorsements, motivating success stories of
                                    students who profited from our network, and
                                    profiles of innovative businesses using
                                    in-app advertising spaces to reach their
                                    target customers in real time.
                                </Typography>
                                <Typography sx={{ fontSize: 15, py: 2 }}>
                                    Stay informed, get inspired, and be part of
                                    the Wazawa Max community through our vibrant
                                    blog. It's your source for fresh insights,
                                    success stories, and the latest happenings
                                    in the world of talent acquisition and
                                    in-app advertising.
                                </Typography>
                            </Box>
                            {!student?.isBeneficiary && (
                                <Box sx={{ textAlign: "center", mt: 2 }}>
                                    <Button
                                        variant="contained"
                                        className="app-btn"
                                        size="large"
                                        color="primary"
                                        startIcon={<ManageAccounts />}
                                        onClick={handleDialogyOpen}
                                    >
                                        Become a job seaker
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    </Grid>
                    <Grid item sm={12}>
                        <MobApp />
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default WazawaMaxMob;
