import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { colors } from "../../assets/utils/constants";
import CustomCard2 from "../cards/CustomCard2";
import mobApp from "../../assets/media/images/mobApp.png";
import { ArrowCircleRightOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { practicalTrainingsSelector } from "../../states/features/selectors";
import { useSelector } from "react-redux";
import { filter, size } from "lodash";

const DashSidebar = () => {
    // ############### Redux state #################
    const { practicalTrainings: activePTs } = useSelector(
        practicalTrainingsSelector
    );
    // => Applied
    const appliedPTs = filter(activePTs, ({ isApplied }) => isApplied === true);

    return (
        <>
            <CustomCard2 head={"Overviews"} position={"static"}>
                <Box
                    sx={{
                        width: "100%",
                        borderRadius: 2,
                        display: "flex",
                        textAlign: "center",
                        justifyContent: "space-between",
                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                        border: `1px solid ${colors.bgColor5}`,
                    }}
                >
                    <Box
                        sx={{
                            p: 1,
                            width: "100%",
                            border: `1px solid ${colors.bgColor5}`,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 12,
                                fontWeight: "bold",
                                color: colors.info,
                            }}
                            noWrap
                        >
                            Active training
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 16,
                                fontWeight: "bold",
                                color: colors.info,
                            }}
                        >
                            {size(activePTs)}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            width: "100%",
                            border: `1px solid ${colors.bgColor5}`,
                            p: 1,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 12,
                                fontWeight: "bold",
                                color: colors.success,
                            }}
                            noWrap
                        >
                            Applied training
                        </Typography>
                        <Typography>{size(appliedPTs)}</Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        height: 400,
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        borderRadius: 4,
                        textAlign: "center",
                    }}
                >
                    <center>
                        <Box sx={{ zIndex: 50, width: { xs: 350, sm: 250 } }}>
                            <img
                                src={mobApp}
                                alt=""
                                style={{
                                    width: "100%",
                                    zIndex: 999,
                                    height: 280,
                                }}
                            />
                        </Box>
                    </center>
                    <Box
                        sx={{
                            height: 250,
                            background: `linear-gradient(180deg, ${colors.secondary}, ${colors.bgColor4})`,
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            right: 0,
                            borderRadius: 4,
                            zIndex: -1,
                        }}
                    ></Box>
                    <Box
                        sx={{
                            height: 120,
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.secondary})`,
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            right: 0,
                            borderRadius: 4,
                            zIndex: 50,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Box sx={{ zIndex: 999 }}>
                            <Box>
                                <Typography sx={{ fontSize: 13 }}>
                                    If you yearn to learn, you'll learn to earn.
                                </Typography>
                                <Typography
                                    sx={{ fontSize: 22, fontWeight: "bold" }}
                                >
                                    WazawaMax APP
                                </Typography>
                            </Box>
                            <Box sx={{ textAlign: "center" }}>
                                <Link to={"/wazawa-max-app"}>
                                    <Button
                                        variant="contained"
                                        endIcon={<ArrowCircleRightOutlined />}
                                        size="small"
                                        color="info"
                                        sx={{ mt: 1 }}
                                        className="app-btn"
                                    >
                                        Learn more
                                    </Button>
                                </Link>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </CustomCard2>
        </>
    );
};

export default DashSidebar;
