import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { studentBaseURL } from '../baseUrl/baseURL';
import { headers } from '../baseUrl/setHeaders';
import { authEndpoints } from './authEndpoints';

export const authAPI = createApi({
    reducerPath: 'authAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: studentBaseURL,
        prepareHeaders: headers,
    }),
    tagTypes: ['Login'],
    endpoints: (builder) => ({
        // => Login
        login: builder.mutation({
            query: (payload) => ({
                url: `${authEndpoints.LOGIN}`,
                method: 'POST',
                body: payload,
            }),
            providesTags: ['Login'],
        }),
        // => Logout
        logout: builder.mutation({
            query: () => ({
                url: `${authEndpoints.LOGOUT}`,
                method: `POST`,
            }),
        }),
    }),
});

export const { useLoginMutation, useLogoutMutation } = authAPI;
