import { useEffect } from "react";
import { errorNotify, successNotify } from "../helpers/notify";
import {
    getRTKErrorMessage,
    getRTKResponseData,
    getRTKSuccessMessage,
} from "../helpers/RTKHelpers";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { setUserProfile } from "../states/features/profile/profileSlice";
import { useLazyGetProfileQuery } from "../api/profile/profileAPI";
import { useLazyGetPracticalTrainingsQuery } from "../api/practicalTrainings/practicalTrainingsAPI";
import { setPracticalTrainings } from "../states/features/practicalTrainings/practicalTrainingsSlice";
import { disableMainFetch } from "../states/features/systemConfig/systemConfigSlice";
import { systemConfigSelector } from "../states/features/selectors";
import { useLazyGetActiveRegionsQuery } from "../api/admin/adminAPI";
import { setRegions } from "../states/features/admin/adminSlice";

const useRTK = () => {
    //  ################# Redux state ####################
    const { isMainFetch } = useSelector(systemConfigSelector);

    // => Use RTK Response
    const useRTKResponse = (isSuccess, isError, error, data, actions) => {
        useEffect(() => {
            let isSubscribed = true;
            if (isSubscribed) {
                if (isSuccess) {
                    actions && actions(getRTKResponseData(data));
                    successNotify(getRTKSuccessMessage(data));
                }
                if (isError) {
                    errorNotify(
                        !isEmpty(error)
                            ? getRTKErrorMessage(error)
                            : "Some error occur. Please try again!"
                    );
                }
            }
            return () => {
                isSubscribed = false;
            };
        }, [isSuccess, isError, error, data]);
    };

    // => Use RTK Fetch
    const useRTKFetch = (isSuccess, data, actions) => {
        useEffect(() => {
            let isSubscribed = true;
            if (isSubscribed) {
                if (isSuccess) {
                    actions(getRTKResponseData(data));
                }
            }
            return () => {
                isSubscribed = false;
            };
        }, [isSuccess, data]);
    };

    // => RTK main data
    const useRTKMainData = () => {
        // => CONST
        const dispatch = useDispatch();

        // ################# Profile #################
        const [
            getProfile,
            {
                isLoading: profileLoading,
                isSuccess: profileSuccess,
                data: profileData,
            },
        ] = useLazyGetProfileQuery();
        const rtkPofileActions = (data) => {
            dispatch(setUserProfile(data));
        };
        useRTKFetch(profileSuccess, profileData, rtkPofileActions);

        // ################# Practical Trainings #################
        const [
            getPTs,
            { isLoading: ptLoading, isSuccess: ptSuccess, data: ptData },
        ] = useLazyGetPracticalTrainingsQuery();
        const rtkPTActions = (data) => {
            dispatch(setPracticalTrainings(data));
        };
        useRTKFetch(ptSuccess, ptData, rtkPTActions);

        // ################# Active region #################
        const [
            getActiveRegions,
            {
                isLoading: regionLoading,
                isSuccess: regionSuccess,
                data: regionData,
            },
        ] = useLazyGetActiveRegionsQuery();
        const rtkRegionActions = (data) => {
            dispatch(setRegions(data));
        };
        useRTKFetch(regionSuccess, regionData, rtkRegionActions);

        // ############## useEffect #################
        useEffect(() => {
            let isSubscribed = true;
            if (isSubscribed) {
                if (isMainFetch) {
                    getProfile();
                    getPTs();
                    getActiveRegions();
                    dispatch(disableMainFetch());
                }
            }
            return () => {
                isSubscribed = false;
            };
        }, []);

        return profileLoading ? profileLoading : ptLoading ? ptLoading : false;
    };

    return {
        useRTKFetch,
        useRTKResponse,
        useRTKMainData,
    };
};

export default useRTK;
