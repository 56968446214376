import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import CustomTextField from '../../../components/Forms/CustomTextField';
import { colors } from '../../../assets/utils/constants';
import {
    ArrowRightAltOutlined,
    VerifiedUserOutlined,
} from '@mui/icons-material';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useRequestOTPMutation } from '../../../api/auth/registerAPI';
import { setPinId } from '../../../states/features/auth/registerSlice';
import { registerSelector } from '../../../states/features/selectors';
import useRTK from '../../../hooks/useRTK';
import CustomDialog from '../../../components/dialogs/CustomDialog';
import PhoneVerification from './PhoneVerification';
import CustomSubmitButton from '../../../components/Forms/CustomSubmitButton';

// ############# Validation Schema ##############
const validationSchema = Yup.object({
    phone_number: Yup.string()
        .required('Phone number is required field')
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            'Positions must be a number',
        )
        .max(10, 'Phone number must be at most 10 characters')
        .min(10, 'Phone number must be at least 10 characters'),
});

const PhoneVerify = ({ steps, activeStep, handleNext, handleBack }) => {
    // ################# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ########### Comp State ##################
    const [openDialog, setOpenDialogy] = useState(false);

    // ############## Redux status ############
    const { phoneNumber } = useSelector(registerSelector);

    // ############### RTK #####################
    // => Request OTP
    const [
        requestOTP,
        { isLoading, isSuccess, isError, error, data: otpData },
    ] = useRequestOTPMutation();

    // ############### useEffect ################
    // => RTK action
    const rtkActions = (data) => {
        dispatch(setPinId(data));
        handleDialogOpen();
    };
    RTK.useRTKResponse(isSuccess, isError, error, otpData, rtkActions);

    // ################ FUNC ###################
    // -> Dialogy closer
    const handleDialogOpen = () => {
        setOpenDialogy(true);
    };

    // -> Dialogy closer
    const handleDialogClose = () => {
        setOpenDialogy(false);
    };

    return (
        <>
            {/* ##################### DIALOG ################# */}
            <CustomDialog
                head={'Verifiy phone number'}
                openDialog={openDialog}
                handleDialogClose={handleDialogClose}
                noDropClose={handleDialogOpen}
            >
                <PhoneVerification
                    handleNext={handleNext}
                    phoneNumber={phoneNumber}
                    closeDialogy={handleDialogClose}
                />
            </CustomDialog>

            <Formik
                enableReinitialize
                initialValues={{
                    phone_number: phoneNumber
                        ? 0 + phoneNumber.substring(3)
                        : '',
                }}
                validationSchema={validationSchema}
                onSubmit={(payload) => {
                    requestOTP(payload);
                    // handleNext();
                }}
            >
                {(formik) => (
                    <Grid
                        container
                        justifyContent={'center'}
                        padding={2}
                        py={{ md: 1.5 }}
                    >
                        <Grid
                            item
                            sm={12}
                            xs={12}
                            sx={{ pb: { md: 4 }, pt: { md: 1 } }}
                        >
                            <Typography className="head">
                                Phone number verification
                            </Typography>
                            <Typography className="sub-head">
                                Please provide your phone number for
                                verification
                            </Typography>
                        </Grid>
                        <Grid item sm={11} xs={12} sx={{ mb: 4 }}>
                            <CustomTextField
                                className="form-input"
                                label={`Phone number`}
                                type="text"
                                name="phone_number"
                                bBottom={colors.secondary}
                            />
                        </Grid>

                        {/* ################ ACTION BUTONS ###################### */}
                        <Grid
                            item
                            sm={11}
                            xs={12}
                            sx={{
                                textAlign: 'center',
                            }}
                        >
                            <CustomSubmitButton
                                startIcon={<VerifiedUserOutlined />}
                                endIcon={<ArrowRightAltOutlined />}
                                btnColor={`secondary`}
                                variant={'contained'}
                                loading={isLoading}
                                onClick={() => {
                                    formik.handleSubmit();
                                }}
                                sx={{ py: 1.5, fontSize: 16 }}
                            >
                                Request verification OTP
                            </CustomSubmitButton>
                        </Grid>
                        {/* <Grid
                            item
                            sm={11}
                            xs={12}
                            sx={{
                                marginTop: 2.5,
                                textAlign: 'center',
                            }}
                        >
                            <NextAndBackBtn
                                steps={steps}
                                activeStep={activeStep}
                                handleBack={handleBack}
                                action={formik.handleSubmit}
                            />
                        </Grid> */}

                        {/* ######### sign_in ######### */}
                        <Grid
                            item
                            sm={12}
                            sx={{
                                marginTop: 2.5,
                                textAlign: 'center',
                            }}
                        >
                            <Link to={'/'}>
                                <Typography
                                    className="sign_in"
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    Sign in here
                                    <ArrowRightAltOutlined color="secondary" />
                                </Typography>
                            </Link>
                        </Grid>
                    </Grid>
                )}
            </Formik>
        </>
    );
};

export default PhoneVerify;
