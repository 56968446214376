// Get PT's
const GET_PTs = '/practical-trainings';

// Get single PT
const GET_SINGLE_PT = '/practical-trainings';

// Get training season
const GET_TRAINING_SEASONS = '/practical-trainings/seasons';

// Add to favorites
const ADD_TO_FAVORITES = '/practical-trainings/favorites';

// Remove from favorites
const REMOVE_FROM_FAVORITES = '/practical-trainings/favorites';

// Apply training
const APPLY_PT = '/practical-trainings/applications/apply';

export const practicalTrainingEndpoints = {
    GET_PTs,
    GET_SINGLE_PT,
    GET_TRAINING_SEASONS,
    ADD_TO_FAVORITES,
    REMOVE_FROM_FAVORITES,
    APPLY_PT,
};
