import React, { useState } from "react";
import { Grid, useMediaQuery } from "@mui/material";
import CustomCard2 from "../../components/cards/CustomCard2";
import OccupationCard from "../../components/dash/OccupationCard";
import PageLayout from "../layouts/PageLayout";
import ChangePassword from "./ChangePassword";
import GeneralDetails from "./components/GeneralDetails";
import { useSelector } from "react-redux";
import { profileSelector } from "../../states/features/selectors";
import { isEmpty } from "lodash";
import Institution from "./components/Institution";
import CustomDialog from "../../components/dialogs/CustomDialog";
import UpdateProfile from "./CRUD/UpdateProfile";
import UpdateProfilePicture from "./CRUD/UpdateProfilePicture";

const Profile = () => {
    // ############### Comp State ################
    const [crudType, setCRUDType] = useState("");
    const [openDialog, setOpenDialogy] = useState(false);
    const [dialogHead, setDialogHead] = useState(false);
    const deviceWidth = useMediaQuery("(min-width:768px)");

    // ############ Redux State ###############
    const { profile: student } = useSelector(profileSelector);

    // ############### FUNC ################
    // -> Dialogy open
    const handleDialogyOpen = (crudType, dialogHead) => {
        setCRUDType(crudType);
        setDialogHead(dialogHead);
        setOpenDialogy(true);
    };
    // -> Dialogy closer
    const handleDialogClose = () => {
        setOpenDialogy(false);
    };

    return (
        <>
            {/* ##################### DIALOG ################# */}
            <CustomDialog
                head={dialogHead}
                openDialog={openDialog}
                handleDialogClose={handleDialogClose}
                fullScreen={deviceWidth ? false : true}
                noDropClose
            >
                {crudType === "update" ? (
                    <UpdateProfile
                        handleDialogClose={handleDialogClose}
                        student={student}
                    />
                ) : (
                    <UpdateProfilePicture
                        handleDialogClose={handleDialogClose}
                        student={student}
                    />
                )}
            </CustomDialog>
            <PageLayout
                head={"Manage account profile"}
                sidebar={
                    <CustomCard2 head={"Change password"}>
                        <ChangePassword />
                    </CustomCard2>
                }
                noSidebar
            >
                <Grid container justifyContent={"center"} spacing={2}>
                    <Grid item sm={5}>
                        <GeneralDetails
                            handleDialogyOpen={handleDialogyOpen}
                            student={student}
                        />
                    </Grid>
                    <Grid item sm={7}>
                        <Grid
                            container
                            rowSpacing={{ md: 8, xs: 2 }}
                            columnSpacing={2}
                        >
                            <Grid item xs={12} sm={12}>
                                {!isEmpty(student) && (
                                    <Institution student={student} />
                                )}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomCard2
                                    head={"Program | Occupation | Section"}
                                    height={"44vh"}
                                >
                                    {!isEmpty(student) && (
                                        <OccupationCard student={student} />
                                    )}
                                </CustomCard2>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomCard2
                                    head={"Change password"}
                                    height={"44vh"}
                                >
                                    <ChangePassword />
                                </CustomCard2>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default Profile;
