import { AppRegistration, LocationOn, MoreVert } from '@mui/icons-material';
import {
    Avatar,
    Box,
    Button,
    Chip,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { colors } from '../../assets/utils/constants';
import ApplyConfimation from '../confimations/ApplyConfimation';
import CustomDialog from '../dialogs/CustomDialog';
import FeedbackDialog from '../dialogs/FeedbackDialog';

const FieldWidget = () => {
    const [openDialog, setOpenDialog] = useState(false);
    const handleConfimationDialogOpen = () => {
        setOpenDialog(true);
    };
    const handleConfimationDialogClose = () => {
        setOpenDialog(false);
    };
    const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false);
    const handleFeedbackDialogOpen = () => {
        setOpenDialog(false);
        setOpenFeedbackDialog(true);
    };
    const handleFeedbackDialogClose = () => {
        setOpenFeedbackDialog(false);
    };

    return (
        <>
            {/* ############# DIALOG ############### */}
            <FeedbackDialog
                isConfirm
                openDialog={openFeedbackDialog}
                handleClose={handleFeedbackDialogClose}
                message={
                    'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
                }
            />
            <CustomDialog
                isConfirm
                noDropClose
                openDialog={openDialog}
                handleDialogClose={handleConfimationDialogClose}
                head="Confirm practical training application"
            >
                <ApplyConfimation
                    handleDialogClose={handleConfimationDialogClose}
                    handleConfirm={handleFeedbackDialogOpen}
                />
            </CustomDialog>

            {/* ############# CONTENTS ############# */}
            <Box
                sx={{
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                    borderTop: `5px solid ${colors.bgColor5}`,
                    borderRadius: 2,
                    overflow: 'hidden',
                    boxShadow: `1px 1px 10px 0px ${colors.bgColor5}`,
                }}
            >
                <Box
                    sx={{
                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                        p: 1,
                        textAlign: 'center',
                        position: 'relative',
                    }}
                >
                    <Typography>IT Assistant</Typography>
                    <Typography sx={{ fontSize: 12 }}>
                        Computing | Computer science
                    </Typography>
                    <Box sx={{ position: 'absolute', left: 13, top: 10 }}>
                        <Chip
                            label="Applied"
                            size="small"
                            color="success"
                            sx={{ fontSize: 10, fontWeight: 'bold' }}
                        />
                    </Box>
                    <Box sx={{ position: 'absolute', right: 13, top: 10 }}>
                        <Link to={'/practical-trainings/details'}>
                            <IconButton
                                size="small"
                                color="info"
                                sx={{
                                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor5})`,
                                }}
                            >
                                <MoreVert />
                            </IconButton>
                        </Link>
                    </Box>
                </Box>
                <Box
                    sx={{
                        p: 1.5,
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box>
                        <ListItem alignItems="flex-start" sx={{ p: 0 }}>
                            <ListItemAvatar>
                                <Avatar
                                    alt="Business Op"
                                    src=""
                                    variant="square"
                                    sx={{ borderRadius: 2 }}
                                />
                            </ListItemAvatar>
                            <ListItemText
                                sx={{
                                    '.MuiListItemText-primary': {
                                        fontSize: 14,
                                        fontWeight: 'bold',
                                    },
                                }}
                                primaryTypographyProps={{
                                    color: colors.primary,
                                }}
                                primary={"VETA Chang'ombe"}
                                secondary={
                                    <React.Fragment>
                                        <Typography
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                        >
                                            <LocationOn
                                                sx={{
                                                    fontSize: 14,
                                                    color: colors.secondary,
                                                }}
                                            />
                                            {'Dar-es-saalam'}
                                        </Typography>
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                        <Typography
                            sx={{
                                fontSize: 14,
                                width: { xs: 100, sm: 280 },
                                mt: 0.5,
                            }}
                            noWrap
                        >
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Nulla, eius exercitationem itaque illum
                            maiores laudantium.
                        </Typography>
                    </Box>
                    <Box>
                        <Box
                            sx={{
                                p: 0.5,
                                px: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: 2,
                                background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.secondary})`,
                            }}
                        >
                            <Typography sx={{ fontSize: 12 }}>
                                Applicants
                            </Typography>
                            <Typography sx={{ fontSize: 12 }}>89</Typography>
                        </Box>
                        <Box
                            sx={{
                                p: 0.5,
                                px: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: 2,
                                background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor4})`,
                                mt: 1,
                            }}
                        >
                            <Typography sx={{ fontSize: 12 }}>
                                6 days ago
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box
                        sx={{
                            textAlign: 'center',
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                            p: 1,
                            width: '100%',
                            borderBottom: `5px solid ${colors.success}`,
                        }}
                    >
                        <Typography
                            noWrap
                            sx={{
                                fontSize: 12,
                                fontWeight: 'bold',
                            }}
                        >
                            Open
                        </Typography>
                        <Typography noWrap sx={{ fontSize: 12 }}>
                            20 Jan 2023
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            textAlign: 'center',
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                            p: 1,
                            width: '100%',
                            borderLeft: `1px solid ${colors.bgColor5}`,
                            borderRight: `1px solid ${colors.bgColor5}`,
                            borderBottom: `5px solid ${colors.danger}`,
                        }}
                    >
                        <Typography
                            noWrap
                            sx={{
                                fontSize: 12,
                                fontWeight: 'bold',
                            }}
                        >
                            Close
                        </Typography>
                        <Typography noWrap sx={{ fontSize: 12 }}>
                            20 Jan 2023
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            textAlign: 'center',
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                            p: 1,
                            width: '100%',
                            borderBottom: `5px solid ${colors.primary}`,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Button
                            className="app-btn"
                            variant="contained"
                            size="small"
                            startIcon={<AppRegistration />}
                            onClick={handleConfimationDialogOpen}
                        >
                            Apply now
                        </Button>
                    </Box>
                </Box>
                <Box></Box>
            </Box>
        </>
    );
};

export default FieldWidget;
