import { Grid } from '@mui/material';
import React from 'react';
import CustomCard2 from '../../components/cards/CustomCard2';
import FieldWidget from '../../components/widgets/FieldWidget';
import PageLayout from '../layouts/PageLayout';

const ActivePT = () => {
    return (
        <>
            <PageLayout head={'Active practical trainings'} noSidebar>
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <CustomCard2 height={610} borderBottom maxNoScroll>
                            <Grid container spacing={3}>
                                {[1, 2, 3, 4, 5, 6].map((index) => (
                                    <Grid item xs={12} sm={4} key={index}>
                                        <FieldWidget />
                                    </Grid>
                                ))}
                            </Grid>
                        </CustomCard2>
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default ActivePT;
