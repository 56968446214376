import React from 'react';
import { Grid } from '@mui/material';
import { helpCenterSelector } from '../../states/features/selectors';
import { useDispatch, useSelector } from 'react-redux';
import useRTK from '../../hooks/useRTK';
import { setSocialNetworks } from '../../states/features/helpCenter/helpCenterSlice';
import { useGetSocialNetworksQuery } from '../../api/helpCenter/helpCenterAPI';
import SocialMediaWidget from '../../components/widgets/SocialMediaWidget';
import NoContent from '../../components/NoContent';
import { isEmpty } from 'lodash';
import ContentLoader from '../../components/ContentLoader';

const SocialNetworks = () => {
    // ############ CONST ###############
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############ Redux state ##############
    const { socialNetworks } = useSelector(helpCenterSelector);

    // ############## RTK ##############
    const {
        isLoading,
        isSuccess,
        data: contacstData,
    } = useGetSocialNetworksQuery();
    // => Action
    const rtkAction = (data) => {
        dispatch(setSocialNetworks(data));
    };
    // => Response
    RTK.useRTKFetch(isSuccess, contacstData, rtkAction);

    console.log(socialNetworks)

    return (
        <div>
            <Grid container spacing={2}>
                {isLoading ? (
                    <Grid item xs>
                        <ContentLoader height={70} />
                    </Grid>
                ) : isEmpty(socialNetworks) ? (
                    <Grid item sm={12} xs={12}>
                        <NoContent
                            message={'No social media available'}
                            height={'10vh'}
                        />
                    </Grid>
                ) : (
                    socialNetworks?.map((media, index) => (
                        <Grid item sm={6} key={index}>
                            <SocialMediaWidget media={media} />
                        </Grid>
                    ))
                )}
            </Grid>
        </div>
    );
};

export default SocialNetworks;
