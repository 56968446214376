import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isMainFetch: true,
};

// !########## SLICE #############
const systemConfigSlice = createSlice({
    name: "systemConfigSlice",
    initialState,
    reducers: {
        // => Deactivate main fetch
        disableMainFetch: (state, actions) => {
            state.isMainFetch = false;
        },
    },
});

export const { disableMainFetch } = systemConfigSlice.actions;
export default systemConfigSlice.reducer;
