import { Box, Grid } from '@mui/material';
import React from 'react';
import { colors } from '../../assets/utils/constants';

const DashboardLayout = ({ sidebar, children, head, noSidebar }) => {
    return (
        <>
            <Grid
                container
                spacing={2}
                padding={2}
                paddingBottom={0}
                sx={{
                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor3}) !important`,
                }}
            >
                <Grid item xs={12} sm={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={noSidebar ? 12 : 9}>
                            <Box>{children}</Box>
                        </Grid>
                        {!noSidebar && (
                            <Grid item xs={12} sm={3}>
                                <Box
                                    sx={{
                                        height: { xs: 600, sm: `89vh` },
                                        position: 'sticky',
                                        top: 80,
                                        py: 1.5,
                                        p: 1.5,
                                        background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor2}) !important`,
                                        borderRadius: 4,
                                    }}
                                >
                                    {sidebar}
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default DashboardLayout;
