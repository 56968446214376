import React from 'react';
import { Box, Grid, MenuItem, Typography } from '@mui/material';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import { colors } from '../../../assets/utils/constants';
import CustomSelectInput from '../../../components/Forms/CustomSelectInput';
import {
    ArrowRightAltOutlined,
    VerifiedUserOutlined,
} from '@mui/icons-material';
import * as Yup from 'yup';
import useRTK from '../../../hooks/useRTK';
import { useDispatch, useSelector } from 'react-redux';
import { useGetActiveSectionsQuery } from '../../../api/institute/instituteAPI';
import { setActiveSections } from '../../../states/features/institute/instituteSlice';
import {
    instituteSelector,
    registerSelector,
} from '../../../states/features/selectors';
import ContentLoader from '../../../components/ContentLoader';
import InnerDataFetchingError from '../../../components/InnerDataFetchingError';
import { setSelectedSection } from '../../../states/features/auth/registerSlice';
import { getRTKErrorMessage } from '../../../helpers/RTKHelpers';
import CustomSubmitButton from '../../../components/Forms/CustomSubmitButton';

// ############# Validation Schema ##############
const validationSchema = Yup.object({
    section_id: Yup.string().required('Section field is required'),
    start_year: Yup.number().required('Start year field is required'),
    finish_year: Yup.number()
        .required('Finish year field is required')
        .min(
            Yup.ref('start_year'),
            `finish year must be later than start year`,
        ),
});

const ProgramSelection = ({ steps, activeStep, handleNext, handleBack }) => {
    // ############ CONST ################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############ Reducer state #############
    const { sections } = useSelector(instituteSelector);
    const { preProfile } = useSelector(registerSelector);

    // ############# RTK #################
    const {
        isLoading: sectionLoading,
        isSuccess: sectionSuccess,
        isError: sectionIsError,
        error: sectionError,
        data: sectionData,
        refetch,
    } = useGetActiveSectionsQuery(preProfile.institute_id);
    // => Sections
    const rtkSectionsAction = (data) => {
        dispatch(setActiveSections(data));
    };
    RTK.useRTKFetch(sectionSuccess, sectionData, rtkSectionsAction);

    const getYears = () => {
        const start = new Date().getFullYear() - 6;
        const end = new Date().getFullYear() + 6;
        let yearCount = [];
        for (let i = start; i < end; i++) {
            yearCount.push(i);
        }
        return yearCount;
    };

    return (
        <>
            <Box
                sx={{
                    position: 'relative',
                    height: sectionLoading || sectionIsError ? 400 : '100%',
                }}
            >
                {sectionLoading ? (
                    <ContentLoader
                        height={300}
                        loaderColor={colors.secondary}
                    />
                ) : sectionIsError ? (
                    <Grid container justifyContent={'center'}>
                        <Grid item sm={11} xs={11}>
                            <InnerDataFetchingError
                                message={getRTKErrorMessage(sectionError)}
                                handleDataRefetch={refetch}
                            />
                        </Grid>
                    </Grid>
                ) : (
                    <Formik
                        enableReinitialize
                        initialValues={{
                            section_id: preProfile.section_id
                                ? preProfile.section_id
                                : '',
                            start_year: preProfile.start_year,
                            finish_year: preProfile.finish_year,
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(payload) => {
                            dispatch(setSelectedSection(payload));
                            handleNext();
                        }}
                    >
                        {(formik) => (
                            <Grid
                                container
                                justifyContent={'center'}
                                padding={2}
                                sx={{ pb: { md: 4 }, pt: { md: 1 } }}
                            >
                                <Grid item sm={12}>
                                    <Typography className="head">
                                        Program (occupation) Selection
                                    </Typography>
                                    <Typography className="sub-head">
                                        Please provide infomation about you
                                        occupation.
                                    </Typography>
                                </Grid>
                                <Grid item sm={11} xs={12}>
                                    <CustomSelectInput
                                        className="form-input"
                                        label={`Select program`}
                                        name="section_id"
                                        formik={formik}
                                        color={colors.secondary}
                                        bBottom={colors.secondary}
                                        readOnly={preProfile.section_id?true:false}
                                    >
                                        {sections?.map((section) => (
                                            <MenuItem
                                                value={section.id}
                                                key={section.id}
                                            >
                                                {section.section.name}
                                            </MenuItem>
                                        ))}
                                    </CustomSelectInput>
                                </Grid>
                                <Grid item sm={11} xs={12}>
                                    <Grid container spacing={{ md: 4 }}>
                                        <Grid item sm={6} xs={12}>
                                            <CustomSelectInput
                                                className="form-input"
                                                label={`Year of start`}
                                                name="start_year"
                                                formik={formik}
                                                color={colors.secondary}
                                                bBottom={colors.secondary}
                                                readOnly
                                            >
                                                {getYears()?.map((year) => (
                                                    <MenuItem
                                                        value={year}
                                                        key={year}
                                                    >
                                                        {year}
                                                    </MenuItem>
                                                ))}
                                            </CustomSelectInput>
                                        </Grid>
                                        <Grid item sm={6} xs={12}>
                                            <CustomSelectInput
                                                className="form-input"
                                                label={`Year of graduate`}
                                                name="finish_year"
                                                formik={formik}
                                                color={colors.secondary}
                                                bBottom={colors.secondary}
                                                readOnly={true}
                                            >
                                                {getYears()?.map((year) => (
                                                    <MenuItem
                                                        value={year}
                                                        key={year}
                                                    >
                                                        {year}
                                                    </MenuItem>
                                                ))}
                                            </CustomSelectInput>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {/* ################ ACTION BUTONS ###################### */}
                                <Grid
                                    item
                                    sm={11}
                                    xs={12}
                                    sx={{
                                        textAlign: 'center',
                                    }}
                                >
                                    <CustomSubmitButton
                                        startIcon={<VerifiedUserOutlined />}
                                        endIcon={<ArrowRightAltOutlined />}
                                        btnColor={`secondary`}
                                        variant={'contained'}
                                        loading={false}
                                        onClick={() => {
                                            formik.handleSubmit();
                                        }}
                                        sx={{ py: 1.5, fontSize: 16 }}
                                    >
                                        Make selection
                                    </CustomSubmitButton>
                                </Grid>
                                {/* <Grid
                                    item
                                    sm={11}
                                    xs={12}
                                    sx={{
                                        marginTop: 2.5,
                                        textAlign: 'center',
                                    }}
                                >
                                    <NextAndBackBtn
                                        steps={steps}
                                        activeStep={activeStep}
                                        handleBack={handleBack}
                                        action={formik.handleSubmit}
                                    />
                                </Grid> */}

                                {/* ######### sign_in ######### */}
                                <Grid
                                    item
                                    sm={12}
                                    xs={12}
                                    sx={{
                                        marginTop: 2.5,
                                        textAlign: 'center',
                                    }}
                                >
                                    <Link to={'/'}>
                                        <Typography
                                            className="sign_in"
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            Sign in here
                                            <ArrowRightAltOutlined color="secondary" />
                                        </Typography>
                                    </Link>
                                </Grid>
                            </Grid>
                        )}
                    </Formik>
                )}
            </Box>
        </>
    );
};

export default ProgramSelection;
