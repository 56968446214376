import { Badge, Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { colors } from '../../assets/utils/constants';
import NotificationWidget2 from '../widgets/NotificationWidget2';
import { NavLink } from 'react-router-dom';
import CountBadge from '../CountBadge';
import { filter, isEmpty, size } from 'lodash';
import MarkAllRead from '../../pages/notifications/CRUD/MarkAllRead';
import DeleteAllNotifications from '../../pages/notifications/CRUD/DeleteAllNotifications';
import NoContent from '../NoContent';

const NotificationSidebar = ({ notifications }) => {
    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    position: 'relative',
                    border: `10px solid ${colors.bgColor4}`,
                    background: `linear-gradient(180deg, ${colors.bgColor4}, ${colors.bgColor4})`,
                }}
            >
                <Box
                    sx={{
                        p: 2,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.secondary})`,
                    }}
                >
                    <Badge
                        color="error"
                        badgeContent={`${size(
                            filter(notifications, ({ isSeen }) => isSeen === 0),
                        )} new`}
                        showZero
                        sx={{
                            '& .MuiBadge-badge': {
                                bgcolor: 'red',
                                fontWeight: 'bold',
                                height: 15,
                                fontSize: 10
                            },
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 16,
                                fontWeight: 'bold',
                                opacity: 0.8,
                                display: 'flex',
                                flexDirection: 'column',
                                pt: 0.5,
                                mb: 0.5,
                            }}
                        >
                            All Notifications
                        </Typography>
                    </Badge>

                    <CountBadge
                        total={size(notifications)}
                        item={'Notificat...'}
                    />
                </Box>
                <Box
                    sx={{ height: '63.5vh', overflow: 'auto', pb: 2, px: 0.5 }}
                >
                    {isEmpty(notifications) ? (
                        <>
                            <NoContent
                                height={'50vh'}
                                message={'No notification available'}
                            />
                        </>
                    ) : (
                        <Grid container>
                            {notifications?.map((notification) => (
                                <Grid
                                    item
                                    sm={12}
                                    xs={12}
                                    key={notification.id}
                                    sx={{ position: 'relative' }}
                                >
                                    <NavLink
                                        to={`/notifications/${notification.id}`}
                                    >
                                        {({ isActive }) => (
                                            <>
                                                {' '}
                                                <NotificationWidget2
                                                    notification={notification}
                                                />
                                                {isActive && (
                                                    <Box
                                                        sx={{
                                                            position:
                                                                'absolute',
                                                            width: 10,
                                                            background: `linear-gradient(90deg, ${colors.warning}, ${colors.bgColor1})`,
                                                            top: 10,
                                                            bottom: 10,
                                                            right: -3,
                                                            left: -3,
                                                            borderRadius: 50,
                                                        }}
                                                    ></Box>
                                                )}
                                            </>
                                        )}
                                    </NavLink>
                                </Grid>
                            ))}
                        </Grid>
                    )}
                </Box>
                <Box
                    sx={{
                        p: 1,
                        display: 'flex',
                        height: 45,
                        overflow: 'hidden',
                        borderTopRightRadius: 5,
                        borderTopLeftRadius: 5,
                        background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor4})`,
                    }}
                >
                    <Box sx={{ width: '90%', mr: 1 }}>
                        <MarkAllRead />
                    </Box>
                    <Box sx={{ width: '90%', ml: 1 }}>
                        <DeleteAllNotifications />
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default NotificationSidebar;
