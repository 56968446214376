import { practicalTrainingsAPI } from './practicalTrainingsAPI';
import { trainingSeasonEndpoints } from './trainingSeasonEndpoints';

export const trainingSeasonAPI = practicalTrainingsAPI.injectEndpoints({
    endpoints: (builder) => ({
        // => GET PT's
        getPracticalTrainingSeasons: builder.query({
            query: (payload) =>
                `${trainingSeasonEndpoints.GET_TRAINING_SEASONS}/${payload}`,
        }),

        // => Enroll
        trainingSeasonEnroll: builder.mutation({
            query: (payload) => ({
                url: `${trainingSeasonEndpoints.TRAINING_SEASON_ENROLL}/${payload}`,
                method: 'POST',
            }),
        }),
    }),
});

export const {
    useLazyGetPracticalTrainingSeasonsQuery,
    useTrainingSeasonEnrollMutation,
} = trainingSeasonAPI;
