import { configureStore } from "@reduxjs/toolkit";
import { RTKmiddleware } from "./RTKmiddlewares";

// => SLICE reducers
import authReducer from "../features/auth/authSlice";
import registerReducer from "../features/auth/registerSlice";
import instituteReducer from "../features/institute/instituteSlice";
import profileReducer from "../features/profile/profileSlice";
import practicalTrainingsReducer from "../features/practicalTrainings/practicalTrainingsSlice";
import helpCenterReducer from "../features/helpCenter/helpCenterSlice";
import notificationsReducer from "../features/notifications/notificationsSlice";
import adminReducer from "../features/admin/adminSlice";
import systemConfigReducer from "../features/systemConfig/systemConfigSlice";

// => RTK reducers
import { authAPI } from "../../api/auth/authAPI";
import { registerAPI } from "../../api/auth/registerAPI";
import { instituteAPI } from "../../api/institute/instituteAPI";
import { profileAPI } from "../../api/profile/profileAPI";
import { practicalTrainingsAPI } from "../../api/practicalTrainings/practicalTrainingsAPI";
import { helpCenterAPI } from "../../api/helpCenter/helpCenterAPI";
import { contactUsAPI } from "../../api/helpCenter/contactUsAPI";
import { notificationsAPI } from "../../api/notifications/notificationsAPI";
import { adminAPI } from "../../api/admin/adminAPI";

export const store = configureStore({
    reducer: {
        [authAPI.reducerPath]: authAPI.reducer,
        [registerAPI.reducerPath]: registerAPI.reducer,
        [profileAPI.reducerPath]: profileAPI.reducer,
        [instituteAPI.reducerPath]: instituteAPI.reducer,
        [practicalTrainingsAPI.reducerPath]: practicalTrainingsAPI.reducer,
        [helpCenterAPI.reducerPath]: helpCenterAPI.reducer,
        [contactUsAPI.reducerPath]: contactUsAPI.reducer,
        [notificationsAPI.reducerPath]: notificationsAPI.reducer,
        [adminAPI.reducerPath]: adminAPI.reducer,
        authReducer,
        adminReducer,
        profileReducer,
        registerReducer,
        instituteReducer,
        practicalTrainingsReducer,
        helpCenterReducer,
        notificationsReducer,
        systemConfigReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(RTKmiddleware),
});
