// LOGIN user
const LOGIN = '/auth/login';

// LOGOUT user
const LOGOUT = '/auth/logout';

export const authEndpoints = {
    LOGIN,
    LOGOUT,
};
