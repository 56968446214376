// => RTK reducers
import { authAPI } from '../../api/auth/authAPI';
import { registerAPI } from '../../api/auth/registerAPI';
import { instituteAPI } from '../../api/institute/instituteAPI';
import { practicalTrainingsAPI } from '../../api/practicalTrainings/practicalTrainingsAPI';
import { profileAPI } from '../../api/profile/profileAPI';
import { helpCenterAPI } from '../../api/helpCenter/helpCenterAPI';
import { contactUsAPI } from '../../api/helpCenter/contactUsAPI';
import { notificationsAPI } from '../../api/notifications/notificationsAPI';
import { adminAPI } from '../../api/admin/adminAPI';

// => RTK middleware
export const RTKmiddleware = [
    authAPI.middleware,
    registerAPI.middleware,
    profileAPI.middleware,
    instituteAPI.middleware,
    practicalTrainingsAPI.middleware,
    helpCenterAPI.middleware,
    contactUsAPI.middleware,
    notificationsAPI.middleware,
    adminAPI.middleware,
];
