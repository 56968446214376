import React from 'react';
import { Box, Grid } from '@mui/material';
import PageLayout from '../layouts/PageLayout';
import { colors } from '../../assets/utils/constants';
import { filter, isEmpty, size } from 'lodash';
import PracticalTrainingSidebar from '../../components/sidebars/PracticalTrainingSidebar';
import InfoboxWidget2 from '../../components/widgets/InfoboxWidget2';
import CustomPagination from '../../components/paginations/CustomPagination';
import CountBadge from '../../components/CountBadge';
import { practicalTrainingsSelector } from '../../states/features/selectors';
import { useSelector } from 'react-redux';
import NoContent from '../../components/NoContent';
import { paginator } from '../../helpers/paginationHelper';
import FieldWidgetOne from '../../components/widgets/FieldWidgetOne';

const PracticalTraining = () => {
    // ############## Comp state ##################
    const [page, setPage] = React.useState(1);
    const [perPage, setPerPage] = React.useState(10);

    // ############### Redux state #################
    const { practicalTrainings: activePTs } = useSelector(
        practicalTrainingsSelector,
    );
    const favoritePT = filter(
        activePTs,
        ({ isFavorite }) => isFavorite === true,
    );
    const appliedPT = filter(activePTs, ({ isApplied }) => isApplied === true);

    // ############## FUNC ####################
    // => handle page change
    const handlePageChange = (page) => {
        setPage(page);
    };
    // => handle per page change
    const handlePerPageChage = (perPage) => {
        setPerPage(perPage);
    };

    const infoBoxData = [
        {
            id: 1,
            title1: 'Applied',
            title2: 'Practical training',
            total: size(appliedPT),
            colorSign: colors.info,
            url: 'applied',
        },
        {
            id: 2,
            title1: 'Accepted',
            title2: 'Practical training',
            total: 0,
            colorSign: colors.warning,
            url: 'special',
        },
        {
            id: 3,
            title1: 'Favorites',
            title2: 'Practical training',
            total: size(favoritePT),
            colorSign: colors.primary,
            url: 'favorites',
        },
    ];

    // => Pagination data
    const paginatedActivePTs = paginator(activePTs, page, perPage);

    return (
        <>
            <PageLayout
                head={'Practical trainings'}
                sidebar={<PracticalTrainingSidebar />}
            >
                <Grid container spacing={2}>
                    {infoBoxData.map((data) => (
                        <Grid item sm={4} key={data.id}>
                            <InfoboxWidget2
                                data={data}
                                colorSign={data.colorSign}
                            />
                        </Grid>
                    ))}
                    <Grid item sm={12}>
                        <CustomPagination
                            head={'Active practical training vacancy'}
                            height={'51vh'}
                            borderBottom
                            handlePageChange={handlePageChange}
                            handlePerPageChage={handlePerPageChage}
                            data={paginatedActivePTs}
                            action={
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Box sx={{ ml: 1 }}>
                                        <CountBadge
                                            total={size(activePTs)}
                                            item={'Active PTs'}
                                        />
                                    </Box>
                                </Box>
                            }
                        >
                            {isEmpty(activePTs) ? (
                                <>
                                    <NoContent
                                        height={300}
                                        message={
                                            'No active practical trainings'
                                        }
                                    />
                                </>
                            ) : (
                                <Grid container spacing={3}>
                                    {paginatedActivePTs.data.map(
                                        (training, index) => (
                                            <Grid item sm={6} key={training.id}>
                                                <FieldWidgetOne
                                                    training={training}
                                                />
                                            </Grid>
                                        ),
                                    )}
                                </Grid>
                            )}
                        </CustomPagination>
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default PracticalTraining;
