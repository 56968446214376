import { Add, Leaderboard } from '@mui/icons-material';
import { Box, Button, Grid, styled, Typography } from '@mui/material';
import React from 'react';
import { colors } from '../../assets/utils/constants';
import CustomCard2 from '../cards/CustomCard2';
import StatsInfoBox from '../infoBoxes/StatsInfoBox';
import { useSelector } from 'react-redux';
import { practicalTrainingsSelector } from '../../states/features/selectors';
import { filter, size } from 'lodash';

const PracticalTrainingSidebar = () => {
    const { practicalTrainings } = useSelector(practicalTrainingsSelector);

    // => Published
    const publishedPTs = filter(
        practicalTrainings,
        ({ status }) => status === 'published',
    );
    // => Closed
    const approvedPTs = filter(
        practicalTrainings,
        ({ application }) => application?.status === 'approved',
    );
    // => Favorites
    const favoritesPTs = filter(
        practicalTrainings,
        ({ isFavorite }) => isFavorite === true,
    );
    // => Applied
    const appliedPTs = filter(
        practicalTrainings,
        ({ isApplied }) => isApplied === true,
    );
    const ptStats = [
        {
            id: 1,
            title: 'Active',
            stats: `${size(publishedPTs)} Training`,
            borderColor: colors.danger,
        },
        {
            id: 2,
            title: 'Applied',
            stats: `${size(appliedPTs)} Training`,
            borderColor: colors.warning,
        },
        {
            id: 3,
            title: 'Approved',
            stats: `${size(approvedPTs)} Training`,
            borderColor: colors.success,
        },
        {
            id: 4,
            title: 'Favorites',
            stats: `${size(favoritesPTs)} Training`,
            borderColor: colors.info,
        },
    ];
    return (
        <>
            <Grid container rowSpacing={4.5}>
                <Grid item sm={12}>
                    <Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                opacity: 0.8,
                            }}
                        >
                            <Leaderboard sx={{ mr: 1 }} color="info" />
                            <Typography
                                sx={{ fontSize: 15, fontWeight: 'bold' }}
                            >
                                Overviews
                            </Typography>
                        </Box>
                        <Grid container spacing={2} sx={{ mt: 0 }}>
                            {ptStats.map((stats) => (
                                <Grid item sm={6} key={stats.id}>
                                    <StatsInfoBox stats={stats} />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default PracticalTrainingSidebar;
