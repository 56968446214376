import React from 'react';
import { ArrowRightAltOutlined, DoneAllOutlined } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import CustomSubmitButton from '../Forms/CustomSubmitButton';
import moment from 'moment';
import useRTK from '../../hooks/useRTK';
import { useTrainingSeasonEnrollMutation } from '../../api/practicalTrainings/trainingSeasonAPI';
import { setUserProfile } from '../../states/features/profile/profileSlice';
import { useDispatch } from 'react-redux';

const EnrollForApplicationConfimation = ({ currSeason, handleDialogClose }) => {
    // ############### CONST #################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ################# RTK #################
    const [
        enrollInTrainingSeason,
        { isLoading, isSuccess, isError, error, data: seasonData },
    ] = useTrainingSeasonEnrollMutation();

    // ############# useEffect ##################
    const rtkActions = (data) => {
        handleDialogClose();
        dispatch(setUserProfile(data));
    };
    RTK.useRTKResponse(isSuccess, isError, error, seasonData, rtkActions);

    return (
        <>
            <Box>
                {currSeason?.status === 'inactive' ? (
                    <>
                        <Box sx={{ mb: 2 }} className="warning-message">
                            <Typography>
                                <strong>NOTE : </strong>
                                {currSeason.title} training season is not active
                                for now. Please wait till it get activated and
                                come back for enrollment.
                            </Typography>
                        </Box>
                    </>
                ) : currSeason?.status === 'closed' ? (
                    <>
                        <Box sx={{ mb: 2 }} className="error-message">
                            <Typography>
                                <strong>NOTE : </strong>
                                {currSeason.title} training season already
                                closed. You can not be enrolled on closed
                                practical training season.
                            </Typography>
                        </Box>
                    </>
                ) : (
                    <>
                        <Box className="success-message" sx={{ py: 4 }}>
                            <Typography
                                sx={{ fontSize: 25, fontWeight: 'bold' }}
                            >
                                {currSeason.title}
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                    mt: 2,
                                }}
                            >
                                <Box>
                                    <Typography
                                        sx={{
                                            fontSize: 12,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Open date
                                    </Typography>
                                    <Typography>
                                        {moment(currSeason.open_date).format(
                                            'LL',
                                        )}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography
                                        sx={{
                                            fontSize: 12,
                                            fontWeight: 'bold',
                                            color: 'red',
                                        }}
                                    >
                                        Close date
                                    </Typography>
                                    <Typography>
                                        {moment(currSeason.close_date).format(
                                            'LL',
                                        )}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box>
                            <Box sx={{ mb: 2 }} className="warning-message">
                                <Typography>
                                    <strong>NOTE : </strong>
                                    You'r about to enroll for {
                                        currSeason.title
                                    }{' '}
                                    training season. Make sure your ready for
                                    attanding practical training.
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                py: 0.5,
                                px: 1,
                            }}
                        >
                            <Box
                                sx={{
                                    py: 0.5,
                                    width: '100%',
                                }}
                            >
                                <CustomSubmitButton
                                    startIcon={
                                        <DoneAllOutlined color="success" />
                                    }
                                    endIcon={<ArrowRightAltOutlined />}
                                    btnColor={`secondary`}
                                    variant={'contained'}
                                    loading={isLoading}
                                    onClick={() => {
                                        enrollInTrainingSeason(currSeason.id);
                                    }}
                                    sx={{ py: 1.5, fontSize: 16 }}
                                >
                                    Submit enrollement
                                </CustomSubmitButton>
                            </Box>
                        </Box>
                    </>
                )}
            </Box>
        </>
    );
};

export default EnrollForApplicationConfimation;
