import React from 'react';
import { ArrowCircleRightOutlined } from '@mui/icons-material';
import { Box, Button, Grid } from '@mui/material';
import { colors } from '../assets/utils/constants';
import CustomCard2 from '../components/cards/CustomCard2';
import EnrollmentCard from '../components/dash/EnrollmentCard';
import OccupationCard from '../components/dash/OccupationCard';
import DashSidebar from '../components/sidebars/DashSidebar';
import SelectedFieldWidget from '../components/widgets/SelectedFieldWidget';
import DashboardLayout from './layouts/DashboardLayout';
import { Link } from 'react-router-dom';
import CustomPagination from '../components/paginations/CustomPagination';
import CountBadge from '../components/CountBadge';
import NoContent from '../components/NoContent';
import FieldWidgetOne from '../components/widgets/FieldWidgetOne';
import { isEmpty, size, take } from 'lodash';
import { useSelector } from 'react-redux';
import { practicalTrainingsSelector } from '../states/features/selectors';
import { paginator } from '../helpers/paginationHelper';

const Dashboard = () => {
    // ############## Comp state ##################
    const [page, setPage] = React.useState(1);
    const [perPage, setPerPage] = React.useState(10);

    // ############### Redux state #################
    const { practicalTrainings: activePTs } = useSelector(
        practicalTrainingsSelector,
    );

    // ############## FUNC ####################
    // => handle page change
    const handlePageChange = (page) => {
        setPage(page);
    };
    // => handle per page change
    const handlePerPageChage = (perPage) => {
        setPerPage(perPage);
    };

    // => Pagination data
    const paginatedActivePTs = paginator(take(activePTs, 10), page, perPage);

    return (
        <>
            <DashboardLayout sidebar={<DashSidebar />}>
                <Grid container spacing={2}>
                    {/* ################### ENROLLMENT #################### */}
                    <Grid item xs={12} sm={4}>
                        <EnrollmentCard />
                    </Grid>

                    {/* ################### SELECTED PT AND OCCUPATION #################### */}
                    <Grid item xs={12} sm={8}>
                        <Box
                            sx={{
                                borderRadius: 4,
                                overflow: 'hidden',
                                background: `linear-gradient(180deg, ${colors.bgColor4}, ${colors.bgColor4})`,
                            }}
                        >
                            <Grid container>
                                <Grid item xs={12} sm={7}>
                                    <CustomCard2
                                        head={'Approved practical training'}
                                        height={230}
                                    >
                                        <SelectedFieldWidget />
                                    </CustomCard2>
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <OccupationCard />
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>

                    {/* ################### LATEST PT #################### */}
                    <Grid item xs={12} sm={12}>
                        <CustomPagination
                            head={'Latest training'}
                            height={{ md: '35.5vh', xs: '100%' }}
                            borderBottom
                            handlePageChange={handlePageChange}
                            handlePerPageChage={handlePerPageChage}
                            data={paginatedActivePTs}
                            action={
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Box sx={{ mr: 2 }}>
                                        <CountBadge
                                            total={size(activePTs)}
                                            item={'Active PTs'}
                                        />
                                    </Box>
                                    <Link to={'/practical-trainings'}>
                                        <Button
                                            variant="contained"
                                            endIcon={
                                                <ArrowCircleRightOutlined />
                                            }
                                            size="small"
                                            color="info"
                                            className="app-btn"
                                        >
                                            View all
                                        </Button>
                                    </Link>
                                </Box>
                            }
                        >
                            {isEmpty(activePTs) ? (
                                <>
                                    <NoContent
                                        height={200}
                                        message={
                                            'No active practical trainings'
                                        }
                                    />
                                </>
                            ) : (
                                <Grid container spacing={3}>
                                    {paginatedActivePTs.data.map((training) => (
                                        <Grid
                                            item
                                            sm={6}
                                            xs={12}
                                            key={training.id}
                                        >
                                            <FieldWidgetOne
                                                training={training}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                        </CustomPagination>
                    </Grid>
                </Grid>
            </DashboardLayout>
        </>
    );
};

export default Dashboard;
