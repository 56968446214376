import { createSlice } from '@reduxjs/toolkit';
import { updateArrObj } from '../../../helpers/arrayHelpers';

const initialState = {
    practicalTrainings: [],
    practicalTrainingSeasons: [],
    practicalTrainingDetails: {},
};

// !########## SLICE #############
const practicalTrainingsSlice = createSlice({
    name: 'practicalTrainingsSlice',
    initialState,
    reducers: {
        setPracticalTrainings: (state, action) => {
            state.practicalTrainings = action.payload;
        },
        setPracticalTrainingSeasons: (state, action) => {
            state.practicalTrainingSeasons = action.payload;
        },
        setPracticalTrainingDetails: (state, action) => {
            state.practicalTrainingDetails = action.payload;
        },
        updateTrainingDetails: (state, actions) => {
            let newTainingDetails = actions.payload;
            let trainings = updateArrObj(
                'id',
                newTainingDetails,
                state.practicalTrainings,
            );
            state.practicalTrainings = trainings;
        },
    },
});

export const {
    setPracticalTrainings,
    setPracticalTrainingSeasons,
    setPracticalTrainingDetails,
    updateTrainingDetails,
} = practicalTrainingsSlice.actions;
export default practicalTrainingsSlice.reducer;
