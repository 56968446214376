import React from "react";
import { Grid, MenuItem, Typography } from "@mui/material";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import { colors } from "../../../assets/utils/constants";
import CustomPasswordField from "../../../components/Forms/CustomPasswordField";
import CustomSubmitButton from "../../../components/Forms/CustomSubmitButton";
import {
    ArrowRightAltOutlined,
    PersonAddAlt1Outlined,
} from "@mui/icons-material";
import CustomTextField from "../../../components/Forms/CustomTextField";
import CustomSelectInput from "../../../components/Forms/CustomSelectInput";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
    adminSelector,
    registerSelector,
} from "../../../states/features/selectors";
import { useRegisterMutation } from "../../../api/auth/registerAPI";
import useAuth from "../../../hooks/useAuth";
import useRTK from "../../../hooks/useRTK";
import SmallCustomSelectInput from "../../../components/Forms/SmallCustomSelectInput";
import { setRegions } from "../../../states/features/admin/adminSlice";
import { useGetActiveRegionsQuery } from "../../../api/admin/adminAPI";
import { isEmpty } from "lodash";

// ############# Validation Schema ##############
const validationSchema = Yup.object({
    region_id: Yup.string().required("Region field is required"),
    gender: Yup.string().required("Gender field is required"),
    password: Yup.string().required().min(8),
    confirm_password: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("confirm password is required field")
        .min(8, "Password must be at least 8 characters"),
});

const PasswordConfimation = ({ steps, activeStep, handleNext, handleBack }) => {
    // ############## CONST #################
    const auth = useAuth();
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############## Redux state ################
    const {
        preProfile,
        selectedSection: section_id,
        startYear: start_year,
        finishYear: finish_year,
        phoneNumber: phone_number,
    } = useSelector(registerSelector);
    const { regions } = useSelector(adminSelector);
    console.log(phone_number);

    // ############# RTK ##################

    // ########## Get active regions ############
    const { isSuccess: regionSuccess, data } = useGetActiveRegionsQuery();
    const rtkPTActions = (data) => {
        dispatch(setRegions(data));
    };
    RTK.useRTKFetch(regionSuccess, data, rtkPTActions);

    const [
        register,
        { isLoading, isSuccess, isError, error, data: studentData },
    ] = useRegisterMutation();

    // ############# useEffect ##################
    const rtkActions = (data) => {
        auth.login(data);
    };
    RTK.useRTKResponse(isSuccess, isError, error, studentData, rtkActions);

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    reg_id: preProfile.reg_id,
                    section_id,
                    start_year,
                    finish_year,
                    phone_number,
                    region_id: "",
                    gender: "",
                    password: "",
                    confirm_password: "",
                }}
                validationSchema={validationSchema}
                onSubmit={(payload) => {
                    register(payload);
                }}
            >
                {(formik) => (
                    <Grid
                        container
                        justifyContent={"center"}
                        padding={2}
                        columnSpacing={4}
                    >
                        <Grid
                            item
                            sm={12}
                            sx={{ pb: { md: 2 }, pt: { md: 0 } }}
                        >
                            <Typography className="head">
                                Addition infomations
                            </Typography>
                            <Typography className="sub-head">
                                Please provide the password that you will be
                                used to login to your account
                            </Typography>
                        </Grid>
                        <Grid item sm={5.5} xs={12}>
                            {!isEmpty(regions) && (
                                <CustomSelectInput
                                    label={"Region"}
                                    name={"region_id"}
                                    formik={formik}
                                    color={colors.secondary}
                                    bBottom={colors.secondary}
                                >
                                    {regions?.map((region) => (
                                        <MenuItem
                                            key={region.id}
                                            value={region.id}
                                        >
                                            {region.name}
                                        </MenuItem>
                                    ))}
                                </CustomSelectInput>
                            )}
                            {/* <CustomTextField
                                className="form-input"
                                label={`Region`}
                                type="text"
                                name="region_id"
                                bBottom={colors.secondary}
                            /> */}
                        </Grid>
                        <Grid item sm={5.5} xs={12}>
                            <CustomSelectInput
                                className="form-input"
                                label={`Gender`}
                                name="gender"
                                formik={formik}
                                color={colors.secondary}
                                bBottom={colors.secondary}
                            >
                                <MenuItem value={"male"}>Male</MenuItem>
                                <MenuItem value={"female"}>Female</MenuItem>
                            </CustomSelectInput>
                        </Grid>
                        <Grid item sm={5.5} xs={12}>
                            <CustomPasswordField
                                id="password"
                                className="form-input"
                                label={"Password"}
                                type="password"
                                name="password"
                                bBottom={colors.secondary}
                            />
                            <Typography
                                sx={{
                                    marign: 0,
                                    padding: 0,
                                    fontSize: 12,
                                    color: colors.warning,
                                }}
                            >
                                Note :- password should contain atleast 8
                                characters
                            </Typography>
                        </Grid>
                        <Grid item sm={5.5} xs={12}>
                            <CustomPasswordField
                                id="confirm_password"
                                className="form-input"
                                label={"Confirm password"}
                                type="password"
                                name="confirm_password"
                                bBottom={colors.secondary}
                            />
                        </Grid>

                        {/* ######### sign up btn ######### */}
                        <Grid
                            item
                            sm={11}
                            xs={12}
                            sx={{
                                marginTop: 2.5,
                                textAlign: "center",
                            }}
                        >
                            <CustomSubmitButton
                                startIcon={<PersonAddAlt1Outlined />}
                                endIcon={<ArrowRightAltOutlined />}
                                btnColor={`secondary`}
                                variant={"contained"}
                                loading={isLoading}
                                onClick={() => {
                                    formik.handleSubmit();
                                }}
                                sx={{ py: 1.5, fontSize: 16 }}
                            >
                                Complete registration
                            </CustomSubmitButton>
                        </Grid>

                        {/* ################ ACTION BUTONS ###################### */}
                        {/* <Grid
                            item
                            sm={11}
                            xs={12}
                            sx={{
                                marginTop: 2.5,
                                textAlign: 'center',
                            }}
                        >
                            <NextAndBackBtn
                                steps={steps}
                                activeStep={activeStep}
                                handleBack={handleBack}
                                action={formik.handleSubmit}
                            />
                        </Grid> */}

                        {/* ######### sign_in ######### */}
                        <Grid
                            item
                            sm={12}
                            sx={{
                                marginTop: 2.5,
                                textAlign: "center",
                            }}
                        >
                            <Link to={"/"}>
                                <Typography
                                    className="sign_in"
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    Sign in here
                                    <ArrowRightAltOutlined color="secondary" />
                                </Typography>
                            </Link>
                        </Grid>
                    </Grid>
                )}
            </Formik>
        </>
    );
};

export default PasswordConfimation;
