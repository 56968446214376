import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { adminBaseURL } from "../baseUrl/baseURL";
import { adminEndpoints } from "./adminEndpoints";

export const adminAPI = createApi({
    reducerPath: "adminAPI",
    baseQuery: fetchBaseQuery({
        baseUrl: adminBaseURL,
    }),
    endpoints: (builder) => ({
        // => Active sections
        getActiveRegions: builder.query({
            query: (payload) => `${adminEndpoints.ACTIVE_REGIONS}`,
        }),
    }),
});

export const { useGetActiveRegionsQuery ,useLazyGetActiveRegionsQuery} = adminAPI;
