import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TopBar from '../../components/TopBar';
import SideBar from '../../components/SideBar';
import { colors } from '../../assets/utils/constants';
import useRTK from '../../hooks/useRTK';
import FullAppLoader from '../../components/FullAppLoader';
import HotToastfy from '../../components/HotToastfy';

const DrawerHeader = styled(Box)(({ theme }) => ({
    ...theme.mixins.toolbar,
}));

const AppLayout = ({ children }) => {
    // ################# Comp state ###############
    const RTK = useRTK();

    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                position: 'absolute',
                top: 0,
                bottom: '100%',
                right: 0,
                left: 0,
            }}
        >
            {RTK.useRTKMainData() ? (
                <FullAppLoader />
            ) : (
                <>
                    {/* ############## Tostfy ############## */}
                    <HotToastfy />
                    {/* ############### topbar ############### */}
                    <TopBar
                        handleDrawerToggle={handleDrawerToggle}
                        mobileOpen={mobileOpen}
                    />

                    {/* ############### sidebar ###############*/}
                    <SideBar mobileOpen={mobileOpen} />

                    {/* ############### content ###############*/}
                    <Box
                        component="main"
                        sx={{ bgcolor: colors.bgColor, flexGrow: 1 }}
                    >
                        <DrawerHeader />
                        {children}
                    </Box>
                </>
            )}
        </Box>
    );
};

export default AppLayout;
