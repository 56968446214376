import { Favorite, FavoriteBorderOutlined } from '@mui/icons-material';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import React from 'react';
import { colors } from '../../../assets/utils/constants';
import { useDispatch } from 'react-redux';
import {
    useAddToFavoritesMutation,
    useRemoveFromFavoritesMutation,
} from '../../../api/practicalTrainings/practicalTrainingsAPI';
import useRTK from '../../../hooks/useRTK';
import { updateTrainingDetails } from '../../../states/features/practicalTrainings/practicalTrainingsSlice';

const TrainingFavorite = ({ training }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# RTK ##################
    const [
        addAction,
        {
            isLoading: addLoading,
            isSuccess: addSuccess,
            isError: addIsError,
            error: addError,
            data: addData,
        },
    ] = useAddToFavoritesMutation();
    const [
        removeAction,
        {
            isLoading: removeLoading,
            isSuccess: removeSuccess,
            isError: removeIsError,
            error: removeError,
            data: removeData,
        },
    ] = useRemoveFromFavoritesMutation();

    // ############# useEffect ##################
    // => add res
    const rtkActions = (data) => {
        dispatch(updateTrainingDetails(data));
    };
    RTK.useRTKResponse(addSuccess, addIsError, addError, addData, rtkActions);
    // => remove res
    RTK.useRTKResponse(
        removeSuccess,
        removeIsError,
        removeError,
        removeData,
        rtkActions,
    );

    return (
        <>
            <Tooltip
                arrow
                title={
                    training.isFavorite
                        ? 'Remove from favorites'
                        : 'Add to favorites'
                }
            >
                <IconButton
                    size="small"
                    sx={{
                        px: 2,
                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor5})`,
                    }}
                    onClick={() => {
                        training.isFavorite
                            ? removeAction(training.id)
                            : addAction(training.id);
                    }}
                >
                    {addLoading || removeLoading ? (
                        <CircularProgress size={15} />
                    ) : training.isFavorite ? (
                        <Favorite
                            color="error"
                            sx={{ fontSize: 16, opacity: 0.8 }}
                        />
                    ) : (
                        <FavoriteBorderOutlined
                            color="error"
                            sx={{ fontSize: 17, opacity: 0.8 }}
                        />
                    )}
                </IconButton>
            </Tooltip>
        </>
    );
};

export default TrainingFavorite;
