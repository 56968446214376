import { Avatar, Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { colors } from '../assets/utils/constants';
import { useDate } from '../hooks/useDate';

const CurrentDateTime = ({user}) => {
    const date = useDate();
    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12} sm={12}>
                    <Box
                        className="neo-bulge"
                        sx={{
                            background: colors.primary,
                            padding: 3,
                            display: 'flex',
                            justifyContent: 'center',
                            height: 120,
                            alignItems: 'center',
                        }}
                    >
                        <Grid container spacing={1.5}>
                            <Grid item xs={8} sm={8}>
                                <Box>
                                    <Typography
                                        sx={{
                                            fontSize: 16,
                                            color: colors.secondary,
                                        }}
                                        noWrap
                                    >
                                        {date.wish_1} {user.first_name}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 22,
                                            color: colors.secondary,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {date.time}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 16,
                                            color: colors.secondary,
                                        }}
                                    >
                                        {date.date}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                <Link to={'/profile'}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                        }}
                                    >
                                        <Avatar
                                            src={user.profile_picture}
                                            sx={{
                                                height: 80,
                                                width: 80,
                                                background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor4})`,
                                            }}
                                        />
                                    </Box>
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default CurrentDateTime;
