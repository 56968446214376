import React from "react";
import { Box, Grid } from "@mui/material";
import { styled } from "@mui/system";
import { colors } from "../../assets/utils/constants";
import StudentRegStepper from "../../components/steppers/StudentRegStepper";
import { setRegions } from "../../states/features/admin/adminSlice";
import { useDispatch } from "react-redux";
import { useGetActiveRegionsQuery } from "../../api/admin/adminAPI";
import useRTK from "../../hooks/useRTK";

//* ################# FORM BOX ###################
const FormBox = styled(Box)(({ theme }) => ({
    backgroundColor: colors.primary,
    zIndex: 999,
    borderTopLeftRadius: 50,
    borderBottomRightRadius: 50,
    overflow: "hidden",
    "& .head": {
        fontSize: 35,
        textAlign: "center",
        marginBottom: 0,
        color: colors.secondary,
    },
    "& .sub-head": {
        fontSize: 17,
        textAlign: "center",
        marginTop: -5,
        color: colors.gray,
    },
    "& .form-input": {
        "& label": {
            color: `${colors.secondary}!important`,
        },
        "& input": {
            color: `${colors.gray}!important`,
            paddingLeft: 16,
        },
        "& .icon": {
            color: `${colors.secondary}!important`,
        },
    },
    "& .sigin-btn": {
        backgroundColor: colors.secondary,
        color: colors.primary,
        paddingLeft: 120,
        paddingRight: 120,
        paddingTop: 10,
        paddingBottom: 10,
        transition: "all ease-in-out .2s",
        "&:hover": {
            backgroundColor: colors.secondary,
            opacity: 0.8,
            transition: "all ease-in-out .2s",
        },
    },
    "& .sign_in": {
        color: colors.gray,
        transition: "all ease-in-out .2s",
        "&:hover": {
            color: colors.secondary,
            transition: "all ease-in-out .2s",
        },
    },
    [theme.breakpoints.down("md")]: {
        "& .head": {
            fontSize: 22,
            textAlign: "left",
            paddingBottom: 20,
        },
        "& .sub-head": {
            fontSize: 16,
            textAlign: "left",
        },
    },
}));

//! ############### MAIN FUNCTION ###################
const Register = () => {
    // ############ CONST #############
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ########## Get active regions ############
    const { isSuccess, data } = useGetActiveRegionsQuery();
    const rtkPTActions = (data) => {
        dispatch(setRegions(data));
    };
    RTK.useRTKFetch(isSuccess, data, rtkPTActions);

    return (
        <React.Fragment>
            <Grid container justifyContent={"center"}>
                <Grid item sm={10} md={10} lg={6} xs={12}>
                    <FormBox>
                        <StudentRegStepper />
                    </FormBox>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default Register;
