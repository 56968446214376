import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Box, Grid, LinearProgress, Typography } from '@mui/material';
import CustomSubmitButton from '../../../components/Forms/CustomSubmitButton';
import { ManageAccounts } from '@mui/icons-material';
import { colors } from '../../../assets/utils/constants';
import OTPInput, { ResendOTP } from 'otp-input-react';
import { useDispatch, useSelector } from 'react-redux';
import useRTK from '../../../hooks/useRTK';
import { getRTKErrorMessage } from '../../../helpers/RTKHelpers';
import { registerSelector } from '../../../states/features/selectors';
import {
    useRequestOTPMutation,
    useVerifyOTPMutation,
} from '../../../api/auth/registerAPI';
import {
    phoneVerified,
    setPinId,
} from '../../../states/features/auth/registerSlice';

// * -> verify schema
const otpValidationSchema = Yup.object({
    otp: Yup.number().required('Fill all field').min(6),
});

const PhoneVerification = ({ phoneNumber, handleNext, closeDialogy }) => {
    // ############## Comp State ################
    const [OTP, setOTP] = useState('');

    // ################# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############## Redux state ################
    const { pinId } = useSelector(registerSelector);

    // ############### RTK #####################
    // => Request
    const [
        resendOTP,
        {
            isLoading: resendLoading,
            isSuccess: resendSuccess,
            isError: resendIsError,
            error: resendError,
            data: resendData,
        },
    ] = useRequestOTPMutation();
    // => Verify
    const [
        verifyPhoneNumber,
        { isLoading, isSuccess, isError, error, data: updatedData },
    ] = useVerifyOTPMutation();

    // ############### useEffect ################
    // => RTK resend action
    const rtkResendActions = (data) => {
        setPinId(data);
    };
    RTK.useRTKResponse(
        resendSuccess,
        resendIsError,
        resendError,
        resendData,
        rtkResendActions,
    );

    // => RTK verified action
    const rtkVerifiedActions = (data) => {
        closeDialogy();
        handleNext();
        dispatch(phoneVerified(true));
    };
    RTK.useRTKResponse(
        isSuccess,
        isError,
        error,
        updatedData,
        rtkVerifiedActions,
    );

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    otp: OTP,
                }}
                validationSchema={otpValidationSchema}
                onSubmit={(payload) => {
                    payload = {
                        pin_id: pinId,
                        ...payload,
                    };
                    verifyPhoneNumber(payload);
                }}
            >
                {(formik) => (
                    <Form>
                        {resendLoading && (
                            <LinearProgress
                                sx={{
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                    left: 0,
                                }}
                            />
                        )}
                        <Grid container justifyContent={'center'}>
                            <Grid item sm={10}>
                                <Typography
                                    sx={{
                                        fontSize: 22,
                                        textAlign: 'center',
                                        color: colors.primary,
                                        fontWeight: 'bold',
                                        margin: 2,
                                    }}
                                >
                                    Verify your phone number
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 16,
                                        textAlign: 'center',
                                        color: colors.primary,
                                    }}
                                >
                                    An verification code has been sent to the
                                    phone provided :{' '}
                                    <strong>{phoneNumber}</strong>
                                </Typography>
                            </Grid>
                            <Grid item sm={9}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginTop: 2,
                                        marginBottom: 3,
                                        flexDirection: 'column',
                                    }}
                                >
                                    <OTPInput
                                        value={OTP}
                                        onChange={setOTP}
                                        autoFocus
                                        OTPLength={6}
                                        otpType="number"
                                        disabled={false}
                                    />
                                    {isError ? (
                                        <>
                                            <Typography
                                                sx={{
                                                    fontSize: 14,
                                                    color: 'red',
                                                    textAlign: 'center',
                                                    marginTop: 4,
                                                }}
                                            >
                                                {getRTKErrorMessage(error)}
                                            </Typography>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </Box>
                                <ResendOTP
                                    onResendClick={() => {
                                        let phone =
                                            0 + phoneNumber.substring(3);
                                        let payload = {
                                            phone_number: phone,
                                        };
                                        resendOTP(payload);
                                    }}
                                />
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <CustomSubmitButton
                                        startIcon={<ManageAccounts />}
                                        onClick={formik.handleSubmit}
                                        loading={isLoading}
                                    >
                                        Verify phone number
                                    </CustomSubmitButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default PhoneVerification;
