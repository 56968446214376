import React from 'react';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import Section from './Section';
import f1 from '../assets/media/images/f1.png';
import f2 from '../assets/media/images/f2.png';
import f3 from '../assets/media/images/f3.png';
import f4 from '../assets/media/images/f4.png';
import f5 from '../assets/media/images/f5.png';
import StoreButtons from './StoreButtons';
import FeatureWidget from './widgets/FeatureWidget';
import { colors } from '../assets/utils/constants';
import { ListAltOutlined } from '@mui/icons-material';
import { useState } from 'react';
import { useEffect } from 'react';

const MobApp = () => {
    const deviceWidth = useMediaQuery('(min-width: 768px)');
    // ########### Var ######################
    const features = [
        {
            id: 1,
            img: f2,
            feature: 'User Auth',
        },
        {
            id: 2,
            img: f1,
            feature: 'Instant messaging',
        },
        {
            id: 3,
            img: f3,
            feature: 'Push notification',
        },
        {
            id: 4,
            img: f4,
            feature: 'Search function',
        },
        {
            id: 5,
            img: f5,
            feature: 'Job search and filter',
        },
        {
            id: 6,
            img: f1,
            feature: 'Resume generator',
        },
    ];

    // ############ Comp state ##############
    const [fetureImg, setFetureImg] = useState('');

    // ############ FUNC ###################
    const handleImgPreview = (img) => {
        setFetureImg(img);
    };

    // ############# useEffect ##############
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            setFetureImg(f1);
        }

        return () => {
            isSubscribed = false;
        };
    }, []);

    return (
        <>
            <Section>
                <Grid container spacing={5} sx={{ mt: { md: '', xs: 5 }, pb: 5 }}>
                    <Grid item sm={8}>
                        <Box>
                            <Box>
                                <Typography className="section-head">
                                    Wazawa Max App
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 16,
                                        fontWeight: 'bold',
                                        mt: 0.5,
                                    }}
                                >
                                    Talent is the multiplier. The more energy
                                    and attention you invest in it, the greater
                                    the yield.
                                </Typography>
                            </Box>
                            <Box>
                                <Box>
                                    <Typography
                                        sx={{
                                            border: `2px solid ${colors.bgColor5}`,
                                            width: 200,
                                            textAlign: 'center',
                                            p: 1,
                                            my: 2,
                                            background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.secondary}) !important`,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Application Features
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 14,
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <ListAltOutlined />
                                        What are the features of Wazawa Max app?
                                        Let's get to key mobile app features
                                        that set our app apart from others.
                                    </Typography>
                                </Box>
                                <Box sx={{ mt: 2 }}>
                                    <Grid container spacing={{ md: 3, xs: 0 }}>
                                        {features.map((feature) => (
                                            <Grid
                                                item
                                                sm={4}
                                                xs={6}
                                                key={feature.id}
                                            >
                                                <FeatureWidget
                                                    handleImgPreview={
                                                        handleImgPreview
                                                    }
                                                    feature={feature}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Box>
                                <Box>
                                    <StoreButtons />
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <Box
                            sx={{
                                px: 2,
                                textAlign: 'center',
                                alignItems: 'center',
                                borderRadius: 10,
                                background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.secondary})`,
                            }}
                        >
                            <img
                                src={fetureImg}
                                alt=""
                                style={{
                                    height: deviceWidth ? '100%' : '100%',
                                    width: '100%',
                                    borderRadius: 25,
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Section>
        </>
    );
};

export default MobApp;
