import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { studentBaseURL } from '../baseUrl/baseURL';
import { practicalTrainingEndpoints } from './practicalTrainingEndpoints';
import { headers } from '../baseUrl/setHeaders';

export const practicalTrainingsAPI = createApi({
    reducerPath: 'practicalTrainingsAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: studentBaseURL,
        prepareHeaders: headers,
    }),
    tagTypes: ['Trainings', 'TrainingDetails'],
    endpoints: (builder) => ({
        // => GET PT's
        getPracticalTrainings: builder.query({
            query: () => `${practicalTrainingEndpoints.GET_PTs}`,
            providesTags: ['Trainings'],
        }),

        // => GET Single PT
        getSinglePracticalTraining: builder.query({
            query: (payload) =>
                `${practicalTrainingEndpoints.GET_SINGLE_PT}/${payload}`,
        }),

        // => Add to favorites
        addToFavorites: builder.mutation({
            query: (payload) => ({
                url: `${practicalTrainingEndpoints.ADD_TO_FAVORITES}/${payload}`,
                method: 'POST',
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(
                    practicalTrainingsAPI.util.invalidateTags(['Trainings']),
                );
            },
        }),

        // => Remove from favorites
        removeFromFavorites: builder.mutation({
            query: (payload) => ({
                url: `${practicalTrainingEndpoints.REMOVE_FROM_FAVORITES}/${payload}`,
                method: 'DELETE',
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(
                    practicalTrainingsAPI.util.invalidateTags(['Trainings']),
                );
            },
        }),

        // => Apply training
        applyPT: builder.mutation({
            query: (payload) => ({
                url: `${practicalTrainingEndpoints.APPLY_PT}/${payload}`,
                method: 'POST',
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(
                    practicalTrainingsAPI.util.invalidateTags(['Trainings', 'TrainingDetails']),
                );
            },
        }),
    }),
});

export const {
    useGetPracticalTrainingsQuery,
    useGetSinglePracticalTrainingQuery,
    useAddToFavoritesMutation,
    useRemoveFromFavoritesMutation,
    useApplyPTMutation,

    // => Lazy
    useLazyGetPracticalTrainingsQuery,
} = practicalTrainingsAPI;
