import {
    AllInclusive,
    AppRegistrationOutlined,
    LocationOn,
    MoreVert,
    RemoveRedEye,
} from '@mui/icons-material';
import {
    Avatar,
    Badge,
    Box,
    Button,
    Chip,
    Grid,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Tooltip,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { colors } from '../../assets/utils/constants';
import strHelper from '../../helpers/strHelper';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';
import { profileSelector } from '../../states/features/selectors';
import { filter, size } from 'lodash';
import FeedbackDialog from '../dialogs/FeedbackDialog';
import CustomDialog from '../dialogs/CustomDialog';
import SuccessFeedback from '../feedbacks/SuccessFeedback';
import TrainingFavorite from '../../pages/practicalTrainings/CRUD/TrainingFavorite';
import ApplyTraining from '../../pages/practicalTrainings/CRUD/ApplyTraining';

const FieldWidgetOne = ({ training }) => {
    // ############# Comp State ################
    const [openDialog, setOpenDialog] = useState(false);
    const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false);

    // ############## Redux state ###################
    const { profile: user } = useSelector(profileSelector);

    // ############## FUNC #####################
    const getApprovalCount = () => {
        let approvals = filter(
            training?.selectedInstitutions,
            ({ status }) => status === 'approved',
        );
        return size(approvals);
    };
    // => Apply dialog
    const handleConfimationDialogOpen = () => {
        setOpenDialog(true);
    };
    const handleConfimationDialogClose = () => {
        setOpenDialog(false);
    };
    // => Feedback dialog
    const handleFeedbackDialogOpen = () => {
        setOpenDialog(false);
        setOpenFeedbackDialog(true);
    };
    const handleFeedbackDialogClose = () => {
        setOpenFeedbackDialog(false);
    };

    return (
        <>
            {/* ############# DIALOG ############### */}
            <FeedbackDialog
                isConfirm
                openDialog={openFeedbackDialog}
                handleClose={handleFeedbackDialogClose}
            >
                <SuccessFeedback
                    message={
                        'Practical training application sent successfully.'
                    }
                />
            </FeedbackDialog>
            <CustomDialog
                isConfirm
                noDropClose
                openDialog={openDialog}
                handleDialogClose={handleConfimationDialogClose}
                head="Confirm practical training application"
            >
                <ApplyTraining
                    training={training}
                    handleConfirm={handleFeedbackDialogOpen}
                    handleDialogClose={handleConfimationDialogClose}
                />
            </CustomDialog>

            <Box
                sx={{
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                    borderTop: `5px solid ${colors.bgColor5}`,
                    borderRadius: 2,
                    overflow: 'hidden',
                    boxShadow: `1px 1px 10px 0px ${colors.bgColor5}`,
                }}
            >
                <Box
                    sx={{
                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                        p: 1,
                        textAlign: 'center',
                        position: 'relative',
                        display: 'flex',
                        justifyContent: { md: 'space-between', xs: 'center' },
                    }}
                >
                    <Box
                        sx={{
                            display: { md: 'flex', xs: 'none' },
                            flexDirection: 'column',
                        }}
                    >
                        <Box>
                            <Chip
                                label={
                                    training?.application
                                        ? training?.application?.status
                                        : training?.status === 'closed'
                                        ? training?.status
                                        : 'Not-applied'
                                }
                                size="small"
                                color={
                                    training?.application?.status === 'approved'
                                        ? 'success'
                                        : training?.application?.status ===
                                          'declined'
                                        ? 'error'
                                        : training?.application?.status ===
                                          'pending'
                                        ? 'info'
                                        : training?.status === 'closed'
                                        ? 'error'
                                        : 'warning'
                                }
                                sx={{
                                    fontSize: 10,
                                    fontWeight: 'bold',
                                    textTransform: 'capitalize',
                                    height: 20,
                                    pt: 0.2,
                                    px: training?.isApplied && 1.2,
                                }}
                            />
                        </Box>
                        <Box sx={{ mt: 0.5 }}>
                            <TrainingFavorite training={training} />
                        </Box>
                    </Box>
                    <Box>
                        <Typography
                            sx={{
                                fontSize: 16,
                                fontWeight: 'bold',
                                color: colors.warning,
                            }}
                        >
                            {strHelper().strCapitalizeFirstChar(training.title)}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 14,
                                color: colors.info,
                            }}
                        >
                            {strHelper().strCapitalizeFirstChar(
                                training.department.name,
                            )}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 12,
                                opacity: 0.8,
                                color: colors.warning,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                color: colors.primary,
                                fontWeight: 'bold',
                            }}
                        >
                            <LocationOn
                                sx={{
                                    fontSize: 12,
                                    mr: 0.5,
                                }}
                            />
                            {training.region}
                        </Typography>
                        <Box sx={{ display: { md: 'none', xs: 'block' } }}>
                            <Chip
                                label={
                                    training?.application
                                        ? training?.application?.status
                                        : 'Not-applied'
                                }
                                size="small"
                                color={
                                    training?.application?.status === 'approved'
                                        ? 'success'
                                        : training?.application?.status ===
                                          'declined'
                                        ? 'error'
                                        : training?.application?.status ===
                                          'pending'
                                        ? 'info'
                                        : 'warning'
                                }
                                sx={{
                                    fontSize: 10,
                                    fontWeight: 'bold',
                                    textTransform: 'capitalize',
                                    height: 20,
                                    pt: 0.2,
                                    px: training?.isApplied && 1.2,
                                }}
                            />
                        </Box>
                    </Box>
                    <Box sx={{ display: { md: 'block', xs: 'none' } }}>
                        <Tooltip title="Training details" arrow>
                            <Link to={`/practical-trainings/${training.id}`}>
                                <IconButton
                                    size="small"
                                    color="info"
                                    sx={{
                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor5})`,
                                    }}
                                >
                                    <MoreVert />
                                </IconButton>
                            </Link>
                        </Tooltip>
                    </Box>
                </Box>
                <Box
                    sx={{
                        p: 1.5,
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box>
                        <ListItem alignItems="flex-start" sx={{ p: 0 }}>
                            <ListItemAvatar>
                                <Avatar
                                    alt="Business Op"
                                    src={training.organization.org_logo}
                                    variant="square"
                                    sx={{
                                        borderRadius: 2,
                                        border: `1px dotted ${colors.warning}`,
                                        p: 0.5,
                                        img: {
                                            objectFit: 'contain',
                                        },
                                    }}
                                />
                            </ListItemAvatar>
                            <ListItemText
                                disableTypography
                                primary={
                                    <Typography
                                        sx={{
                                            fontSize: 14,
                                            opacity: 0.8,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {
                                            training.organization
                                                .organization_name
                                        }
                                    </Typography>
                                }
                                secondary={
                                    <React.Fragment>
                                        <Typography
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                        >
                                            <LocationOn
                                                sx={{
                                                    fontSize: 14,
                                                    color: colors.secondary,
                                                }}
                                            />
                                            {training.organization.location}
                                        </Typography>
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                        <Typography
                            sx={{
                                fontSize: 14,
                                width: 300,
                                mt: 0.5,
                                display: { md: 'block', xs: 'none' },
                            }}
                            noWrap
                        >
                            {training.description}
                        </Typography>
                    </Box>
                    <Box>
                        <Box
                            sx={{
                                p: 0.5,
                                px: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: 2,
                                background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.secondary})`,
                            }}
                        >
                            <Badge
                                showZero
                                color="info"
                                badgeContent={training.totalApplied}
                            >
                                <Typography sx={{ fontSize: 12, pr: 2 }}>
                                    Applicants
                                </Typography>
                            </Badge>
                        </Box>
                        <Box
                            sx={{
                                p: 0.5,
                                px: 1,
                                mt: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: 2,
                                background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor3})`,
                            }}
                        >
                            <Typography
                                component={'div'}
                                sx={{
                                    fontSize: 12,
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                Positions
                                {training.positions ? (
                                    <Chip
                                        label={training.positions}
                                        size="small"
                                        sx={{ ml: 1, fontSize: 12 }}
                                        color="secondary"
                                    />
                                ) : (
                                    <AllInclusive
                                        color="warning"
                                        sx={{ fontSize: 14, ml: 1 }}
                                    />
                                )}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                p: 0.5,
                                px: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: 2,
                                // background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor4})`,
                                mt: 1,
                                opacity: 0.8,
                            }}
                        >
                            <Typography
                                sx={{ fontSize: 12, fontWeight: 'bold' }}
                            >
                                {moment(training.created_at)
                                    .startOf('hour')
                                    .fromNow()}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box
                    sx={{
                        // display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Grid container>
                        <Grid item sm={4} xs={6}>
                            <Box
                                sx={{
                                    textAlign: 'center',
                                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                    p: 1,
                                    width: '100%',
                                    borderBottom: `5px solid ${colors.success}`,
                                }}
                            >
                                <Typography
                                    noWrap
                                    sx={{
                                        fontSize: 12,
                                        fontWeight: 'bold',
                                        color: 'green',
                                    }}
                                >
                                    Application start
                                </Typography>
                                <Typography noWrap sx={{ fontSize: 12 }}>
                                    {moment(training.open_date).format('LL')}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item sm={4} xs={6}>
                            <Box
                                sx={{
                                    textAlign: 'center',
                                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                    p: 1,
                                    width: '100%',
                                    borderLeft: `1px solid ${colors.bgColor5}`,
                                    borderRight: `1px solid ${colors.bgColor5}`,
                                    borderBottom: `5px solid ${colors.danger}`,
                                }}
                            >
                                <Typography
                                    noWrap
                                    sx={{
                                        fontSize: 12,
                                        fontWeight: 'bold',
                                        color: 'red',
                                    }}
                                >
                                    Application deadline
                                </Typography>
                                <Typography noWrap sx={{ fontSize: 12 }}>
                                    {moment(training.end_date).format('LL')}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            sm={4}
                            xs={6}
                            sx={{
                                bgcolor: colors.secondary,
                                display: { md: 'none', xs: 'block' },
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-around',
                                    height: '100%',
                                }}
                            >
                                <Box sx={{}}>
                                    <TrainingFavorite training={training} />
                                </Box>
                                <Box sx={{}}>
                                    <Tooltip title="Training details" arrow>
                                        <Link
                                            to={`/practical-trainings/${training.id}`}
                                        >
                                            <IconButton
                                                size="small"
                                                color="info"
                                                sx={{
                                                    px: 2,
                                                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor5})`,
                                                }}
                                            >
                                                <RemoveRedEye
                                                    sx={{
                                                        fontSize: 16,
                                                        opacity: 0.8,
                                                    }}
                                                />
                                            </IconButton>
                                        </Link>
                                    </Tooltip>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item sm={4} xs={6}>
                            <Box
                                sx={{
                                    textAlign: 'center',
                                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                    width: '100%',
                                    height: '100%',
                                    borderBottom: `5px solid ${colors.primary}`,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Button
                                    className="app-btn"
                                    variant="contained"
                                    sx={{
                                        height: '100%',
                                        borderRadius: `0 !important`,
                                    }}
                                    fullWidth
                                    disabled={training.isApplied ? true : false}
                                    startIcon={<AppRegistrationOutlined />}
                                    onClick={handleConfimationDialogOpen}
                                >
                                    Apply now
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    );
};

export default FieldWidgetOne;
