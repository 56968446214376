// => Get profile
const GET_PROFILE = "/auth/profile";

// => UPDATE profile
const UPDATE_PROFILE = "/auth/profile";

// => UPDATE profile picture
const UPDATE_PROFILE_PICTURE = "/auth/profile-picture";

// => CHANGE password
const CHANGE_PASSWORD = "/auth/password/change";

// => Become beneficiary
const BECOME_BENEFICIARY = "/beneficiaries";

export const profileEndpoints = {
    GET_PROFILE,
    UPDATE_PROFILE,
    UPDATE_PROFILE_PICTURE,
    CHANGE_PASSWORD,
    BECOME_BENEFICIARY,
};
