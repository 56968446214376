import React, { useState } from 'react';
import { Box, Button, Chip, Grid, Typography } from '@mui/material';
import { colors } from '../../../assets/utils/constants';
import strHelper from '../../../helpers/strHelper';
import moment from 'moment';
import {
    AllInclusive,
    DocumentScannerOutlined,
    PinDrop,
} from '@mui/icons-material';
import { isEmpty, size } from 'lodash';
// import DocumentPreview from '../../../components/DocumentPreview';
import NoContent from '../../../components/NoContent';
// import StudentApprovalStatus from './StudentApprovalStatus';
import { profileSelector } from '../../../states/features/selectors';
import { useSelector } from 'react-redux';

const PTGeneralDetails = ({ training }) => {
    // ################## COMP State ###################
    const [openDocsDialog, setOpenDocsDialogy] = useState(false);
    const selectedInstitutions = training.selectedInstitutions;

    // ############## Redux state ###################
    const { profile: user } = useSelector(profileSelector);

    // ################## FUNC ###################
    // -> Dialogy closer
    const handleDialogClose = () => {
        setOpenDocsDialogy(false);
    };

    // => Handle docs preview
    const handleDocsPreview = () => {
        setOpenDocsDialogy(true);
    };

    return (
        <>
            {/* ##################### DIALOG ################# */}
            {/* <DocumentPreviewDialog
                head={'Practical training attachment'}
                openDialog={openDocsDialog}
                handleDialogClose={handleDialogClose}
            >
                <DocumentPreview document={training.attachment} />
            </DocumentPreviewDialog> */}

            <Grid container spacing={2}>
                {/* ################ TITLE ################# */}
                <Grid item sm={12}>
                    <Box
                        sx={{
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                            p: 1,
                            textAlign: 'center',
                            position: 'relative',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 20,
                                color: colors.info,
                                textTransform: 'uppercase',
                            }}
                        >
                            {strHelper().strCapitalizeFirstChar(training.title)}
                        </Typography>
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    opacity: 0.8,
                                    color: colors.info,
                                }}
                            >
                                {training.sector.name}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    opacity: 0.8,
                                    color: colors.warning,
                                }}
                            >
                                {training.department.name}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 16,
                                    opacity: 0.8,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    color: colors.primary,
                                    fontWeight: 'bold',
                                }}
                            >
                                ({' '}
                                <PinDrop
                                    sx={{
                                        fontSize: 18,
                                        mr: 1,
                                    }}
                                />
                                {training.region} )
                            </Typography>
                        </Box>
                        {/* {user.organization_type.name === 'Institution' && (
                            <StudentApprovalStatus training={training} />
                        )} */}
                    </Box>
                </Grid>
                {/* ################ APPLICATION DATE ################# */}
                <Grid item sm={6} xs={12}>
                    <Box
                        sx={{
                            p: 1.5,
                            borderRadius: 2,
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                            textAlign: 'center',
                            border: `1px dotted ${colors.bgColor5}`,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 14,
                                fontWeight: 'bold',
                                pb: 1,
                                color: colors.info,
                                borderBottom: `1px solid ${colors.bgColor4}`,
                            }}
                        >
                            Application date
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                            }}
                        >
                            <Box
                                sx={{
                                    width: '100%',
                                    mr: 1,
                                }}
                            >
                                <strong style={{ color: colors.success }}>
                                    <small>Start date</small>
                                </strong>
                                <Typography sx={{ opacity: 0.8 }}>
                                    {moment(training.open_date).format('LL')}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    width: '100%',
                                    mr: 1,
                                }}
                            >
                                <strong style={{ color: 'red' }}>
                                    <small>Deadline date</small>
                                </strong>
                                <Typography sx={{ opacity: 0.8 }}>
                                    {moment(training.end_date).format('LL')}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                {/* ################ APPLICANTS ################# */}
                <Grid item sm={6} xs={12}>
                    <Box
                        sx={{
                            p: 1.5,
                            borderRadius: 2,
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                            textAlign: 'center',
                            border: `1px dotted ${colors.bgColor5}`,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 14,
                                fontWeight: 'bold',
                                pb: 1,
                                color: colors.info,
                                borderBottom: `1px solid ${colors.bgColor4}`,
                            }}
                        >
                            Application applicants
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                            }}
                        >
                            <Box
                                sx={{
                                    width: '100%',
                                    mr: 1,
                                    opacity: 0.8,
                                }}
                            >
                                <strong>
                                    <small>Max applicants</small>
                                </strong>
                                <Typography>
                                    {training.max_applicants ? (
                                        training.max_applicants
                                    ) : (
                                        <AllInclusive color="warning" />
                                    )}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    width: '100%',
                                    mr: 1,
                                    opacity: 0.8,
                                }}
                            >
                                <strong>
                                    <small>Positions</small>
                                </strong>
                                <Typography>
                                    {training.positions ? (
                                        training.positions
                                    ) : (
                                        <AllInclusive color="warning" />
                                    )}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                {/* ################ DESCRIPTION ################# */}
                <Grid item sm={12} xs={12}>
                    <Box
                        sx={{
                            p: 1.5,
                            borderRadius: 2,
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                            border: `1px dotted ${colors.bgColor5}`,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 15,
                                fontWeight: 'bold',
                                mb: 1,
                                color: colors.info,
                            }}
                            >
                            Description
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                            }}
                            >
                            <Box
                                sx={{
                                    width: '100%',
                                    mr: 1,
                                }}
                                >
                                <Typography
                                    sx={{
                                        fontSize: 16,
                                        opacity: 0.8,
                                    }}
                                >
                                    {training.description}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                {/* ################ SECTORS ################# */}
                <Grid item sm={6} xs={12}>
                    <Box
                        sx={{
                            p: 1.5,
                            borderRadius: 2,
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                            border: `1px dotted ${colors.bgColor5}`,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 15,
                                fontWeight: 'bold',
                                mb: 1,
                                color: colors.info,
                            }}
                        >
                            Selected sector
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                            }}
                        >
                            <Box
                                sx={{
                                    width: '100%',
                                    mr: 1,
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: 16,
                                        opacity: 0.8,
                                    }}
                                >
                                    {training.sector.name}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                {/* ################ DEPARTMENTS ################# */}
                <Grid item sm={6} xs={12}>
                    <Box
                        sx={{
                            p: 1.5,
                            borderRadius: 2,
                            height: '100%',
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                            border: `1px dotted ${colors.bgColor5}`,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 15,
                                fontWeight: 'bold',
                                mb: 1,
                                color: colors.info,
                            }}
                        >
                            Selected department
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                            }}
                        >
                            <Box
                                sx={{
                                    width: '100%',
                                    mr: 1,
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: 16,
                                        opacity: 0.8,
                                    }}
                                >
                                    {training.department.name}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                {/* ################ INSTITUTIONS ################# */}
                <Grid item sm={6} xs={12}>
                    <Box
                        sx={{
                            p: 1.5,
                            borderRadius: 2,
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                            border: `1px dotted ${colors.bgColor5}`,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 14,
                                fontWeight: 'bold',
                                mb: 1,
                                color: colors.info,
                            }}
                        >
                            Selected institutions{' '}
                            {selectedInstitutions && (
                                <strong style={{ color: colors.warning }}>
                                    ({size(selectedInstitutions)})
                                </strong>
                            )}
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                            }}
                        >
                            <Box
                                sx={{
                                    'width': '100%',
                                    'mr': 1,
                                    '& .chip': {
                                        border: `1px solid ${colors.bgColor5}`,
                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2}) !important`,
                                        mr: 1,
                                    },
                                    'overflowX': 'auto',
                                    'display': 'flex',
                                    'pb': 2,
                                }}
                            >
                                {isEmpty(selectedInstitutions) ? (
                                    <Chip
                                        label="All institutions"
                                        size="small"
                                        className="chip"
                                    />
                                ) : (
                                    selectedInstitutions?.map((institution) => (
                                        <Chip
                                            key={institution.id}
                                            label={
                                                institution.organization_name
                                            }
                                            size="small"
                                            className="chip"
                                        />
                                    ))
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                {/* ################ SECTIONS ################# */}
                <Grid item sm={6} xs={12}>
                    <Box
                        sx={{
                            p: 1.5,
                            borderRadius: 2,
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                            border: `1px dotted ${colors.bgColor5}`,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 14,
                                fontWeight: 'bold',
                                mb: 1,
                                color: colors.info,
                            }}
                        >
                            Selected sections
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                            }}
                        >
                            <Box
                                sx={{
                                    'width': '100%',
                                    'mr': 1,
                                    '& .chip': {
                                        border: `1px solid ${colors.bgColor5}`,
                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2}) !important`,
                                        mr: 1,
                                    },
                                    'overflowX': 'auto',
                                    'display': 'flex',
                                    'pb': 2,
                                }}
                            >
                                {isEmpty(training?.sections) ? (
                                    <Chip
                                        label="All sections"
                                        size="small"
                                        className="chip"
                                    />
                                ) : (
                                    JSON.parse(
                                        training?.sections,
                                    )?.map((section) => (
                                        <Chip
                                            key={section.id}
                                            label={section.title}
                                            size="small"
                                            className="chip"
                                        />
                                    ))
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                {/* ################ ATTACHMENT ################# */}
                <Grid item sm={12} xs={12}>
                    <Box
                        sx={{
                            p: 1.5,
                            borderRadius: 2,
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 14,
                                fontWeight: 'bold',
                                mb: 1,
                                color: colors.info,
                            }}
                        >
                            Attachment
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                            }}
                        >
                            <Box
                                sx={{
                                    'width': '100%',
                                    'mr': 1,
                                    '& .chip': {
                                        border: `1px solid ${colors.bgColor5}`,
                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2}) !important`,
                                        mr: 1,
                                    },
                                    'overflowX': 'auto',
                                    'display': 'flex',
                                    'pb': 2,
                                    'justifyContent': 'center',
                                }}
                            >
                                {training.attachment ? (
                                    <>
                                        <Button
                                            startIcon={
                                                <DocumentScannerOutlined />
                                            }
                                            variant="contained"
                                            onClick={() => {
                                                handleDocsPreview();
                                            }}
                                        >
                                            Preview attachment
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        <Box>
                                            <NoContent
                                                message={
                                                    'Attachment not uploaded'
                                                }
                                            />
                                        </Box>
                                    </>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default PTGeneralDetails;
