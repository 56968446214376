import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { studentBaseURL } from '../baseUrl/baseURL';
import { registerEndpoints } from './registerEndpoints';

export const registerAPI = createApi({
    reducerPath: 'registerAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: studentBaseURL,
    }),
    tagTypes: ['Login'],
    endpoints: (builder) => ({
        // => Register
        register: builder.mutation({
            query: (payload) => ({
                url: `${registerEndpoints.REGISTER}`,
                method: 'POST',
                body: payload,
            }),
        }),
        // => Register
        regIDVerify: builder.mutation({
            query: (payload) => ({
                url: `${registerEndpoints.REG_ID_VERIFY}`,
                method: 'POST',
                body: payload,
            }),
        }),
        // => Request OTP
        requestOTP: builder.mutation({
            query: (payload) => ({
                url: `${registerEndpoints.REQUEST_OTP}`,
                method: `POST`,
                body: payload,
            }),
        }),
        // => Verify OTP
        verifyOTP: builder.mutation({
            query: (payload) => ({
                url: `${registerEndpoints.VERIFY_OTP}`,
                method: `POST`,
                body: payload,
            }),
        }),
        // => Forgot Password
        forgotPassword: builder.mutation({
            query: (payload) => ({
                url: `${registerEndpoints.FORGOT_PASSWORD}`,
                method: `POST`,
                body: payload,
            }),
        }),
        // => Reset password
        resetPassword: builder.mutation({
            query: (payload) => ({
                url: `${registerEndpoints.FORGOT_PASSWORD}`,
                method: `PUT`,
                body: payload,
            }),
        }),
    }),
});

export const {
    useRegisterMutation,
    useRegIDVerifyMutation,
    useRequestOTPMutation,
    useVerifyOTPMutation,
    useForgotPasswordMutation,
    useResetPasswordMutation,
} = registerAPI;
