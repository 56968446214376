import React from 'react';
import { Grid, Typography } from '@mui/material';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import CustomTextField from '../../../components/Forms/CustomTextField';
import { colors } from '../../../assets/utils/constants';
import { ArrowRightAltOutlined, VerifiedUserOutlined } from '@mui/icons-material';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { registerSelector } from '../../../states/features/selectors';
import { useRegIDVerifyMutation } from '../../../api/auth/registerAPI';
import useRTK from '../../../hooks/useRTK';
import { setPreProfile } from '../../../states/features/auth/registerSlice';
import CustomSubmitButton from '../../../components/Forms/CustomSubmitButton';

// ############# Validation Schema ##############
const validationSchema = Yup.object({
    reg_id: Yup.string().required('Please provide your registration number'),
});

const VerifyRegID = ({ steps, activeStep, handleNext, handleBack }) => {
    // ############ CONST ####################
    const dispatch = useDispatch();
    const RTK = useRTK();

    // ############ Redux State ##############
    const { preProfile } = useSelector(registerSelector);

    // ############# RTK ##################
    const [
        regIdVerify,
        { isLoading, isSuccess, isError, error, data: studentData },
    ] = useRegIDVerifyMutation();

    // ############# useEffect ##################
    const rtkActions = (data) => {
        dispatch(setPreProfile(data));
        handleNext();
    };
    RTK.useRTKResponse(isSuccess, isError, error, studentData, rtkActions);

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    reg_id: preProfile?.reg_id,
                }}
                validationSchema={validationSchema}
                onSubmit={(payload) => {
                    regIdVerify(payload);
                }}
            >
                {(formik) => (
                    <Grid
                        container
                        justifyContent={'center'}
                        padding={2}
                        py={{ md: 2 }}
                    >
                        <Grid
                            item
                            sm={12}
                            xs={12}
                            sx={{ pb: { md: 4 }, pt: { md: 2 } }}
                        >
                            <Typography className="head">
                                Registration number verification
                            </Typography>
                            <Typography className="sub-head">
                                Please provide your institution registration
                                number in order to proceed
                            </Typography>
                        </Grid>
                        <Grid item sm={11} xs={12} sx={{ mb: 4 }}>
                            <CustomTextField
                                className="form-input"
                                label={`Registration number`}
                                type="text"
                                name="reg_id"
                                bBottom={colors.secondary}
                            />
                        </Grid>

                        {/* ################ ACTION BUTONS ###################### */}
                        <Grid
                            item
                            sm={11}
                            xs={12}
                            sx={{
                                textAlign: 'center',
                            }}
                        >
                            <CustomSubmitButton
                                startIcon={<VerifiedUserOutlined />}
                                endIcon={<ArrowRightAltOutlined />}
                                btnColor={`secondary`}
                                variant={'contained'}
                                loading={isLoading}
                                onClick={() => {
                                    formik.handleSubmit();
                                }}
                                sx={{ py: 1.5, fontSize: 16 }}
                            >
                                Confirm registration number
                            </CustomSubmitButton>
                        </Grid>
                        {/* <Grid
                            item
                            sm={11}
                            xs={12}
                            sx={{
                                marginTop: 2.5,
                                textAlign: 'center',
                            }}
                        >
                            <NextAndBackBtn
                                steps={steps}
                                activeStep={activeStep}
                                handleBack={handleBack}
                                action={formik.handleSubmit}
                            />
                        </Grid> */}

                        {/* ######### sign_in ######### */}
                        <Grid
                            item
                            sm={12}
                            sx={{
                                marginTop: 2.5,
                                textAlign: 'center',
                            }}
                        >
                            <Link to={'/'}>
                                <Typography
                                    className="sign_in"
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    Sign in here
                                    <ArrowRightAltOutlined color="secondary" />
                                </Typography>
                            </Link>
                        </Grid>
                    </Grid>
                )}
            </Formik>
        </>
    );
};

export default VerifyRegID;
