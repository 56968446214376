import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { colors } from '../../assets/utils/constants';
import CustomTextField from '../../components/Forms/CustomTextField';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';

// ======= MUI styled comp ===============
import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import CustomSubmitButton from '../../components/Forms/CustomSubmitButton';
import {
    ArrowRightAltOutlined,
    VerifiedUserOutlined,
} from '@mui/icons-material';
import useRTK from '../../hooks/useRTK';
import { useDispatch, useSelector } from 'react-redux';
import { useForgotPasswordMutation } from '../../api/auth/registerAPI';
import CustomDialog from '../../components/dialogs/CustomDialog';
import PhoneVerification from './CRUD/PhoneVerification';
import { registerSelector } from '../../states/features/selectors';
import { setPinId } from '../../states/features/auth/registerSlice';

// ======== form box ==============
const FormBox = styled(Box)(({ theme }) => ({
    'backgroundColor': colors.primary,
    'zIndex': 999,
    'borderTopLeftRadius': 50,
    'borderBottomRightRadius': 50,
    'padding': '50px 25px',
    '& .head': {
        fontSize: 50,
        textAlign: 'center',
        marginBottom: 0,
        color: colors.secondary,
    },
    '& .sub-head': {
        fontSize: 20,
        textAlign: 'center',
        marginTop: -10,
        color: colors.gray,
    },
    '& .form-input': {
        '& label': {
            color: `${colors.secondary}!important`,
        },
        '& input': {
            color: `${colors.gray}!important`,
            paddingLeft: 16,
        },
    },
    '& .sigin-btn': {
        'backgroundColor': colors.secondary,
        'color': colors.primary,
        'paddingLeft': 120,
        'paddingRight': 120,
        'paddingTop': 10,
        'paddingBottom': 10,
        'transition': 'all ease-in-out .2s',
        '&:hover': {
            backgroundColor: colors.secondary,
            opacity: 0.8,
            transition: 'all ease-in-out .2s',
        },
    },
    '& .forget': {
        'color': colors.gray,
        'transition': 'all ease-in-out .2s',
        '&:hover': {
            color: colors.secondary,
            transition: 'all ease-in-out .2s',
        },
    },
    [theme.breakpoints.down('md')]: {
        'padding': '50px 5px',
        '& .head': {
            fontSize: 35,
        },
        '& .sub-head': {
            fontSize: 15,
        },
        '& .sigin-btn': {
            paddingLeft: 30,
            paddingRight: 30,
        },
    },
}));

// ############## validation ###############
const validation = Yup.object({
    phone_number: Yup.string().required(
        'Please provide a registered phone number',
    ),
});

const ForgetPassword = () => {
    // ################# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // ########### Comp State ##################
    const [openDialog, setOpenDialogy] = useState(false);

    // ############## Redux status ############
    const { phoneNumber } = useSelector(registerSelector);

    // ############### RTK #####################
    // => Request OTP
    const [
        forgotPassword,
        { isLoading, isSuccess, isError, error, data: otpData },
    ] = useForgotPasswordMutation();

    // ############### useEffect ################
    // => RTK action
    const rtkActions = (data) => {
        dispatch(setPinId(data));
        handleDialogOpen();
    };
    RTK.useRTKResponse(isSuccess, isError, error, otpData, rtkActions);

    // ################ FUNC ###################
    // -> Dialogy closer
    const handleDialogOpen = () => {
        setOpenDialogy(true);
    };

    // -> Dialogy closer
    const handleDialogClose = () => {
        setOpenDialogy(false);
    };

    return (
        <React.Fragment>
            {/* ##################### DIALOG ################# */}
            <CustomDialog
                head={'Verifiy phone number'}
                openDialog={openDialog}
                handleDialogClose={handleDialogClose}
                noDropClose={handleDialogOpen}
            >
                <PhoneVerification
                    handleNext={() => {
                        setTimeout(() => {
                            navigate('/reset-password');
                        }, 5);
                    }}
                    phoneNumber={phoneNumber}
                    closeDialogy={handleDialogClose}
                />
            </CustomDialog>

            <Grid item sm={10}>
                <FormBox>
                    <Typography className="head">Password reset</Typography>
                    <Typography className="sub-head">
                        Provide your registered phone number
                    </Typography>
                    {/* ######### Form ######## */}
                    <Formik
                        initialValues={{
                            phone_number: '',
                        }}
                        validationSchema={validation}
                        onSubmit={(payload) => {
                            forgotPassword(payload);
                        }}
                    >
                        {(formik) => (
                            <Form>
                                <Grid
                                    container
                                    justifyContent={'center'}
                                    padding={2}
                                >
                                    {/* ######### phone ######### */}
                                    <Grid item sm={11} xs={12} sx={{ my: 5 }}>
                                        <CustomTextField
                                            className="form-input"
                                            label={`Phone number`}
                                            type="text"
                                            name="phone_number"
                                            bBottom={colors.secondary}
                                        />
                                    </Grid>

                                    {/* ######### sign btn ######### */}
                                    <Grid
                                        item
                                        sm={11}
                                        xs={12}
                                        sx={{
                                            textAlign: 'center',
                                        }}
                                    >
                                        <CustomSubmitButton
                                            startIcon={<VerifiedUserOutlined />}
                                            endIcon={<ArrowRightAltOutlined />}
                                            btnColor={`secondary`}
                                            variant={'contained'}
                                            loading={isLoading}
                                            onClick={() => {
                                                formik.handleSubmit();
                                            }}
                                            sx={{ py: 1.5, fontSize: 16 }}
                                        >
                                            Request verification OTP
                                        </CustomSubmitButton>
                                    </Grid>

                                    {/* ######### forget ######### */}
                                    <Grid
                                        item
                                        sm={12}
                                        sx={{
                                            marginTop: 2.5,
                                            textAlign: 'center',
                                        }}
                                    >
                                        <Link to={'/'}>
                                            <Typography
                                                className="sign_in"
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    color: colors.secondary,
                                                }}
                                            >
                                                Sign in here
                                                <ArrowRightAltOutlined color="secondary" />
                                            </Typography>
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </FormBox>
            </Grid>
        </React.Fragment>
    );
};

export default ForgetPassword;
