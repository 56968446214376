import React from "react";
import { Form, Formik } from "formik";
import { colors } from "../../assets/utils/constants";
import CustomTextField from "../../components/Forms/CustomTextField";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import logo from "../../assets/media/images/logo.png";

// ############ MUI styled comp ################
import {
    Box,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Grid,
    Grow,
    Typography,
} from "@mui/material";
import { styled } from "@mui/system";

// ############ Custom MUI styled comp ###########
import CustomPasswordField from "../../components/Forms/CustomPasswordField";
import CustomSubmitButton from "../../components/Forms/CustomSubmitButton";
import { ArrowRightAltOutlined, LoginOutlined } from "@mui/icons-material";
import useRTK from "../../hooks/useRTK";
import { useLoginMutation } from "../../api/auth/authAPI";
import useAuth from "../../hooks/useAuth";

// ############ form box ############
const FormBox = styled(Box)(({ theme }) => ({
    backgroundColor: colors.primary,
    backdropFilter: "blur(15px)",
    zIndex: 999,
    borderTopLeftRadius: 50,
    borderBottomRightRadius: 50,
    padding: "50px 25px",
    opacity: 0.8,
    "& .head": {
        fontSize: 45,
        textAlign: "center",
        marginBottom: 0,
        color: colors.secondary,
    },
    "& .sub-head": {
        fontSize: 18,
        textAlign: "center",
        marginTop: -10,
        color: colors.gray,
    },
    "& .form-input": {
        "& label": {
            color: `${colors.secondary}!important`,
        },
        "& input": {
            color: `${colors.gray}!important`,
            paddingLeft: 16,
        },
        "& .icon": {
            color: `${colors.secondary}!important`,
        },
    },
    "& .sigin-btn": {
        backgroundColor: colors.secondary,
        color: colors.primary,
        paddingLeft: 120,
        paddingRight: 120,
        paddingTop: 10,
        paddingBottom: 10,
        transition: "all ease-in-out .2s",
        "&:hover": {
            backgroundColor: colors.secondary,
            opacity: 0.8,
            transition: "all ease-in-out .2s",
        },
    },
    "& .forget": {
        color: colors.gray,
        transition: "all ease-in-out .2s",
        "&:hover": {
            color: colors.secondary,
            transition: "all ease-in-out .2s",
        },
    },
    [theme.breakpoints.down("md")]: {
        padding: "50px 5px",
        "& .head": {
            fontSize: 35,
        },
        "& .sub-head": {
            fontSize: 15,
        },
        "& .sigin-btn": {
            paddingLeft: 100,
            paddingRight: 100,
        },
    },
}));

// ############ validation ############
const validation = Yup.object({
    reg_id: Yup.string().required("Registration id is required"),
    password: Yup.string().required(),
});

// ! ############ MAIN FUNC ##############
const Login = () => {
    // ############## CONST ##########
    const RTK = useRTK();
    const auth = useAuth();

    // ############# RTK ##############
    const [login, { isLoading, isSuccess, isError, error, data: studentData }] =
        useLoginMutation();

    // ############# useEffect ##################
    const rtkActions = (data) => {
        auth.login(data);
    };
    RTK.useRTKResponse(isSuccess, isError, error, studentData, rtkActions);

    return (
        <React.Fragment>
            <Grid item sm={10} md={10} lg={6} xs={12}>
                <FormBox>
                    <Grid item sm={12} xs={12} sx={{ display: { md: "none" } }}>
                        <Grid container justifyContent={"center"}>
                            <Grid item sm={2} xs={3}>
                                <img
                                    src={logo}
                                    alt=""
                                    style={{
                                        width: "100%",
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Typography className="head">Welcome Back</Typography>
                    <Typography className="sub-head">
                        Sign in to your account
                    </Typography>
                    {/* ######### Form ######## */}
                    <Formik
                        initialValues={{
                            reg_id: "",
                            password: "",
                        }}
                        validationSchema={validation}
                        onSubmit={(payload) => {
                            login(payload);
                        }}
                    >
                        {(formik) => (
                            <Form style={{ marginTop: 25 }}>
                                <Grid container justifyContent={"center"}>
                                    {/* ######### username ######### */}
                                    <Grid item sm={10} xs={11}>
                                        <CustomTextField
                                            className="form-input"
                                            label="RegistrationID"
                                            type="text"
                                            name="reg_id"
                                            bBottom={colors.secondary}
                                        />
                                    </Grid>

                                    {/* ######### password ######### */}
                                    <Grid item sm={10} xs={11}>
                                        <CustomPasswordField
                                            className="form-input"
                                            label="Password"
                                            type="password"
                                            name="password"
                                            bBottom={colors.secondary}
                                        />
                                    </Grid>

                                    {/* ######### remember ######### */}
                                    <Grid item sm={10} xs={11}>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            <FormGroup>
                                                <FormControlLabel
                                                    name="remember"
                                                    control={
                                                        <Checkbox
                                                            color="secondary"
                                                            size="small"
                                                        />
                                                    }
                                                    label="Remember me ?"
                                                    sx={{
                                                        "& span": {
                                                            color: colors.gray,
                                                        },
                                                    }}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Link to={"/forget-password"}>
                                                    <Typography className="forget">
                                                        Forget password ?
                                                    </Typography>
                                                </Link>
                                            </FormGroup>
                                        </Box>
                                    </Grid>

                                    {/* ########## error ########### */}
                                    <Grid
                                        item
                                        sm={10}
                                        md={10}
                                        xs={11}
                                        sx={{
                                            textAlign: "center",
                                            color: "red",
                                        }}
                                    >
                                        {false && (
                                            <Grow in={true}>
                                                <Typography>
                                                    Provided credentials do not
                                                    match
                                                </Typography>
                                            </Grow>
                                        )}
                                    </Grid>

                                    {/* ######### sign btn ######### */}
                                    <Grid
                                        item
                                        sm={10}
                                        md={10}
                                        xs={11}
                                        sx={{
                                            marginTop: 2.5,
                                            textAlign: "center",
                                        }}
                                    >
                                        <CustomSubmitButton
                                            startIcon={<LoginOutlined />}
                                            endIcon={<ArrowRightAltOutlined />}
                                            btnColor={`secondary`}
                                            variant={"contained"}
                                            loading={isLoading}
                                            onClick={() => {
                                                formik.handleSubmit();
                                            }}
                                            sx={{ py: 1.5, fontSize: 16 }}
                                        >
                                            Login
                                        </CustomSubmitButton>
                                    </Grid>

                                    {/* ######### register ######### */}
                                    <Grid
                                        item
                                        sm={10}
                                        md={10}
                                        xs={11}
                                        sx={{
                                            marginTop: 2.5,
                                            textAlign: "center",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontWeight: "bold",
                                                color: colors.secondary,
                                            }}
                                        >
                                            OR
                                        </Typography>
                                        <Link to={"/register"}>
                                            <Typography
                                                className="forget"
                                                marginTop={2}
                                            >
                                                Don't have an acount? register
                                                now.
                                            </Typography>
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </FormBox>
            </Grid>
        </React.Fragment>
    );
};

export default Login;
