import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    regions: {},
};

const adminSlice = createSlice({
    name: "adminSlice",
    initialState,
    reducers: {
        setRegions: (state, action) => {
            state.regions = action.payload;
        },
    },
});

export const { setRegions } = adminSlice.actions;
export default adminSlice.reducer;
