import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { colors } from '../../assets/utils/constants';
import VerifyRegID from '../../pages/auth/CRUD/VerifyRegID';
import ProgramSelection from '../../pages/auth/CRUD/ProgramSelection';
import PasswordConfimation from '../../pages/auth/CRUD/PasswordConfimation';
import { Grid } from '@mui/material';
import logo from '../../assets/media/images/logo.png';
import PhoneVerify from '../../pages/auth/CRUD/PhoneVerify';
import { useSelector } from 'react-redux';
import { registerSelector } from '../../states/features/selectors';

const steps = [
    'Registration ID',
    'Phone Verification',
    'Program | Occupation | Section',
    'Addition Infos',
];

const StudentRegStepper = () => {
    // ########### Comp State ############
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());

    // ############ Redux State ##############
    const { preProfile } = useSelector(registerSelector);

    // ############ FUNC ##############
    const handleNext = () => {
        let newSkipped = skipped;
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleReset = () => {
        setActiveStep(0);
    };

    return (
        <Box sx={{ width: '100%', py: 2 }}>
            <Grid container padding={2} justifyContent={'center'}>
                <Grid
                    item
                    sm={12}
                    xs={12}
                    sx={{ mb: 5, display: { md: 'none' } }}
                >
                    <Grid container justifyContent={'center'}>
                        <Grid item sm={2} xs={3}>
                            <img
                                src={logo}
                                alt=""
                                style={{
                                    width: '100%',
                                }}
                            />
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <Typography
                                style={{
                                    fontSize: 25,
                                    textAlign: 'center',
                                    color: colors.secondary,
                                }}
                            >
                                Wazawa Max
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                {/* <Grid
                    item
                    sm={12}
                    xs={12}
                    sx={{
                        display: { md: 'flex', xs: 'none' },
                        justifyContent: 'center',
                    }}
                >
                    <Stepper
                        activeStep={activeStep}
                        sx={{
                            py: 1.5,
                            opacity: 0.8,
                            borderRadius: 2,
                            background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.secondary})`,
                        }}
                    >
                        {steps.map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>
                                        {label}
                                    </StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                </Grid> */}
                <Grid item sm={11.5} xs={12}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: 22,
                                    mt: 1,
                                    ml: 1,
                                    color: colors.secondary,
                                    fontWeight: 'bold',
                                }}
                            >
                                Step {activeStep + 1} of 4
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 16,
                                    ml: 1,
                                    color: colors.warning,
                                }}
                            >
                                {activeStep === 0
                                    ? 'Registration ID'
                                    : activeStep === 1
                                    ? 'Phone Verification'
                                    : activeStep === 2
                                    ? 'Program | Occupation | Section'
                                    : activeStep === 3
                                    ? 'Addition Infos'
                                    : ''}
                            </Typography>
                        </Box>
                        {preProfile && (
                            <Box>
                                <Typography
                                    sx={{
                                        fontSize: 20,
                                        mt: 1,
                                        mr: 1,
                                        color: colors.secondary,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {`${preProfile.first_name} ${preProfile.last_name}`}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 16,
                                        mr: 1,
                                        color: colors.secondary,
                                    }}
                                >
                                    {preProfile.institution.organization_name}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 16,
                                        mr: 1,
                                        color: colors.warning,
                                    }}
                                >
                                    {preProfile.reg_id}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </Grid>
            </Grid>

            {activeStep === steps.length ? (
                <Box>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                        All steps completed - you&apos;re finished
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button onClick={handleReset}>Reset</Button>
                    </Box>
                </Box>
            ) : (
                <Box>
                    {activeStep === 0 && (
                        <Box
                            sx={{
                                borderRadius: 2,
                                position: 'relative',
                            }}
                        >
                            <VerifyRegID
                                steps={steps}
                                activeStep={activeStep}
                                handleNext={handleNext}
                                handleBack={handleBack}
                            />
                        </Box>
                    )}
                    {activeStep === 1 && (
                        <Box
                            sx={{
                                mt: 2,
                                borderRadius: 2,
                                position: 'relative',
                                overflow: 'auto',
                            }}
                        >
                            <PhoneVerify
                                steps={steps}
                                activeStep={activeStep}
                                handleNext={handleNext}
                                handleBack={handleBack}
                            />
                        </Box>
                    )}
                    {activeStep === 2 && (
                        <Box
                            sx={{
                                mt: 2,
                                borderRadius: 2,
                                position: 'relative',
                                overflow: 'auto',
                            }}
                        >
                            <ProgramSelection
                                steps={steps}
                                activeStep={activeStep}
                                handleNext={handleNext}
                                handleBack={handleBack}
                            />
                        </Box>
                    )}
                    {activeStep === 3 && (
                        <Box
                            sx={{
                                mt: 2,
                                borderRadius: 2,
                                position: 'relative',
                                overflow: 'auto',
                            }}
                        >
                            <PasswordConfimation
                                steps={steps}
                                activeStep={activeStep}
                                handleNext={handleNext}
                                handleBack={handleBack}
                            />
                        </Box>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default StudentRegStepper;
