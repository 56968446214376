import React from 'react';
import { AllInclusive, LocationOn, MoreVert } from '@mui/icons-material';
import {
    Avatar,
    Badge,
    Box,
    Chip,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { colors } from '../../assets/utils/constants';
import NoContent from '../NoContent';
import { useSelector } from 'react-redux';
import { practicalTrainingsSelector } from '../../states/features/selectors';
import { filter, isEmpty } from 'lodash';
import strHelper from '../../helpers/strHelper';
import moment from 'moment';

const SelectedFieldWidget = () => {
    // ############### Redux state #################
    const { practicalTrainings, practicalTrainingSeasons } = useSelector(
        practicalTrainingsSelector,
    );

    // ############### FUNC ####################
    const getApprovedTraining = () => {
        let approved = filter(
            practicalTrainings,
            ({ application }) => application.status === 'approved',
        );
        return approved[0];
    };
    const training = getApprovedTraining();

    // => Get current season
    const getCurrSeason = () => {
        let currDate = new Date();
        let currMonth = moment(currDate).format('MM');
        let currYear = parseInt(moment(currDate).format('YYYY'));
        let currSeason = '';
        if (currMonth > 6) {
            let startYear = currYear;
            let lastYear = currYear + 1;
            currSeason = startYear + '/' + lastYear;
        } else {
            let startYear = currYear - 1;
            let lastYear = currYear;
            currSeason = startYear + '/' + lastYear;
        }
        return 'Academic year ' + currSeason;
    };
    const currSeason = getCurrSeason();

    const getCurrAddedSeason = (season) => {
        let currSeason = filter(
            practicalTrainingSeasons,
            ({ title }) => title === season,
        );
        return currSeason[0];
    };
    const currAddedSeason = getCurrAddedSeason(currSeason);

    return (
        <>
            {isEmpty(training) ? (
                <>
                    <NoContent
                        message={
                            <Box sx={{ px: 3 }}>
                                <Typography sx={{ fontSize: 15 }}>
                                    No current approved practical training for
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 18,
                                        color: colors.info,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {currAddedSeason?.title}
                                </Typography>
                            </Box>
                        }
                    />
                </>
            ) : (
                <Link to={`/practical-trainings/${training.id}`}>
                    {' '}
                    <Box
                        sx={{
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                            borderTop: `5px solid ${colors.bgColor5}`,
                            borderRadius: 2,
                            overflow: 'hidden',
                            boxShadow: `1px 1px 10px 0px ${colors.bgColor5}`,
                        }}
                    >
                        <Box
                            sx={{
                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                p: 1,
                                textAlign: 'center',
                                position: 'relative',
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <Box>
                                <Typography
                                    sx={{
                                        fontSize: 16,
                                        fontWeight: 'bold',
                                        color: colors.warning,
                                    }}
                                >
                                    {strHelper().strCapitalizeFirstChar(
                                        training.title,
                                    )}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        color: colors.info,
                                    }}
                                >
                                    {strHelper().strCapitalizeFirstChar(
                                        training.department.name,
                                    )}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 12,
                                        opacity: 0.8,
                                        color: colors.warning,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        color: colors.primary,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    <LocationOn
                                        sx={{
                                            fontSize: 12,
                                            mr: 0.5,
                                        }}
                                    />
                                    {training.region}
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                p: 1.5,
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Box>
                                <ListItem alignItems="flex-start" sx={{ p: 0 }}>
                                    <ListItemAvatar>
                                        <Avatar
                                            alt="Business Op"
                                            src={training.organization.org_logo}
                                            variant="square"
                                            sx={{
                                                borderRadius: 2,
                                                border: `1px dotted ${colors.warning}`,
                                                p: 0.5,
                                                img: {
                                                    objectFit: 'contain',
                                                },
                                            }}
                                        />
                                    </ListItemAvatar>
                                    <ListItemText
                                        disableTypography
                                        primary={
                                            <Typography
                                                sx={{
                                                    fontSize: 14,
                                                    opacity: 0.8,
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                {
                                                    training.organization
                                                        .organization_name
                                                }
                                            </Typography>
                                        }
                                        secondary={
                                            <React.Fragment>
                                                <Typography
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                    component="span"
                                                    variant="body2"
                                                    color="text.primary"
                                                >
                                                    <LocationOn
                                                        sx={{
                                                            fontSize: 14,
                                                            color:
                                                                colors.secondary,
                                                        }}
                                                    />
                                                    {
                                                        training.organization
                                                            .location
                                                    }
                                                </Typography>
                                            </React.Fragment>
                                        }
                                    />
                                </ListItem>
                            </Box>
                            <Box>
                                <Box
                                    sx={{
                                        p: 0.5,
                                        px: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        borderRadius: 2,
                                        background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.secondary})`,
                                    }}
                                >
                                    <Badge
                                        showZero
                                        color="info"
                                        badgeContent={training.totalApplied}
                                    >
                                        <Typography
                                            sx={{ fontSize: 12, pr: 2 }}
                                        >
                                            Applicants
                                        </Typography>
                                    </Badge>
                                </Box>
                                <Box
                                    sx={{
                                        p: 0.5,
                                        px: 1,
                                        mt: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        borderRadius: 2,
                                        background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor3})`,
                                    }}
                                >
                                    <Typography
                                        component={'div'}
                                        sx={{
                                            fontSize: 12,
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        Positions
                                        {training.positions ? (
                                            <Chip
                                                label={training.positions}
                                                size="small"
                                                sx={{ ml: 1, fontSize: 12 }}
                                                color="secondary"
                                            />
                                        ) : (
                                            <AllInclusive
                                                color="warning"
                                                sx={{ fontSize: 14, ml: 1 }}
                                            />
                                        )}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Link>
            )}
        </>
    );
};

export default SelectedFieldWidget;
