// Get training season
const GET_TRAINING_SEASONS = '/practical-trainings/seasons';

// Enroll for training application
const TRAINING_SEASON_ENROLL = '/practical-trainings/applications/enroll';

export const trainingSeasonEndpoints = {
    GET_TRAINING_SEASONS,
    TRAINING_SEASON_ENROLL,
};
