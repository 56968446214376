import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    sections: [],
};

// !########## SLICE #############
const instituteSlice = createSlice({
    name: 'instituteSlice',
    initialState,
    reducers: {
        setActiveSections: (state, action) => {
            state.sections = action.payload;
        },
    },
});

export const { setActiveSections } = instituteSlice.actions;
export default instituteSlice.reducer;
