import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AcademicDetails from '../pages/AcademicDetails';
import ForgetPassword from '../pages/auth/ForgetPassword';
import Login from '../pages/auth/Login';
import Register from '../pages/auth/Register';
import VerifyRegID from '../pages/auth/CRUD/VerifyRegID';
import Dashboard from '../pages/Dashboard';
import HelpCenter from '../pages/helpCenter/HelpCenter';
import AppLayout from '../pages/layouts/AppLayout';
import AuthLayout from '../pages/layouts/AuthLayout';
import ActivePT from '../pages/practicalTrainings/ActivePT';
import AppliedPT from '../pages/practicalTrainings/AppliedPT';
import PracticalTrainingDetails from '../pages/practicalTrainings/PracticalTrainingDetails';
import PracticalTrainings from '../pages/practicalTrainings/PracticalTrainings';
import Profile from '../pages/profile/Profile';
import WazawaMaxMob from '../pages/wazawamaxMobile/WazawaMaxMob';
import GuestRoutes from './GuestRoutes';
import PrivateRoutes from './PrivateRoutes';
import ResetPassword from '../pages/auth/ResetPassword';
import NotificationDetails from '../pages/notifications/NotificationDetails';
import Notifications from '../pages/notifications/Notifications';
import FavoritesPT from '../pages/practicalTrainings/FavoritesPT';

// ?############## GUEST ELEMENTS ###########
// -> login
const LoginElement = () => (
    <AuthLayout>
        <Login />
    </AuthLayout>
);

// -> verify reg id
const VerifyRegIDElement = () => (
    <AuthLayout>
        <VerifyRegID />
    </AuthLayout>
);

// -> register
const RegisterElement = () => (
    <AuthLayout>
        <Register />
    </AuthLayout>
);

// -> forget password
const ForgetElement = () => (
    <AuthLayout>
        <ForgetPassword />
    </AuthLayout>
);

// -> Reset password
const ResetPasswordElement = () => (
    <AuthLayout>
        <ResetPassword />
    </AuthLayout>
);

// ?############## PROTECTED ELEMENTS ###########
const DashboardElement = () => (
    <AppLayout>
        <Dashboard />
    </AppLayout>
);
const AcademicDetailsElement = () => (
    <AppLayout>
        <AcademicDetails />
    </AppLayout>
);
const PracticalTrainingsElement = () => (
    <AppLayout>
        <PracticalTrainings />
    </AppLayout>
);
const PracticalTrainingDetailsElement = () => (
    <AppLayout>
        <PracticalTrainingDetails />
    </AppLayout>
);
const PracticalTrainingActiveElement = () => (
    <AppLayout>
        <ActivePT />
    </AppLayout>
);
const PracticalTrainingAppliedElement = () => (
    <AppLayout>
        <AppliedPT />
    </AppLayout>
);
const PracticalTrainingFavoriteElement = () => (
    <AppLayout>
        <FavoritesPT />
    </AppLayout>
);
const ProfileElement = () => (
    <AppLayout>
        <Profile />
    </AppLayout>
);
const HelpCenterElement = () => (
    <AppLayout>
        <HelpCenter />
    </AppLayout>
);
const WazawaMaxMobElement = () => (
    <AppLayout>
        <WazawaMaxMob />
    </AppLayout>
);

//! ###################### NOTFICATION ELEMENTS #######################
const NotificationsElement = () => (
    <AppLayout>
        <Notifications />
    </AppLayout>
);
const NotificationDetailsElement = () => (
    <AppLayout>
        <NotificationDetails />
    </AppLayout>
);

// !############## MAIN FUNC ###########
const App = () => {
    return (
        <React.Fragment>
            <Routes>
                {/* ################## GUEST ROUTES ################## */}
                <Route element={<GuestRoutes />}>
                    <Route path="/" element={<LoginElement />} />
                    <Route
                        path="/verify/registration-id"
                        element={<VerifyRegIDElement />}
                    />
                    <Route path="/register" element={<RegisterElement />} />
                    <Route
                        path="/forget-password"
                        element={<ForgetElement />}
                    />
                    <Route
                        path="/reset-password"
                        element={<ResetPasswordElement />}
                    />
                </Route>

                {/* ################## PROTECTED ROUTES ################## */}
                <Route element={<PrivateRoutes />}>
                    <Route path="/dashboard" element={<DashboardElement />} />
                    <Route path="/profile" element={<ProfileElement />} />
                    <Route
                        path="/help-center"
                        element={<HelpCenterElement />}
                    />
                    <Route
                        path="/wazawa-max-app"
                        element={<WazawaMaxMobElement />}
                    />
                    <Route
                        path="/academic-details"
                        element={<AcademicDetailsElement />}
                    />
                    <Route
                        path="/practical-trainings"
                        element={<PracticalTrainingsElement />}
                    />
                    <Route
                        path="/practical-trainings/:training_id"
                        element={<PracticalTrainingDetailsElement />}
                    />
                    <Route
                        path="/practical-trainings/active"
                        element={<PracticalTrainingActiveElement />}
                    />
                    <Route
                        path="/practical-trainings/applied"
                        element={<PracticalTrainingAppliedElement />}
                    />
                    <Route
                        path="/practical-trainings/favorites"
                        element={<PracticalTrainingFavoriteElement />}
                    />

                    {/* ##################### Notifications ROUTES ########### */}
                    <Route
                        path="/notifications"
                        element={<NotificationsElement />}
                    />
                    <Route
                        path="/notifications/:notification_id"
                        element={<NotificationDetailsElement />}
                    />
                </Route>
            </Routes>
        </React.Fragment>
    );
};

export default App;
