import React from 'react';
import { Box, styled, Toolbar, Typography } from '@mui/material';

// ======== image import ============
import GPBadge from '../assets/media/images/gp_badge.png';
import ASBadge from '../assets/media/images/as_badge.png';
import { colors } from '../assets/utils/constants';

const ButtonContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'space-between',
    paddingTop: 15,
    paddingBottom: 15,
});

const StoreButton = styled(Box)(({ theme }) => ({
    '& :hover': {
        transform: `Scale(1.05)`,
        transition: 'all ease-in-out .3s',
    },
    '& img': {
        width: '100%',
        height: 40,
        borderRadius: 5,
        objectFit: 'contain',
        transition: 'all ease-in-out .3s',
    },
}));

const StoreButtons = () => {
    return (
        <>
            <Box
                sx={{
                    background: `linear-gradient(180deg, ${colors.bgColor5}, ${colors.secondary}) !important`,
                    mt: 3,
                }}
            >
                <Box sx={{ textAlign: 'center', pt: 3 }}>
                    <Typography>
                        We available on both Android and Ios Platforms
                    </Typography>
                </Box>
                <ButtonContainer>
                    <Toolbar>
                        <StoreButton href="">
                            <img src={GPBadge} alt="gp" />
                        </StoreButton>
                    </Toolbar>
                    <Toolbar>
                        <StoreButton href="">
                            <img src={ASBadge} alt="ps" />
                        </StoreButton>
                    </Toolbar>
                </ButtonContainer>
            </Box>
        </>
    );
};

export default StoreButtons;
