import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { orgBaseURL } from "../baseUrl/baseURL";
import { instituteEndpoints } from "./instituteEndpoints";

export const instituteAPI = createApi({
    reducerPath: "instituteAPI",
    baseQuery: fetchBaseQuery({
        baseUrl: orgBaseURL,
    }),
    endpoints: (builder) => ({
        // => Active sections
        getActiveSections: builder.query({
            query: (payload) =>
                `${instituteEndpoints.ACTIVE_SECTIONS}/${payload}`,
        }),
    }),
});

export const { useGetActiveSectionsQuery } = instituteAPI;
