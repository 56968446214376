import React from 'react';
import { Box, IconButton, styled, Typography } from '@mui/material';
import { colors } from '../../assets/utils/constants';
import { Close } from '@mui/icons-material';

const Card = styled(Box)({
    background: colors.bgColor2,
    border: `1px solid ${colors.bgColor4}`,
    borderBottomLeftRadius: 25,
    borderBottomRightRadius: 25,
});

const CardHeader = styled(Box)({
    'display': 'flex',
    'justifyContent': 'space-between',
    'alignItems': 'center',
    'paddingTop': 12,
    'paddingBottom': 12,
    'paddingLeft': 15,
    'paddingRight': 15,
    'position': 'relative !important',
    '.head': {
        'fontSize': 16,
        'color': colors.primary,
        'fontWeight': 'bold',
        'opacity': 0.8,
        '::before': {
            content: "''",
            width: 90,
            height: 4,
            background: colors.bgColor5,
            position: 'absolute',
            bottom: 0,
        },
    },
});

const CardBody = styled(Box)({
    padding: 15,
    overflow: 'auto',
});

const CustomSearchCard = ({
    action,
    head,
    height,
    borderBottom,
    toggleSearchModal,
    children,
}) => {
    return (
        <React.Fragment>
            <Card
                sx={{
                    borderLeft: `15px solid ${colors.bgColor4}`,
                    borderRight: `15px solid ${colors.bgColor4}`,
                    borderBottom: borderBottom
                        ? `15px solid ${colors.bgColor4}`
                        : `1px solid ${colors.bgColor4}`,
                }}
            >
                {head && (
                    <CardHeader>
                        <Typography className="head">{head}</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {action}
                            <IconButton
                                sx={{
                                    ml: 4,
                                    bgcolor: colors.bgColor4,
                                    borderRadius: 50,
                                }}
                                color="warning"
                                onClick={toggleSearchModal}
                                size="small"
                            >
                                <Close />
                            </IconButton>
                        </Box>
                    </CardHeader>
                )}
                <CardBody sx={{ height: height }}>{children}</CardBody>
            </Card>
        </React.Fragment>
    );
};

export default CustomSearchCard;
