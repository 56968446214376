import {
    AllInclusive,
    AppRegistration,
    ArrowRightAltOutlined,
} from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { colors } from '../../assets/utils/constants';
import CustomCard2 from '../cards/CustomCard2';
import CustomDialog from '../dialogs/CustomDialog';
import FeedbackDialog from '../dialogs/FeedbackDialog';
import CustomSubmitButton from '../Forms/CustomSubmitButton';
import OrganizationFieldWidget from '../widgets/OrganizationFieldWidget';
import SuccessFeedback from '../feedbacks/SuccessFeedback';
import ApplyTraining from '../../pages/practicalTrainings/CRUD/ApplyTraining';
import { filter, isEmpty, size } from 'lodash';

const PracticalTrainingDetailsSidebar = ({ training }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const handleConfimationDialogOpen = () => {
        setOpenDialog(true);
    };
    const handleConfimationDialogClose = () => {
        setOpenDialog(false);
    };
    const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false);
    const handleFeedbackDialogOpen = () => {
        setOpenDialog(false);
        setOpenFeedbackDialog(true);
    };
    const handleFeedbackDialogClose = () => {
        setOpenFeedbackDialog(false);
    };
    return (
        <>
            {/* ############# DIALOG ############### */}
            <FeedbackDialog
                isConfirm
                openDialog={openFeedbackDialog}
                handleClose={handleFeedbackDialogClose}
            >
                <SuccessFeedback
                    message={
                        'Practical training application sent successfully.'
                    }
                />
            </FeedbackDialog>
            <CustomDialog
                isConfirm
                noDropClose
                openDialog={openDialog}
                head="Practical training application"
                handleDialogClose={handleConfimationDialogClose}
            >
                <ApplyTraining
                    training={training}
                    handleConfirm={handleFeedbackDialogOpen}
                    handleDialogClose={handleConfimationDialogClose}
                />
            </CustomDialog>

            {/* ############# CONTENT ############### */}
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <CustomCard2 head={'Posted by'}>
                        <OrganizationFieldWidget
                            organization={training?.organization}
                        />
                    </CustomCard2>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Box
                        className={
                            training?.application?.status === 'approved'
                                ? 'success-box'
                                : training?.application?.status === 'declined'
                                ? 'error-box'
                                : training?.application?.status === 'pending'
                                ? 'info-box'
                                : 'warning-box'
                        }
                        sx={{
                            borderRadius: 2,
                            flexDirection: 'column',
                            p: 0.4,
                            boxShadow: '3px 3px 3px 3px rgba(46, 119, 174,0.2)',
                        }}
                    >
                        <Typography
                            sx={{
                                textAlign: 'center',
                                textTransform: 'uppercase',
                                p: 0.5,
                            }}
                        >
                            Application status
                        </Typography>
                        <Typography
                            sx={{
                                textAlign: 'center',
                                p: 0.5,
                                textTransform: 'capitalize',
                            }}
                        >
                            {training?.application
                                ? `${training?.application?.status}`
                                : 'Not applied'}
                        </Typography>
                    </Box>
                </Grid>
                {training?.application?.status === 'approved' && (
                    <Grid item xs={12} sm={12}>
                        <Box
                            className={
                                training?.application?.onTrainingStatus ===
                                'completed'
                                    ? 'success-box'
                                    : training?.application
                                          ?.onTrainingStatus === 'postponed'
                                    ? 'error-box'
                                    : training?.application
                                          ?.onTrainingStatus === 'pending'
                                    ? 'warning-box'
                                    : training?.application
                                          ?.onTrainingStatus === 'on-training'
                                    ? 'info-box'
                                    : 'warning-box'
                            }
                            sx={{
                                borderRadius: 2,
                                flexDirection: 'column',
                                p: 0.4,
                                boxShadow:
                                    '3px 3px 3px 3px rgba(46, 119, 174,0.2)',
                            }}
                        >
                            <Typography
                                sx={{
                                    textAlign: 'center',
                                    textTransform: 'uppercase',
                                    p: 0.5,
                                }}
                            >
                                Training status
                            </Typography>
                            <Typography
                                sx={{
                                    textAlign: 'center',
                                    p: 0.5,
                                    textTransform: 'capitalize',
                                }}
                            >
                                {training?.application
                                    ? `${training?.application?.onTrainingStatus}`
                                    : 'Not applied'}
                            </Typography>
                        </Box>
                    </Grid>
                )}
                <Grid item xs={12} sm={12}>
                    <Box
                        sx={{
                            pt: 1.5,
                            borderRadius: 2,
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                            textAlign: 'center',
                            height: 120,
                            position: 'relative',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 14,
                                fontWeight: 'bold',
                                pb: 1,
                                borderBottom: `1px solid ${colors.bgColor5}`,
                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                color: colors.info,
                            }}
                        >
                            Overall training applicants
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                p: 1.5,
                            }}
                        >
                            <Box
                                sx={{
                                    width: '100%',
                                    mr: 1,
                                    color: colors.warning,
                                }}
                            >
                                <strong>
                                    <small>Applied</small>
                                </strong>
                                <Typography>
                                    {size(training?.applicants)}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    width: '100%',
                                    mr: 1,
                                    color: colors.success,
                                }}
                            >
                                <strong>
                                    <small>Approved</small>
                                </strong>
                                <Typography>
                                    {size(
                                        filter(
                                            training?.applicants,
                                            ({ status }) =>
                                                status === 'approved',
                                        ),
                                    )}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    width: '100%',
                                    mr: 1,
                                    color: colors.info,
                                }}
                            >
                                <strong>
                                    <small>Available</small>
                                </strong>
                                {training.positions > 0 ? (
                                    <Typography>
                                        {training.positions -
                                            size(
                                                filter(
                                                    training?.applicants,
                                                    ({ status }) =>
                                                        status === 'approved',
                                                ),
                                            )}
                                    </Typography>
                                ) : (
                                    <AllInclusive />
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                {!training?.application && (
                    <Grid item xs={12} sm={12}>
                        <CustomCard2 head={'Action buttons'}>
                            <Grid container>
                                {!training?.application && (
                                    <Grid item sm={12} xs={12}>
                                        <CustomSubmitButton
                                            startIcon={<AppRegistration />}
                                            endIcon={<ArrowRightAltOutlined />}
                                            btnColor={`secondary`}
                                            variant={'contained'}
                                            onClick={
                                                handleConfimationDialogOpen
                                            }
                                            sx={{ py: 1.5, fontSize: 16 }}
                                        >
                                            Apply now
                                        </CustomSubmitButton>
                                    </Grid>
                                )}
                            </Grid>
                        </CustomCard2>
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default PracticalTrainingDetailsSidebar;
