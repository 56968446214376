import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Slide, Tooltip, Typography } from '@mui/material';
import { colors } from '../../assets/utils/constants';
import { Check } from '@mui/icons-material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            <Tooltip title={'Close dialog'} arrow>
                <IconButton
                    className="neo-bulge"
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: colors.secondary,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </Tooltip>
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const FeedbackDialog = ({
    openDialog,
    handleDialogClose,
    head,
    noDropClose,
    isConfirm,
    customWidth,
    handleClose,
    children,
}) => {
    return (
        <>
            <BootstrapDialog
                className="neo-bulge"
                onClose={noDropClose ? noDropClose : handleDialogClose}
                aria-labelledby="customized-dialog-title"
                open={openDialog}
                TransitionComponent={Transition}
                fullWidth
                maxWidth={customWidth && customWidth}
                sx={{
                    ' .MuiPaper-root': {
                        borderRadius: 5,
                        boxShadow:
                            '-8px -8px 12px 0 rgba(0, 0, 0, .1) inset !important',
                        border: `10px solid ${colors.bgColor2}`,
                    },
                }}
            >
                {!isConfirm ? (
                    <BootstrapDialogTitle
                        id="customized-dialog-title"
                        onClose={handleDialogClose}
                        sx={{
                            fontSize: 16,
                            fontWeight: 'bold',
                            bgcolor: colors.primary,
                            color: colors.secondary,
                        }}
                    >
                        {head}
                    </BootstrapDialogTitle>
                ) : (
                    ''
                )}
                <DialogContent dividers>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <Box>{children}</Box>
                        <Box sx={{ mt: 3 }}>
                            <Button
                                variant="contained"
                                className="app-btn"
                                size="small"
                                sx={{ px: 4 }}
                                color="warning"
                                onClick={handleClose}
                            >
                                Close
                            </Button>
                        </Box>
                    </Box>
                </DialogContent>
            </BootstrapDialog>
        </>
    );
};

export default FeedbackDialog;
