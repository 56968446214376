import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { colors } from '../../assets/utils/constants';

const FeatureWidget = ({ feature, handleImgPreview }) => {
    return (
        <>
            <Button
                sx={{ textTransform: 'capitalize', textAlign: 'left' }}
                onClick={() => handleImgPreview(feature.img)}
            >
                <Box
                    sx={{
                        height: 100,
                        background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor4}) !important`,
                        borderRadius: 3,
                        overflow: 'hidden',
                        border: `1px solid ${colors.bgColor5}`,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            p: 1,
                        }}
                    >
                        <Box
                            sx={{
                                height: 40,
                                width: 40,
                                background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor5}) !important`,
                                borderRadius: 50,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                mr: 1,
                                borderBottom: `5px solid ${colors.primary}`,
                            }}
                        >
                            <Typography>{feature.id}</Typography>
                        </Box>
                        <Typography sx={{ fontWeight: 'bold' }}>
                            {feature.feature}
                        </Typography>
                    </Box>
                    <Box sx={{ px: 2 }}>
                        <Typography sx={{ fontSize: 12 }}>
                            Lorem ipsum dolor sit, amet consectetur adipisicing
                            elit.
                        </Typography>
                    </Box>
                </Box>
            </Button>
        </>
    );
};

export default FeatureWidget;
