import { Box, Divider, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { colors } from '../../assets/utils/constants';

const ContactWidget = ({ contact }) => {
    return (
        <>
            <Link to={contact.url}>
                <Box
                    sx={{
                        'height': 80,
                        'display': 'flex',
                        'justifyContent': 'center',
                        'alignItems': 'center',
                        'flexDirection': 'column',
                        'borderRadius': 2,
                        'background': `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor4})`,
                        'transition': `.3s ease-in-out all`,
                        ':hover': {
                            boxShadow: `1px 1px 10px 2px ${colors.bgColor5}`,
                            transition: `.3s ease-in-out all`,
                        },
                    }}
                >
                    <Divider orientation="horizontal" flexItem>
                        <Box
                            sx={{
                                textAlign: 'center',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            {contact.icon}
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    fontWeight: 'bold',
                                }}
                            >
                                {contact.title}
                            </Typography>
                        </Box>
                    </Divider>
                    <Typography sx={{ mt: 1, fontSize: 16 }}>
                        {contact.contact}
                    </Typography>
                </Box>
            </Link>
        </>
    );
};

export default ContactWidget;
