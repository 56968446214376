import React from 'react';
import { Box, Grid, styled } from '@mui/material';
import { colors } from '../assets/utils/constants';

const SectionCon = styled(Box)(({ theme }) => ({
    'display': 'flex',
    'justifyContent': 'center',
    'alignItems': 'center',
    '& .section-head': {
        fontSize: 30,
        fontWeight: 'bold',
        color: colors.primary,
        opacity: 0.85,
        position: 'relative',
        zIndex: 24,
        // '::before': {
        //     content: "''",
        //     width: 60,
        //     borderTop: `5px solid ${colors.primary}`,
        //     borderLeft: `5px solid ${colors.primary}`,
        //     position: 'absolute',
        //     top: -10,
        //     bottom: 0,
        //     left: -15,
        //     zIndex: -1,
        //     borderTopLeftRadius: 15,
        //     opacity: 0.5,
        // },
    },
}));

const Section = ({ bgcolor, children }) => {
    return (
        <>
            <SectionCon
                sx={{
                    background: bgcolor,
                    height: '100%',
                }}
            >
                <Grid container justifyContent={'center'}>
                    <Grid item sm={11}>
                        {children}
                    </Grid>
                </Grid>
            </SectionCon>
        </>
    );
};

export default Section;
