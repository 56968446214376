import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    pinId: '',
    phoneNumber: '',
    isPhoneVerified: false,
    preProfile: '',
    selectedSection: '',
    startYear: '',
    finishYear: '',
};

// !########## SLICE #############
const registerSlice = createSlice({
    name: 'registerSlice',
    initialState,
    reducers: {
        setPreProfile: (state, action) => {
            state.preProfile = action.payload;
        },
        setPinId: (state, action) => {
            let pinId = action.payload.pinId;
            let phoneNumber = action.payload.phoneNumber;
            state.pinId = pinId;
            state.phoneNumber = phoneNumber;
        },
        phoneVerified: (state, action) => {
            state.isPhoneVerified = action.payload;
        },
        setSelectedSection: (state, action) => {
            state.selectedSection = action.payload.section_id;
            state.startYear = action.payload.start_year;
            state.finishYear = action.payload.finish_year;
        },
    },
});

export const {
    setPreProfile,
    setPinId,
    setSections,
    phoneVerified,
    setSelectedSection,
} = registerSlice.actions;
export default registerSlice.reducer;
