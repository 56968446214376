import React, { useEffect, useState } from 'react';
import { PersonAdd, TaskAltOutlined } from '@mui/icons-material';
import { Box, Button, Chip, Typography } from '@mui/material';
import { colors } from '../../assets/utils/constants';
import CurrentDateTime from '../CurrentDateTime';
import CustomDialog from '../dialogs/CustomDialog';
import EnrollForApplicationConfimation from '../confimations/EnrollForApplicationConfimation';
import useRTK from '../../hooks/useRTK';
import { useDispatch, useSelector } from 'react-redux';
import { setPracticalTrainingSeasons } from '../../states/features/practicalTrainings/practicalTrainingsSlice';
import { useLazyGetPracticalTrainingSeasonsQuery } from '../../api/practicalTrainings/trainingSeasonAPI';
import {
    practicalTrainingsSelector,
    profileSelector,
} from '../../states/features/selectors';
import { filter, isEmpty } from 'lodash';
import moment from 'moment';
import ContentLoader from '../ContentLoader';

const EnrollmentCard = () => {
    // ############### CONST #################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############### Comp State #################
    const [openDialog, setOpenDialog] = useState(false);

    // ############### Redux state ###############
    const { profile: user } = useSelector(profileSelector);
    const { practicalTrainingSeasons } = useSelector(
        practicalTrainingsSelector,
    );

    // ################# RTK #################
    const [
        getTrainingSeason,
        { isLoading, isSuccess, data: seasonData },
    ] = useLazyGetPracticalTrainingSeasonsQuery();
    const rtkActions = (data) => {
        dispatch(setPracticalTrainingSeasons(data));
    };

    // ############# useEffect ##################
    RTK.useRTKFetch(isSuccess, seasonData, rtkActions);
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (!isEmpty(user)) {
                if (!isEmpty(user.institute_id)) {
                    getTrainingSeason(user.institute_id);
                }
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [user]);

    // ############## FUNC #################
    // => Handle dialogy open
    const handleConfimationDialogOpen = () => {
        setOpenDialog(true);
    };
    // => Handle dialogy close
    const handleConfimationDialogClose = () => {
        setOpenDialog(false);
    };

    // => Get current season
    const getCurrSeason = () => {
        let currDate = new Date();
        let currMonth = moment(currDate).format('MM');
        let currYear = parseInt(moment(currDate).format('YYYY'));
        let currSeason = '';
        if (currMonth > 6) {
            let startYear = currYear;
            let lastYear = currYear + 1;
            currSeason = startYear + '/' + lastYear;
        } else {
            let startYear = currYear - 1;
            let lastYear = currYear;
            currSeason = startYear + '/' + lastYear;
        }
        return 'Academic year ' + currSeason;
    };
    const currSeason = getCurrSeason();

    const getCurrAddedSeason = (season) => {
        let currSeason = filter(
            practicalTrainingSeasons,
            ({ title }) => title === season,
        );
        return currSeason[0];
    };
    const currAddedSeason = getCurrAddedSeason(currSeason);

    return (
        <>
            {/* ############# DIALOG ############### */}
            <CustomDialog
                isConfirm
                noDropClose
                openDialog={openDialog}
                handleDialogClose={handleConfimationDialogClose}
                head="Confirm practical training enrollment"
            >
                <EnrollForApplicationConfimation
                    currSeason={currAddedSeason}
                    handleDialogClose={handleConfimationDialogClose}
                />
            </CustomDialog>

            {/* ############# CONTENTS ############# */}
            <Box
                sx={{
                    height: 278,
                    display: 'flex',
                    justifyContent: 'space-',
                    flexDirection: 'column',
                    borderRadius: 4,
                    overflow: 'hidden',
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor5})`,
                    border: `1px dotted ${colors.warning}`,
                }}
            >
                <Box>
                    <CurrentDateTime user={user} />
                </Box>
                <Box
                    sx={{
                        textAlign: 'center',
                        height: 200,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        position: 'relative',
                    }}
                >
                    <Box>
                        <Typography
                            sx={{
                                fontSize: 15,
                                p: 1,
                                opacity: 0.9,
                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.secondary})`,
                            }}
                        >
                            Practical training enrollement
                        </Typography>
                    </Box>

                    {isLoading ? (
                        <>
                            <ContentLoader height={211} />
                        </>
                    ) : isEmpty(currAddedSeason) ? (
                        <Box
                            sx={{
                                height: 130,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                p: 3,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 18,
                                    textAlign: 'center',
                                    color: colors.warning,
                                    fontWeight: 'bold',
                                }}
                            >
                                No active practical training season
                            </Typography>
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                flexDirection: 'column',
                                borderRadius: 4,
                                overflow: 'hidden',
                            }}
                        >
                            <Typography
                                sx={{
                                    mt: 1,
                                    fontWeight: 'bold',
                                    color: colors.info,
                                }}
                            >
                                {currAddedSeason.title}
                            </Typography>
                            <Box>
                                <Chip
                                    label={`Practical training season ${currAddedSeason.status}`}
                                    color={
                                        currAddedSeason.status === 'opened'
                                            ? 'success'
                                            : currAddedSeason.status ===
                                              'closed'
                                            ? 'error'
                                            : 'warning'
                                    }
                                />
                            </Box>
                            {user.isCurrEnrolled === false ? (
                                <Box>
                                    <Button
                                        className="app-btn"
                                        variant="contained"
                                        size="small"
                                        fullWidth
                                        startIcon={<PersonAdd />}
                                        onClick={handleConfimationDialogOpen}
                                        sx={{ py: 1 }}
                                    >
                                        Enroll for application
                                    </Button>
                                </Box>
                            ) : (
                                <Box
                                    sx={{
                                        height: 40,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    className="success-message"
                                >
                                    <TaskAltOutlined
                                        sx={{
                                            fontSize: 30,
                                            color: colors.success,
                                            mr: 2,
                                        }}
                                    />
                                    <Typography sx={{ fontWeight: 'bold' }}>
                                        Your enrolled
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    )}
                </Box>
            </Box>
        </>
    );
};

export default EnrollmentCard;
