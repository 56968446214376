import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const initialState = {
    profile: {},
};

// !########## SLICE #############
const profileSlice = createSlice({
    name: 'profileSlice',
    initialState,
    reducers: {
        setUserProfile: (state, action) => {
            let profile = action.payload;
            state.profile = profile;
            // => Update user Cookie
            cookies.set('user', profile);
        },
        setAuthUserProfile: (state, action) => {
            let profile = action.payload.user;
            state.profile = profile;
        },
        removeAuthUserProfile: (state, action) => {
            state.profile = {};
        },
    },
});

export const {
    setUserProfile,
    setAuthUserProfile,
    removeAuthUserProfile,
} = profileSlice.actions;
export default profileSlice.reducer;
