import { AppRegistration, ArrowRightAltOutlined, DoneAllOutlined } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import CustomSubmitButton from '../Forms/CustomSubmitButton';

const ApplyConfimation = ({ handleDialogClose, handleConfirm }) => {
    return (
        <>
            <Box sx={{ width: '100%' }}>
                <Box className="warning-message">
                    <Typography sx={{ fontSize: 18, fontWeight: 'bold' }}>
                        Confirm practical training application
                    </Typography>
                </Box>
                <Box sx={{ py: 5 }}>
                    <Typography sx={{ fontSize: 15, textAlign: 'center' }}>
                        Lorem ipsum dolor, sit amet consectetur adipisicing
                        elit. Voluptate laboriosam, maxime aliquid laborum
                        necessitatibus accusantium facilis harum praesentium
                        voluptatem.
                    </Typography>
                </Box>
                <Box
                    sx={{
                        py: 0.5,
                        width: '100%',
                    }}
                >
                    <CustomSubmitButton
                        startIcon={<DoneAllOutlined color='success' />}
                        endIcon={<ArrowRightAltOutlined />}
                        btnColor={`secondary`}
                        variant={'contained'}
                        onClick={handleConfirm}
                        sx={{ py: 1.5, fontSize: 16 }}
                    >
                        Submit application
                    </CustomSubmitButton>
                </Box>
            </Box>
        </>
    );
};

export default ApplyConfimation;
