import React from 'react';
import { Typography } from '@mui/material';
import { colors } from '../assets/utils/constants';

const CountBadge = ({ total, item }) => {
    return (
        <>
            <Typography
                sx={{
                    p: 1,
                    opacity: 0.8,
                    fontSize: 13,
                    borderRadius: 1,
                    fontWeight: 'bold',
                    color: colors.primary,
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.secondary})`,
                }}
            >
                {`${total} ${item}`}
            </Typography>
        </>
    );
};

export default CountBadge;
