// REGISTER user
const REGISTER = '/auth/register';

// Reg ID verification
const REG_ID_VERIFY = '/auth/reg-id-verify';

// REQUEST otp
const REQUEST_OTP = '/auth/otp-request';

// VERIFY otp
const VERIFY_OTP = '/auth/otp-verify';

// FORGOT password
const FORGOT_PASSWORD = '/auth/password/forgot';

export const registerEndpoints = {
    REG_ID_VERIFY,
    REQUEST_OTP,
    VERIFY_OTP,
    REGISTER,
    FORGOT_PASSWORD,
};
