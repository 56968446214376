import React from 'react';
import { FilePresentOutlined, WorkHistory } from '@mui/icons-material';
import PageLayout from '../layouts/PageLayout';
// import PTGeneralDetails from './components/PTGeneralDetails';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useRTK from '../../hooks/useRTK';
import { practicalTrainingsSelector } from '../../states/features/selectors';
import { isEmpty } from 'lodash';
import { useGetSinglePracticalTrainingQuery } from '../../api/practicalTrainings/practicalTrainingsAPI';
import { setPracticalTrainingDetails } from '../../states/features/practicalTrainings/practicalTrainingsSlice';
import PTGeneralDetails from './components/PTGeneralDetails';
import CustomCard2 from '../../components/cards/CustomCard2';
import ContentLoader from '../../components/ContentLoader';
import CustomTabs from '../../components/tabs/CustomTabs';
import InnerDataFetchingError from '../../components/InnerDataFetchingError';
import PracticalTrainingDetailsSidebar from '../../components/sidebars/PracticalTrainingDetailsSidebar';
import TrainingDocs from './components/TrainingDocs';

// !######################### MAIL FUNC #####################
const PracticalTrainingDetails = () => {
    // ############## CONST ################
    const RTK = useRTK();
    const { training_id } = useParams();
    const dispatch = useDispatch();

    // ############# Redux state ###########
    const { practicalTrainingDetails } = useSelector(
        practicalTrainingsSelector,
    );

    // ############### RTK ################
    const {
        isLoading,
        isSuccess,
        isError,
        refetch,
        data: PTData,
    } = useGetSinglePracticalTrainingQuery(training_id);
    // => Response
    const rtkAction = (data) => {
        dispatch(setPracticalTrainingDetails(data));
    };
    RTK.useRTKFetch(isSuccess, PTData, rtkAction);

    // ############ Tabs ##################
    const PTTabs = [
        {
            id: 1,
            title: 'Details',
            icon: <WorkHistory />,
            tabsContent: !isEmpty(practicalTrainingDetails) ? (
                <PTGeneralDetails training={practicalTrainingDetails} />
            ) : (
                ''
            ),
        },
        {
            id: 2,
            title: 'Documents',
            icon: <FilePresentOutlined />,
            tabsContent: !isEmpty(practicalTrainingDetails) ? (
                <>
                    <TrainingDocs />
                </>
            ) : (
                ''
            ),
            displayNone:
                practicalTrainingDetails?.application?.status === 'approved'
                    ? false
                    : true,
        },
    ];
    return (
        <React.Fragment>
            <>
                <PageLayout
                    head={'Practical training details'}
                    sidebar={
                        !isEmpty(practicalTrainingDetails) && !isLoading ? (
                            <PracticalTrainingDetailsSidebar
                                training={practicalTrainingDetails}
                            />
                        ) : (
                            ''
                        )
                    }
                    noSidebar={isLoading || isError ? true : false}
                >
                    {/* ##################### MAIN CONTENT ################## */}
                    {isLoading ? (
                        <CustomCard2 height={'78vh'}>
                            <ContentLoader />
                        </CustomCard2>
                    ) : isError ? (
                        <>
                            <CustomCard2 height={{ md: '78vh' }}>
                                <InnerDataFetchingError
                                    height={500}
                                    handleDataRefetch={refetch}
                                />
                            </CustomCard2>
                        </>
                    ) : (
                        <CustomTabs
                            tabs={PTTabs}
                            height={{ md: '68.5vh', xs: '100%' }}
                        />
                    )}
                </PageLayout>
            </>
        </React.Fragment>
    );
};

export default PracticalTrainingDetails;
