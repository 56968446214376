import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Drawer from '@mui/material/Drawer';
import {
    Typography,
    Stack,
    Box,
    Avatar,
    Button,
    Tooltip,
    CircularProgress,
} from '@mui/material';
import logo from '../assets/media/images/logo.png';
import { colors } from '../assets/utils/constants';
import { Link, NavLink } from 'react-router-dom';
import {
    Window,
    ManageAccounts,
    Groups,
    School,
    Engineering,
    Logout,
    PhoneAndroid,
} from '@mui/icons-material';
import { useState } from 'react';
import { useLogoutMutation } from '../api/auth/authAPI';
import useRTK from '../hooks/useRTK';
import useAuth from '../hooks/useAuth';

//############# Sidebar Mui Proparty #################
const drawerWidth = 250;
const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const AppBarHeight = styled('div')(({ theme }) => ({
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const { window } = 'props';
const container =
    window !== undefined ? () => window().document.body : undefined;

//############# DrawerDesktop #################
const DrawerDesktop = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    'width': drawerWidth,
    'flexShrink': 0,
    'whiteSpace': 'nowrap',
    'boxSizing': 'border-box',
    '& .MuiPaper-root': {
        background: `linear-gradient(180deg, ${colors.primary}, ${colors.primary}) !important`,
        border: 'none !important',
        padding: '0px',
        paddingTop: 0,
        boxShadow: `0px 0px 4px 0px ${colors.bgColor5}`,
    },
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));

//############# SideNavListItem #################
const SideNavListItem = styled(ListItem)(({ theme }) => ({
    'paddingTop': 4,
    'paddingBottom': 4,
    'transition': 'all ease-in-out 0.3s',
    '&::before': {
        content: '""',
        position: 'absolute',
        height: 0,
        bottom: '50%',
        width: 4,
        transition: 'all ease-in-out 0.3s',
        backgroundColor: colors.secondary,
    },
    '& .icon': {
        minWidth: 0,
        justifyContent: 'center',
        color: colors.secondary,
        opacity: 0.9,
    },
    '& .name': {
        color: colors.secondary,
    },
}));

//############# SidebarBottom #################
const SidebarBottom = styled(Stack)(({ theme }) => ({
    'position': 'absolute',
    'bottom': 0,
    'right': 0,
    'left': 0,
    'backgroundColor': colors.primary,
    '& .content': {
        'height': 80,
        'background': `rgb(255, 255, 255, .1)`,
        'display': 'flex',
        'flexDirection': 'column',
        'justifyContent': 'center',
        'alignItems': 'center',
        'borderRadius': 5,
        'opacity': 0.8,
        '& .icon': {
            transition: `.3s ease-in-out all`,
        },
        ':hover': {
            '& .icon': {
                transform: `scale(1.3) rotate(360deg)`,
                transition: `.3s ease-in-out all`,
            },
        },
        '& .icon-con': {
            height: 45,
            width: 45,
            borderRadius: 50,
            border: `2px solid ${colors.secondary}`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    },
}));

// !############### MAIN FUNC ###################
const Sidebar = ({ handleDrawerToggle, mobileOpen }) => {
    // ############## Comp state ######################
    const RTK = useRTK();
    const auth = useAuth();
    const [open] = useState(true);

    // ############## RTK ######################
    const [
        logoutUser,
        { isLoading, isSuccess, isError, error, data: logoutData },
    ] = useLogoutMutation();
    // ############# useEffect ##################
    const rtkActions = (data) => {
        auth.logout(data);
    };
    RTK.useRTKResponse(isSuccess, isError, error, logoutData, rtkActions);

    // -> Nav links
    let links = [
        {
            id: 1,
            name: 'Profile',
            icon: <ManageAccounts />,
            url: '/profile',
            tooltip: 'Job vacancy',
            total: 4,
        },
        {
            id: 2,
            name: 'Academic details',
            icon: <School />,
            url: '/academic-details',
            tooltip: 'Job vacancy',
            total: 4,
        },
        {
            id: 3,
            name: 'Practical trainings',
            icon: <Engineering />,
            url: '/practical-trainings',
            tooltip: 'Job vacancy',
            total: 4,
        },
        {
            id: 4,
            name: 'Wazawa Max App',
            icon: <PhoneAndroid />,
            url: '/wazawa-max-app',
            tooltip: 'Job vacancy',
            total: 4,
        },
    ];

    // -> Drawer
    const drawer = (
        <Box>
            <List sx={{ marginTop: 1.2 }}>
                {/* ####################### LINKS ####################### */}
                <NavLink to="/dashboard">
                    {({ isActive }) => (
                        <SideNavListItem
                            disablePadding
                            sx={{
                                'marginTop': 3,
                                'position': 'relative',
                                'bgcolor': isActive && colors.bgColor4,
                                '& .icon': {
                                    color: isActive
                                        ? colors.primary
                                        : colors.secondary,
                                },
                                '& .name': {
                                    color: isActive
                                        ? colors.primary
                                        : colors.secondary,
                                },
                                '&::before': isActive && {
                                    content: '""',
                                    position: 'absolute',
                                    height: '100%',
                                    bottom: 0,
                                    top: 0,
                                    width: 4,
                                    transition: 'all ease-in-out 0.3s',
                                    backgroundColor: colors.info,
                                },
                                '&:hover': !isActive && {
                                    'transition': 'all ease-in-out 0.3s',
                                    '&::before': {
                                        transition: 'all ease-in-out 0.3s',
                                        height: '100%',
                                        bottom: 0,
                                    },
                                },
                            }}
                        >
                            <ListItemButton
                                sx={{
                                    py: 0.5,
                                }}
                            >
                                <ListItemIcon
                                    className="icon"
                                    sx={{ mr: open ? 1 : 'auto' }}
                                >
                                    <Window />
                                </ListItemIcon>
                                <ListItemText
                                    className="name"
                                    sx={{ opacity: open ? 1 : 0 }}
                                    primaryTypographyProps={{
                                        fontWeight: 'bold',
                                        fontSize: 14,
                                    }}
                                    primary="Dashboard"
                                />
                            </ListItemButton>
                            {isActive && (
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        height: 55,
                                        right: 0,
                                        width: 15,
                                        bgcolor: colors.bgColor4,
                                        borderTopLeftRadius: 50,
                                        borderBottomLeftRadius: 50,
                                    }}
                                ></Box>
                            )}
                        </SideNavListItem>
                    )}
                </NavLink>
                {links?.map((link) => (
                    <NavLink to={link.url} key={link.id}>
                        {({ isActive }) => (
                            <SideNavListItem
                                disablePadding
                                sx={{
                                    'position': 'relative',
                                    'bgcolor': isActive && colors.bgColor4,
                                    '& .icon': {
                                        color: isActive
                                            ? colors.primary
                                            : colors.secondary,
                                    },
                                    '& .name': {
                                        color: isActive
                                            ? colors.primary
                                            : colors.secondary,
                                    },
                                    '&::before': isActive && {
                                        content: '""',
                                        position: 'absolute',
                                        height: '100%',
                                        bottom: 0,
                                        top: 0,
                                        width: 4,
                                        transition: 'all ease-in-out 0.3s',
                                        backgroundColor: colors.info,
                                    },
                                    '&:hover': !isActive && {
                                        'transition': 'all ease-in-out 0.3s',
                                        '&::before': {
                                            transition: 'all ease-in-out 0.3s',
                                            height: '100%',
                                            bottom: 0,
                                        },
                                    },
                                }}
                            >
                                <ListItemButton
                                    sx={{
                                        py: 0.5,
                                    }}
                                >
                                    <ListItemIcon
                                        className="icon"
                                        sx={{ mr: open ? 1 : 'auto' }}
                                    >
                                        {link.icon}
                                    </ListItemIcon>
                                    <ListItemText
                                        className="name"
                                        primary={link.name}
                                        sx={{ opacity: open ? 1 : 0 }}
                                        primaryTypographyProps={{
                                            fontSize: 14,
                                        }}
                                    />
                                    {/* <Badge
                                        className="badge"
                                        badgeContent={link.total}
                                        showZero
                                        sx={{
                                            '& .MuiBadge-badge': {
                                                bgcolor: colors.bgColor3,
                                                color: colors.primary,
                                                fontWeight: 'bold',
                                            },
                                        }}
                                    /> */}
                                    {isActive && (
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                height: 55,
                                                right: 0,
                                                width: 15,
                                                bgcolor: colors.bgColor4,
                                                borderTopLeftRadius: 50,
                                                borderBottomLeftRadius: 50,
                                            }}
                                        ></Box>
                                    )}
                                </ListItemButton>
                            </SideNavListItem>
                        )}
                    </NavLink>
                ))}
            </List>

            {/* ####################### bottom ####################### */}
            <SidebarBottom>
                <Box className="content">
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                        }}
                    >
                        <Box sx={{ textAlign: 'center', width: '100%' }}>
                            <Tooltip title={'Manage profile'} arrow>
                                <Link to={'/profile'}>
                                    <Button
                                        color="info"
                                        sx={{
                                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor5})`,
                                        }}
                                    >
                                        <ManageAccounts />
                                    </Button>
                                </Link>
                            </Tooltip>
                        </Box>
                        <Box sx={{ textAlign: 'center', width: '100%' }}>
                            <Tooltip title={'Help center'} arrow>
                                <Link to={'/help-center'}>
                                    <Button
                                        color="success"
                                        sx={{
                                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor5})`,
                                        }}
                                    >
                                        <Groups />
                                    </Button>
                                </Link>
                            </Tooltip>
                        </Box>
                        <Box sx={{ textAlign: 'center', width: '100%' }}>
                            <Tooltip title={'Logout'} arrow>
                                <Button
                                    sx={{
                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor5})`,
                                    }}
                                    color="error"
                                    onClick={() => {
                                        logoutUser();
                                    }}
                                    disabled={isLoading ? true : false}
                                >
                                    {isLoading ? (
                                        <CircularProgress
                                            size={23}
                                            color="error"
                                        />
                                    ) : (
                                        <Logout />
                                    )}
                                </Button>
                            </Tooltip>
                        </Box>
                    </Box>
                </Box>
            </SidebarBottom>
        </Box>
    );

    return (
        <>
            {/* ########################### mobile ########################## */}
            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    'display': { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': {
                        boxSizing: 'border-box',
                        width: '70%',
                        backgroundColor: colors.primary,
                    },
                }}
            >
                <Toolbar />
                {drawer}
            </Drawer>

            {/* ########################### desktop ########################## */}
            <DrawerDesktop
                variant="permanent"
                open={open}
                sx={{
                    display: { xs: 'none', sm: 'block' },
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: colors.primary,
                        bgcolor: colors.primary,
                        height: 64,
                        position: 'absolute',
                        left: 0,
                        width: 250,
                        borderBottom: `3px solid ${colors.secondary}`,
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: 'bold',
                            fontSize: 18,
                            color: colors.secondary,
                        }}
                    >
                        Wazawa Max
                    </Typography>
                    <Box
                        sx={{
                            bgcolor: colors.primary,
                            borderRadius: 2,
                            ml: 1,
                        }}
                    >
                        <Avatar
                            src={logo}
                            sx={{
                                p: 1,
                                img: {
                                    objectFit: 'contain',
                                },
                            }}
                        />
                    </Box>
                </Box>
                <AppBarHeight />
                {drawer}
            </DrawerDesktop>
        </>
    );
};

export default Sidebar;
