// => Auth selector
export const authSelector = (state) => state.authReducer;

// => Profile selector
export const profileSelector = (state) => state.profileReducer;

// => Register selector
export const registerSelector = (state) => state.registerReducer;

// => Institute selector
export const instituteSelector = (state) => state.instituteReducer;

// => practicalTrainings selector
export const practicalTrainingsSelector = (state) =>
    state.practicalTrainingsReducer;

// => Help center selector
export const helpCenterSelector = (state) => state.helpCenterReducer;

// => Notification selector
export const notificationsSelector = (state) => state.notificationsReducer;

// => Admin selector
export const adminSelector = (state) => state.adminReducer;

// => System config selector
export const systemConfigSelector = (state) => state.systemConfigReducer;
