import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { colors } from '../../assets/utils/constants';

export default function CustomSearchTextfield({ searchModal, toggleSearchModal }) {
    return (
        <Paper
            component="form"
            sx={{
                p: '2px 4px',
                display: 'flex',
                zIndex: 999,
                alignItems: 'center',
                width: { xs: '100%', sm: 490 },
                boxShadow: 'none',
                border: `4px solid ${colors.bgColor4}`,
                borderRadius: { xs: 0, sm: 2 },
                background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor2}) !important`,
            }}
        >
            <InputBase
                sx={{ ml: 1, flex: 1, height:1 }}
                placeholder="Sector | Department | Program"
                inputProps={{ 'aria-label': 'Sector | Department | Program' }}
                onFocus={searchModal !== '0vh'? null : toggleSearchModal}
            />
            <IconButton type="button" sx={{ p: '1px' }} aria-label="search">
                <SearchIcon />
            </IconButton>
        </Paper>
    );
}
