import React from 'react';
import {
    ArrowRightAltOutlined,
    VerifiedUserOutlined,
} from '@mui/icons-material';
import { Grid, MenuItem } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import CustomSubmitButton from '../../../components/Forms/CustomSubmitButton';
import { useDispatch } from 'react-redux';
import useRTK from '../../../hooks/useRTK';
import CustomTextField from '../../../components/Forms/CustomTextField';
import CustomSelectInput from '../../../components/Forms/CustomSelectInput';
import { colors } from '../../../assets/utils/constants';
import { useUpdateProfileMutation } from '../../../api/profile/profileAPI';
import { setUserProfile } from '../../../states/features/profile/profileSlice';

// * -> Validation
const updateProfileValidationSchema = Yup.object({
    first_name: Yup.string().required('First name is required field'),
    middle_name: Yup.string().required('Middle name is required field'),
    last_name: Yup.string().required('Last name is required field'),
    address: Yup.string().required('Address is required field'),
    email: Yup.string().required('Email is required field'),
    gender: Yup.string().required('Gender is required field'),
    dob: Yup.date().required('Date of birth is required field'),
});

const UpdateProfile = ({ student, handleDialogClose }) => {
    // ############## CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# RTK ##################
    const [
        updateProfile,
        { isLoading, isSuccess, isError, error, data: studentData },
    ] = useUpdateProfileMutation();

    // ############# useEffect ##################
    const rtkActions = (data) => {
        handleDialogClose();
        dispatch(setUserProfile(data));
    };
    RTK.useRTKResponse(isSuccess, isError, error, studentData, rtkActions);

    return (
        <>
            <Formik
                initialValues={{
                    first_name: student ? student.first_name : '',
                    middle_name: student ? student.middle_name : '',
                    last_name: student ? student.last_name : '',
                    address: student ? student.address : '',
                    email: student ? student.email : '',
                    gender: student ? student.gender : '',
                    dob: student ? student.dob : '',
                }}
                validationSchema={updateProfileValidationSchema}
                onSubmit={(payload) => {
                    updateProfile(payload);
                }}
            >
                {(formik) => (
                    <Form>
                        <Grid container justifyContent={'center'}>
                            <Grid item sm={12} xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item sm={4} xs={12}>
                                        <CustomTextField
                                            className="form-input"
                                            label={`First name`}
                                            type="text"
                                            name="first_name"
                                        />
                                    </Grid>
                                    <Grid item sm={4} xs={12}>
                                        <CustomTextField
                                            className="form-input"
                                            label={`Middle name`}
                                            type="text"
                                            name="middle_name"
                                        />
                                    </Grid>
                                    <Grid item sm={4} xs={12}>
                                        <CustomTextField
                                            className="form-input"
                                            label={`Last name`}
                                            type="text"
                                            name="last_name"
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <CustomTextField
                                            className="form-input"
                                            label={`Address (location)`}
                                            type="text"
                                            name="address"
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <CustomTextField
                                            className="form-input"
                                            label={`email address`}
                                            type="text"
                                            name="email"
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <CustomSelectInput
                                            className="form-input"
                                            label={`Gender`}
                                            name="gender"
                                            formik={formik}
                                            color={colors.primary}
                                        >
                                            <MenuItem value={'male'}>
                                                Male
                                            </MenuItem>
                                            <MenuItem value={'female'}>
                                                Female
                                            </MenuItem>
                                        </CustomSelectInput>
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <CustomTextField
                                            className="form-input"
                                            label={`Birth date`}
                                            type="date"
                                            name="dob"
                                        />
                                    </Grid>
                                    <Grid item sm={12} xs={12}>
                                        <CustomSubmitButton
                                            startIcon={<VerifiedUserOutlined />}
                                            endIcon={<ArrowRightAltOutlined />}
                                            btnColor={`secondary`}
                                            variant={'contained'}
                                            loading={isLoading}
                                            onClick={() => {
                                                formik.handleSubmit();
                                            }}
                                            sx={{ py: 1.5, fontSize: 16 }}
                                        >
                                            Update profile
                                        </CustomSubmitButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default UpdateProfile;
