import React from 'react';
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
} from '@react-pdf/renderer';
import logo from '../../assets/media/images/logo.png';
import logo2 from '../../assets/media/images/logo.png';
import moment from 'moment/moment';
import { colors } from '../../assets/utils/constants';

// Create styles
const styles = StyleSheet.create({
    page: {
        paddingLeft: '50px',
        paddingRight: '50px',
        paddingTop: '50px',
        paddingBottom: '59px',
        backgroundColor: colors.bgColor2,
    },
    section: {},
    image: {
        height: '120px',
        width: '180px',
    },
    table: {
        display: 'table',
        width: 'auto',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
    },
    tr: {
        display: 'flex',
        flexDirection: 'row',
    },
    td: {
        padding: `8px`,
        flexGrow: 1,
        width: '100%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    thead: {
        fontSize: `10px`,
        textTransform: 'uppercase',
    },
    tText: { fontSize: `10px` },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },
});

// Create Document Component
const ReportLayout = () => (
    <Document>
        <Page size="A4" style={styles.page}>
            <View
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    bottom: 0,
                    top: 0,
                }}
            >
                <Image style={{ opacity: 0.05, width: '70%' }} src={logo2} />
            </View>
            <View
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'absolute',
                    left: 0,
                    right: 0,
                }}
            >
                <Image style={styles.image} src={logo} />
            </View>
            <View style={styles.section}>
                <Text
                    style={{
                        textAlign: 'center',
                        fontSize: '13px',
                        color: colors.primary,
                        fontWeight: '700px',
                        textTransform: 'uppercase',
                        textDecoration: 'underline',
                        marginTop: '50px',
                    }}
                >
                    Wazawa Max
                </Text>
            </View>
            <View style={styles.section}>
                <Text
                    style={{
                        fontSize: '12px',
                        color: colors.primary,
                        fontWeight: '700px',
                        marginBottom: '10px',
                    }}
                >
                    {moment().format('LL')}
                </Text>
            </View>
            <View style={styles.section}></View>
            <Text
                style={styles.pageNumber}
                render={({ pageNumber, totalPages }) =>
                    `${pageNumber} / ${totalPages}`
                }
                fixed
            />
        </Page>
    </Document>
);

export default ReportLayout;
