import React, { useState } from "react";
import {
    ArrowRightAltOutlined,
    VerifiedUserOutlined,
} from "@mui/icons-material";
import { Grid, MenuItem } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import CustomSubmitButton from "../../../components/Forms/CustomSubmitButton";
import { useDispatch, useSelector } from "react-redux";
import useRTK from "../../../hooks/useRTK";
import CustomTextField from "../../../components/Forms/CustomTextField";
import { colors } from "../../../assets/utils/constants";
import { useBecomeBeneficiaryMutation } from "../../../api/profile/profileAPI";
import { setUserProfile } from "../../../states/features/profile/profileSlice";
import SmallCustomSelectInput from "../../../components/Forms/SmallCustomSelectInput";
import { filter, isEmpty } from "lodash";
import { adminSelector } from "../../../states/features/selectors";

// * -> Validation
const becomeBeneficiaryValidationSchema = Yup.object({
    region: Yup.string().required("Region is required field"),
    district: Yup.string().required("District is required field"),
    birth_date: Yup.date().required("Date of birth is required field"),
});

const BecomeBeneficiary = ({ student, handleDialogClose }) => {
    // ############## CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ########## Comp state ###########
    const [districts, setDistricts] = useState([]);

    // ############ Redux state #############
    const { regions: activeRegions } = useSelector(adminSelector);

    // ############# RTK ##################
    const [
        becomeBeneficiaryAction,
        { isLoading, isSuccess, isError, error, data: studentData },
    ] = useBecomeBeneficiaryMutation();

    // ############# useEffect ##################
    const rtkActions = (data) => {
        handleDialogClose();
        dispatch(setUserProfile(data));
    };
    RTK.useRTKResponse(isSuccess, isError, error, studentData, rtkActions);

    // ################# FUNC ###################
    const getSelectedRegionDistricts = (payload) => {
        setDistricts("");
        let selectedRegion = filter(activeRegions, ({ id }) => id === payload);
        selectedRegion = selectedRegion[0];
        let selectedDistricts = selectedRegion.districts;
        setDistricts(selectedDistricts);
    };

    return (
        <>
            <Formik
                initialValues={{
                    region: "",
                    district: "",
                    birth_date: student ? student.dob : "",
                }}
                validationSchema={becomeBeneficiaryValidationSchema}
                onSubmit={(payload) => {
                    becomeBeneficiaryAction(payload);
                }}
            >
                {(formik) => (
                    <Form>
                        <Grid container justifyContent={"center"}>
                            <Grid item sm={12} xs={12}>
                                <Grid container spacing={2}>
                                    <Grid
                                        item
                                        sm={!isEmpty(districts) ? 6 : 12}
                                        xs={12}
                                    >
                                        <SmallCustomSelectInput
                                            label={"Region"}
                                            name={"region"}
                                            formik={formik}
                                            color={colors.primary}
                                            handler={getSelectedRegionDistricts}
                                        >
                                            {activeRegions.map((region) => (
                                                <MenuItem
                                                    key={region.id}
                                                    value={region.id}
                                                >
                                                    {region.name}
                                                </MenuItem>
                                            ))}
                                        </SmallCustomSelectInput>
                                    </Grid>
                                    {!isEmpty(districts) && (
                                        <Grid item sm={6} xs={12}>
                                            <SmallCustomSelectInput
                                                label={"District"}
                                                name={"district"}
                                                formik={formik}
                                                color={colors.primary}
                                            >
                                                {isEmpty(districts) ? (
                                                    <>
                                                        <MenuItem value={""}>
                                                            No district
                                                        </MenuItem>
                                                    </>
                                                ) : (
                                                    districts.map(
                                                        (district) => (
                                                            <MenuItem
                                                                key={
                                                                    district.id
                                                                }
                                                                value={
                                                                    district.id
                                                                }
                                                            >
                                                                {district.name}
                                                            </MenuItem>
                                                        )
                                                    )
                                                )}
                                            </SmallCustomSelectInput>
                                        </Grid>
                                    )}
                                    <Grid item sm={12} xs={12}>
                                        <CustomTextField
                                            className="form-input"
                                            label={`Birth date`}
                                            type="date"
                                            name="birth_date"
                                        />
                                    </Grid>
                                    <Grid item sm={12} xs={12}>
                                        <CustomSubmitButton
                                            startIcon={<VerifiedUserOutlined />}
                                            endIcon={<ArrowRightAltOutlined />}
                                            btnColor={`secondary`}
                                            variant={"contained"}
                                            loading={isLoading}
                                            onClick={() => {
                                                formik.handleSubmit();
                                            }}
                                            sx={{ py: 1.5, fontSize: 16 }}
                                        >
                                            Register as beneficiary
                                        </CustomSubmitButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default BecomeBeneficiary;
