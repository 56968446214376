import React from 'react';
import { Box, Grid } from '@mui/material';
import PageLayout from '../layouts/PageLayout';
import { filter, isEmpty, size } from 'lodash';
import PracticalTrainingSidebar from '../../components/sidebars/PracticalTrainingSidebar';
import CustomPagination from '../../components/paginations/CustomPagination';
import CountBadge from '../../components/CountBadge';
import { practicalTrainingsSelector } from '../../states/features/selectors';
import { useSelector } from 'react-redux';
import NoContent from '../../components/NoContent';
import { paginator } from '../../helpers/paginationHelper';
import FieldWidgetOne from '../../components/widgets/FieldWidgetOne';

const FavoritesPT = () => {
    // ############## Comp state ##################
    const [page, setPage] = React.useState(1);
    const [perPage, setPerPage] = React.useState(10);

    // ############### Redux state #################
    const { practicalTrainings: activePTs } = useSelector(
        practicalTrainingsSelector,
    );
    const favoritePT = filter(
        activePTs,
        ({ isFavorite }) => isFavorite === true,
    );

    // ############## FUNC ####################
    // => handle page change
    const handlePageChange = (page) => {
        setPage(page);
    };
    // => handle per page change
    const handlePerPageChage = (perPage) => {
        setPerPage(perPage);
    };

    // => Pagination data
    const paginatedActivePTs = paginator(favoritePT, page, perPage);

    return (
        <>
            <PageLayout
                head={'Practical trainings'}
                sidebar={<PracticalTrainingSidebar />}
            >
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <CustomPagination
                            head={'Favorites practical training vacancy'}
                            height={'64vh'}
                            borderBottom
                            handlePageChange={handlePageChange}
                            handlePerPageChage={handlePerPageChage}
                            data={paginatedActivePTs}
                            action={
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Box sx={{ ml: 1 }}>
                                        <CountBadge
                                            total={size(favoritePT)}
                                            item={'Active PTs'}
                                        />
                                    </Box>
                                </Box>
                            }
                        >
                            {isEmpty(favoritePT) ? (
                                <>
                                    <NoContent
                                        height={300}
                                        message={
                                            'No active practical trainings'
                                        }
                                    />
                                </>
                            ) : (
                                <Grid container spacing={3}>
                                    {paginatedActivePTs.data.map((training) => (
                                        <Grid item sm={6} key={training.id}>
                                            <FieldWidgetOne
                                                training={training}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                        </CustomPagination>
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default FavoritesPT;
