import { ArrowRightAltOutlined, ManageAccounts } from '@mui/icons-material';
import { Box } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import { colors } from '../../assets/utils/constants';
import CustomPasswordField from '../../components/Forms/CustomPasswordField';
import CustomSubmitButton from '../../components/Forms/CustomSubmitButton';
import useRTK from '../../hooks/useRTK';
import { useChangePasswordMutation } from '../../api/profile/profileAPI';
import * as Yup from 'yup';

// ############# Validation Schema ##############
const validationSchema = Yup.object({
    current_password: Yup.string().required('old password is required field'),
    new_password: Yup.string()
        .required('new password is required field')
        .min(8, 'new password must be at least 8 characters'),
    confirm_password: Yup.string()
        .oneOf([Yup.ref('new_password'), null], 'Passwords must match')
        .required('confirm password is required field')
        .min(8, 'Password must be at least 8 characters'),
});

const ChangePassword = () => {
    // ############## CONST ##################
    const RTK = useRTK();

    // ############# RTK ##################
    const [
        changePassword,
        { isLoading, isSuccess, isError, error, data: studentData },
    ] = useChangePasswordMutation();

    // ############# useEffect ##################
    const rtkActions = (data) => {};
    RTK.useRTKResponse(isSuccess, isError, error, studentData, rtkActions);

    return (
        <>
            <Formik
                initialValues={{
                    current_password: '',
                    new_password: '',
                    confirm_password: '',
                }}
                validationSchema={validationSchema}
                onSubmit={(payload, { resetForm }) => {
                    resetForm();
                    changePassword(payload);
                }}
            >
                {(formik) => (
                    <Form>
                        <Box>
                            <CustomPasswordField
                                name="current_password"
                                labelColor={colors.primary}
                                label={'Current password'}
                                type={'password'}
                            />
                        </Box>
                        <Box>
                            <CustomPasswordField
                                name="new_password"
                                labelColor={colors.primary}
                                label={'New password'}
                                type={'password'}
                            />
                        </Box>
                        <Box>
                            <CustomPasswordField
                                name="confirm_password"
                                labelColor={colors.primary}
                                label={'Confirm new password'}
                                type={'password'}
                            />
                        </Box>
                        <Box sx={{ width: '100%', mt: 2, textAlign: 'center' }}>
                            <CustomSubmitButton
                                endIcon={<ArrowRightAltOutlined />}
                                btnColor={`secondary`}
                                variant={'contained'}
                                loading={isLoading}
                                onClick={() => {
                                    formik.handleSubmit();
                                }}
                                sx={{ py: 1.5, fontSize: 16 }}
                                startIcon={<ManageAccounts />}
                            >
                                Change password
                            </CustomSubmitButton>
                        </Box>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default ChangePassword;
